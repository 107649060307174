.home-6
	.bg-fake
		padding-top: r(10px)
		width: 100%
		height: 100%
		background-position: -70% -100%!important
		background-repeat: no-repeat
		background-size: auto!important
		justify-content: center
		.swiper-partner
			position: relative
			.item
				background-color: $white
				border-radius: 10px
				display: flex
				align-items: center
				justify-content: center
				padding: r(25px)
				height: 100px
				@media screen and ( max-width: 767.9px )
					height: 120px
					
				img
					object-fit: contain
.footer
	margin-top: r(10px)
	.wrap-address
		display: flex
		width: 100%
		@media screen and ( max-width: 1023.9px )
			flex-wrap: wrap
		@media screen and ( max-width: 767.9px )
			display: block
		.item
			+dc
			background-image: linear-gradient(to top, #17a654 0%, #134d90 100%);
			opacity: 0.85
			height: 160px
			flex-direction: column
			flex: 0 0 (100% / 3)
			border-right: 1px solid $main-2
			justify-content: flex-start
			padding-top: r(30px)
			&:nth-child(1)
				border-radius: 10px 0 0 10px
				@media screen and ( max-width: 1023.9px )
					flex: 0 0 50%
					border-radius: 10px 0 0 0
				@media screen and ( max-width: 767.9px )
					border-right: 0
					border-radius: 10px 10px 0 0
			&:nth-child(2)
				border-radius: 0
				@media screen and ( max-width: 1023.9px )
					flex: 0 0 50%
					border-right: 0
					border-radius: 0 10px 0 0
				@media screen and ( max-width: 767.9px )
					border-radius: 0
				@media screen and ( max-width: 575.9px )
					.item-content
						width: 100%
			&:nth-child(3)
				border-radius: 0 10px 10px 0
				border-right: 0
				align-items: flex-start
				@media screen and ( max-width: 1023.9px )
					flex: 0 0 100%
					border-radius: 0 0 10px 10px
				.item-content
					width: 100%
					.subscribe
						.subscribefrm
							padding-left: r(96px)
							@media screen and ( max-width: 1279.9px )
								padding-left: r(30px)
							input
								max-width: 90%
								border-radius: 23px
								width: 100%
								height: 46px
								border: none
								padding-left: 20px
								font-size: 14px
								color: $color-66
							button
								margin-left: -80px
								border-radius: 20px
								background-color: $main-2
								width: 75px
								height: 40px
								border: none
								font-size: r(13px)
								color: $white
								text-transform: uppercase
								font-weight: 700
								border: thin solid $tran
								transition: .3s all ease-in-out
								&:hover
									border: thin solid $main-2
									color: $main-2
									background-color: $white 
			.title
				font-size: r(18px)
				margin-bottom: r(15px)
				padding: 0 r(48px)
				display: flex
				color: $white
				@media screen and ( max-width: 1365.9px )
					padding: 0 r(10px) 0 r(48px)
				@media screen and ( max-width: 479.9px )
					padding: 0 r(10px) 0 r(20px)
			em
				padding-right: 20px
				font-size: 24px
				color: $white
				font-weight: 700
			.content
				padding: 0 r(96px)
				color: $color-eb
				@media screen and ( max-width: 1365.9px )
					padding: 0 r(30px) 0 r(96px)
				@media screen and ( max-width: 479.9px )
					padding: 0 r(30px) 0 r(40px)
	.footer-menu
		margin: r(20px) 0
		.Module.Module-150
			margin-top: r(10px)
			li
				a
					font-size: 12px
					font-weight: 400
					letter-spacing: 1.2px
					text-transform: none
		ul
			list-style: none
			padding: 0
			display: flex
			overflow: auto
			padding-bottom: r(5px)
			&::-webkit-scrollbar
				height: 1px
			&::-webkit-scrollbar-thumb
				background: $main-2!important
				width: 5px
				&:hover
					background: $main-2!important
			li
				margin-right: 20px
				position: relative
				&:last-child
					margin-right: 0 
					&::before
						display: none
				&::before
					content: ""
					position: absolute
					width: 1px
					background-color: $white
					height: 60%
					top: 50%
					right: -10px
					transform: translateY(-50%)
				&.active
					a
						text-decoration: underline
				a
					font-weight: 700
					color: $white
					text-transform: uppercase
					letter-spacing: 0.7px
					display: inline-block
					position: relative
					white-space: nowrap
					&::before
						content: ""
						position: absolute
						left: 0
						width: 0
						height: 1px
						background-color: $white
						bottom: 0
						transition: .3s all ease-in-out
					&:hover
						color: $white
						&::before
							width: 100%
		.copyright
			margin-top: 5px
			font-size: 12px
			font-weight: 400
			color: $white
			letter-spacing: 1.2px
		.social
			display: flex
			margin-top: r(10px)
			a
				display: block
				margin-right: r(20px)
				color: $white
				font-size: 18px
				transform: translateY(0)
				transition: all .3s ease-in-out
				&:last-child
					margin-right: 0
				&:hover
					transform: translateY(-3px)

.home-6 .Module.Module-211, .Module.Module-211 .ModuleContent
	width: 100%
							
					





