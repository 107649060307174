.main-section
	padding: 25px 0
	@media(min-width: 768px)
		padding: 30px 0
	@media(min-width: 1024px)
		padding: 30px 0
	@media(min-width: 1280px)
		padding: 18px 0
	@media only screen and (min-width : 1200px) and (max-height : 768px)
		padding: 20px 0
.section-post
	padding: r(80px) 0px
	@media screen and ( max-width: 992px )
		padding: 30px
	@media screen and ( max-width: 375px )
		padding: 20px

