.nang-luc
    &.section
        padding-top: 0
    .title-center
        margin-top: r(40px)
    .swiper-nang-luc
        position: relative
        .img
            text-align: center
            margin: 0 auto
            display: block
        .wrap-button
            .button-next
                right: 280px
                @media screen and ( max-width: 1599.9px )
                    right: 180px
            .button-prev
                left: 280px
                @media screen and ( max-width: 1599.9px )
                    left: 180px