.tuyen-dung-1
	.section-title
		margin-bottom: 0
		@media screen and ( max-width: 568px )
			font-size: 28px
			
	.wrap-post
		padding: r(30px) 0
		[class*='col-']
			margin-bottom: 10px
			&:nth-child(odd)
				padding-right: 5px
				@media screen and ( max-width: 1023.9px )
					padding-right: 0 
			&:nth-child(even)
				padding-left: 5px
				@media screen and ( max-width: 1023.9px )
					padding-left: 0
			.box-wrap
				border-radius: 20px 0 0 0
			&:nth-child(2)
				.wrapper-img
					border-radius: 0 20px 0 0
			&:nth-child(3)
				.wrapper-img
					border-radius: 0 0 0 20px
			&:nth-child(4)
				.wrapper-img
					border-radius: 0 0 20px 0
		.box-wrap
			overflow: hidden
			padding: r(60px) r(75px) 0 r(40px) 
			position: relative
			width: 100%
			height: 100%
			min-height: 360px
			@media screen and ( max-width: 1024px )
				padding: r(50px) r(50px) r(30px) r(40px)
				
			@media screen and ( max-width: 768px )
				padding: r(30px)
				
			.desc
				p
					font-size: 16px
					color: $white
					line-height: 1.8
					margin-top: 30px
		.wrapper-img
			overflow: hidden
			position: relative
			transition: .8s cubic-bezier(0.42, 0, 0.22, 1.02)
			cursor: pointer
			height: 360px
			
			&:hover
				&::after
					display: none
				&::before
					top: 0
				// .text-desc
				// 	transform: translateY(0)
				// .text-desc
				.text-desc
					.desc
						max-height: 100%
						opacity: 1
						visibility: visible
						transition: .3s all
			.img
				width: 100%
				height: 100%
				a
					img
						width: 100%
						height: 100%
						object-fit: cover
			&::after
				content: ""
				width: 100%
				height: 100%
				position: absolute
				top: 0
				left: 0
				background-image: linear-gradient(to top, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0) 100%)
				transition: .3s all ease-in-out
			&::before
				content: ""
				width: 100%
				height: 100%
				position: absolute
				top: 100%
				left: 0
				background-image: linear-gradient(to top, #17a654 0%, rgba(23, 166, 84, 0.99) 1%, rgba(16, 125, 194, 0.34) 72%, rgba(16, 125, 194, 0.22) 85%)
				transition: .3s all ease-in-out
			.text-desc
				position: absolute
				left: 0
				bottom: 0
				padding: r(30px) r(20px) r(25px) r(30px)

				z-index: 2
				.img
					width: 45px
				.title
					font-size: 20px
					color: $white
					text-transform: uppercase
					font-weight: 600
					padding-top: 12px
					transition: .3s all 
				.desc
					padding-top: 20px
					font-size: 16px
					color: $white
					line-height: 1.8
					max-height: 0
					opacity: 0
					visibility: visible
					transition: .3s all ease-in-out
				
	
.tuyeng-dung-2
	padding: 30px 0
	height: 100%
	.box-wrap
		>.row
			margin-bottom: -30px
			>*
				margin-bottom: 30px
		.box-content
			display: block
			border: 1px dashed $main
			border-radius: 20px
			padding: 20px
			position: relative
			background: $white	
			transition: .3s ease-in-out all
			box-shadow: -8px 0 46px rgba(0, 0, 0, 0.09)
			display: block
			width: 100% 
			@media screen and ( min-width: 1520px )
				height: 110px
			@media screen and ( min-width: 576px )
				height: 140px
			&:hover
				background: $bg-gradient3
				color: $white
				cursor: pointer
				border: 1px dashed $tran
				*
					color: $white !important
					transition: .3s all ease-in-out
			.title
				font-size: 18px
				text-transform: uppercase
				font-weight: 600
				
				
			.info
				max-width: 60%
				display: flex
				justify-content: space-between
				align-items: center
				flex-wrap: wrap
				margin-top: 10px
				padding: 0
				list-style: none
				@media screen and ( max-width: 767.9px )
					max-width: 100%
				.item
					display: flex
					align-items: center
					font-size: 16px
					color: $color-99
					padding: 5px 0
					em
						margin-right: 10px
					&.green
						*
							color: $main-2
			.view-more
				font-size: 14px
				color: $color-99
				font-weight: 600
				position: absolute
				right: 20px
				bottom: 20px
				@media screen and ( max-width: 767.9px )
					position: static
				a
					&:hover
						+hover-underline(95%, $white)
.tuyendung-list
	.hidden-td-list
		display: none
// .tuyen-dung-1
//     min-height: 280px
//     background-color: #fff
//     width: 100%
//     padding-top: 35px
//     padding-bottom: 40px
//     .wrap-text
//         padding-left: 120px
//         @media screen and ( max-width: 992px )
//             padding-left: 0
//     .title
//         color: $main
//         font-size: r(42px)
//         font-weight: 400
//         text-transform: uppercase
//         margin-bottom: 25px
//         @media screen and ( max-width: 575px )
//             font-size: r(35px)
//     .text
//         font-size: r(20px)
//         font-weight: 400
//         line-height: 32px
//         color: black
//         &+.text
//             margin-top: 25px
// .tuyen-dung-2
//     min-height: 1080px
//     background-color: $main
//     padding-top: r(40px)
//     padding-bottom: r(140px)
//     .section-title
//         text-transform: none
//         margin-bottom: 20px
//     .number
//         color: #ffffff
//         font-size: r(68px)
//         font-weight: 300
//         @media screen and ( max-width: 1280px )
//             font-size: 3.8rem
//     .title
//         color: #ffffff
//         font-size: r(35px)
//         font-weight: 700
//         text-transform: uppercase
//         @media screen and ( max-width: 1280px )
//             font-size: 1.5rem
//     .box-big
//         width: 100%
//         position: relative
//         display: block
//         height: 800px
//         @media screen and ( max-width: 992px )
//             height: 80vh
//         @media screen and ( max-width: 575px )
//             height: auto
//         &:hover
//             .mota
//                 opacity: 1
//                 visibility: visible
//                 transform: translateY(0px)

//         &::before
//             content: ''
//             position: absolute
//             top: 0
//             left: 0
//             background-color: rgba(0,0,0,0.4)
//             width: 100%
//             height: 100%
//         .title
//             display: inline-block
//             +text-underline(white,100%,1px)
//             margin-bottom: 20px
//             letter-spacing: 1.6px
//         .mota
//             color: #ffffff
//             font-size: r(20px)
//             font-weight: 400
//             line-height: 32px
//             opacity: 0
//             visibility: hidden
//             transition: .3s all  ease-in-out
//             transform: translateY(10px)

//         .img
//             width: 100%
//             height: 100%
//             img
//                 width: 100%
//                 height: 100%
//                 object-fit: cover
//     .wrap-text
//         position: absolute
//         padding: 0 14px
//         top: 0
//         left: 0
//     .box-small
//         height: 400px
//         display: block
//         position: relative
//         &:hover
//             .mota
//                 opacity: 1
//                 visibility: visible
//                 transform: translateY(0px)
//         &::before
//             content: ''
//             position: absolute
//             top: 0
//             left: 0
//             background-color: rgba(0,0,0,0.4)
//             width: 100%
//             height: 100%
//         @media screen and ( max-width: 992px )
//             height: 300px
//         .img
//             height: 100%
//             img
//                 width: 100%
//                 object-fit: cover
//                 height: 100%
//         .mota
//             color: #ffffff
//             font-size: r(20px)
//             font-weight: 400
//             line-height: 32px
//             opacity: 0
//             visibility: hidden
//             transition: .3s all  ease-in-out
//             transform: translateY(10px)
//             @media screen and ( max-width: 1280px )
//                 font-size: 1rem
//                 line-height: 1.5
//                 margin-top: 15px
// .tuyen-dung-3
//     min-height: 1082px
//     padding-top: 70px
//     padding-bottom: 48px
//     .title-td
//         padding-left: 120px
//         color: #1a1a1a
//         font-size: r(35px)
//         font-weight: 700
//         text-align: left
//         text-transform: uppercase
//         line-height: 1.2
//         @media screen and ( max-width: 992px )
//             padding-left: 0
//             font-size: r(30px)
//     .wrap-table
//         max-width: 1312px
//         width: 100%
//         margin: 0 auto
//         margin-top: 43px
//         box-shadow: 0 3px 6px rgba(0, 0, 0, 0.38)
//         border-radius: 10px
//         border: 2px solid $main
//         background-color: #ffffff
//         padding: 56px 72px
//         padding-bottom: 18px
//         @media screen and ( max-width: 768px )
//             padding: 56px 20px
//             padding-bottom: 18px
//         table
//             width: 100%
//             .t-2
//                 padding-left: 76px
//                 @media screen and ( max-width: 991.98px )
//                     padding-left: 0!important
//             thead
//                 th
//                     color: #000000
//                     font-size: 2rem
//                     font-weight: 700
//                     padding-bottom: 25px
//                     border-bottom: 1px solid $main
//                     white-space: nowrap
//                     @media screen and ( max-width: 991.98px )
//                         font-size: 1.5rem
//                         &:first-child
//                             display: none
//                     @media screen and ( max-width: 575px )
//                         font-size: 1.2rem
//                     @media screen and ( max-width: 768px )
//                         white-space: nowrap
//                     &:last-child
//                         text-align: right
//             tbody
//                 tr
//                     &+tr
//                         border-top: 1px solid $main
//                 td
//                     padding: 34px 0px
//                     padding-bottom: 41px
//                     @media screen and ( max-width: 991.98px )
//                         &:first-child
//                             display: none
//                     &:last-child
//                         text-align: right
//                 .stt
//                     color: #1a1a1a
//                     font-size: 1.5rem
//                     font-weight: 500
//                 .title
//                     color: #1a1a1a
//                     font-size: 1.5rem
//                     font-weight: 500
//                     transition: .3s all  ease-in-out
//                     @media screen and ( max-width: 575px )
//                         font-size: 1.2rem

//                     &:hover
//                         color: $main
//                     // @media screen and ( max-width: 1024px )
//                     //     font-size: r(30px)
//                     // @media screen and ( max-width: 768px )
//                     //     font-size: r(17px)
//                 .date
//                     color: #1a1a1a
//                     font-family: Lato
//                     font-size: 1.5rem
//                     font-weight: 500
//                     @media screen and ( max-width: 575px )
//                         font-size: 1.2rem

// .tuyen-dung-4
//     min-height: 500px
//     background-color: #957e6e
//     padding-top: 52px
//     padding-bottom: 45px
//     .title
//         color: #ffffff
//         font-size: r(35px)
//         font-weight: 600
//         margin-bottom: 35px
//     .col-10
//         margin: 0 auto
//     .frm-btnwrap
//         display: flex
//         justify-content: flex-end
//     .frm-btn
//         input
//             color: #ffffff
//             font-size: 35px
//             font-weight: 600
//             text-transform: uppercase
//             width: 207px
//             height: 62px
//             border-radius: 5px
//             background-color: $main
//             border: 0
//             outline: 0
//             transition: .3s all  ease-in-out
//             &:hover
//                 transform: translateY(-3px)
// .modal-tuyen-dung
//     display: none
//     max-width: 1170px!important
//     width: 100%!important
//     border: 1px solid #707070
//     background-color: #ffffff
//     padding-bottom: 46px
//     .title
//         color: $main
//         font-size: r(35px)
//         font-weight: 700
//         text-transform: uppercase
//         border-bottom: 2px dotted $main
//         padding-bottom: 10px
//         margin-bottom: 20px
//     strong
//         font-size: 20px
//         margin-top: 20px
//         display: inline-block
//     .content
//         margin-top: 20px
//     p
//         font-size: 20px
//     span
//         font-size: 20px
//     ul
//         padding-left: 23px
//         li
//             font-size: r(20px)
//             line-height: 32px
//     .wrap-content
//         border-bottom: 2px dotted $main
//         padding-bottom: 40px
//     .fancybox-close-small
//         background-color: $main
//         svg
//             color: white


