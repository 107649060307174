.home-4
	flex-direction: column
	@media screen and ( min-width: 1200px )
		justify-content: center
	.wrap-title
		display: flex
		justify-content: space-between
		.seemore
			&::before
				top: 20%
	.seemore
		color: $white
		margin-right: r(80px)
		&::before
			background: $white
			transform: translateY(0)
			top: 50%
			transition: .3s all ease-in-out
		&:hover
			color: $main
			&::before
				background: $main
	.s4-left
		position: relative

	.index-3-slide
		.box-img
			box-shadow: 0 0 30px rgba(0, 0, 0, 0.1)
			.img
				img
					height: 550px
					width: 100%
					object-fit: cover
					@media screen and ( max-width: 1519.9px )
						height: 450px
		.swiper-slide
			transition: .2s all ease-in-out
			position: relative
			img
				border-radius: 25px
			&::before
				content: ""
				position: absolute
				width: 100%
				height: 100%
				border-radius: 25px
				background: rgba(#252525, .5)
				pointer-events: none
			.wrap-inner
				position: absolute
				padding: 0
				bottom: 60px
				width: 100%
				display: flex
				left: 40%
				justify-content: space-between
				z-index: 3
				@media screen and ( max-width: 768px )
					flex-direction: column
					height: 100%
					bottom: 0
					justify-content: center
				*
					color: $white
				.wrap-left
					text-transform: uppercase
					position: relative
					.title,
					.sub-title
						position: absolute
						transform: rotate(-90deg)
						transform-origin: 0 0
						transition: .2s all ease-in-out
					.title
						left: 25px
						white-space: nowrap
						font-size: r(24px)
						@media(max-width: 576px)
							white-space: pre-wrap
					.sub-title
						white-space: nowrap
						margin-bottom: 10px
				.wrap-right
					display: none
		.swiper-slide-active
			width: calc(100%-380px) !important
			@media screen and ( max-width: 1279.9px )
				width: calc(100%-190px) !important
			&::before
				// background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.2) 52%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0) 100%)
				background: none 
				background-image: linear-gradient(180deg, transparent,rgba(0, 0, 0, 0.1),rgba(0, 0, 0, .2),rgba(0, 0, 0, .2), rgba(0, 0, 0, .2),rgba(0, 0, 0, .3), rgba(0, 0, 0, .4), rgba(0, 0, 0, .5), rgba(0, 0, 0, .6), rgba(0, 0, 0, .7), rgba(0, 0, 0, .8), rgba(0, 0, 0, .9))
			.wrap-inner
				padding: 0 r(80px)
				left: 0
				@media screen and ( max-width: 768px )
					padding: 0 r(60px)
				@media screen and ( max-width: 480px )
					padding: 0 r(30px)
				&::before
				.wrap-left
					width: 100%
					text-transform: uppercase
					position: relative
					@media screen and ( max-width: 767.9px )
						margin-bottom: r(60px)
					.title,
					.sub-title
						position: static
						transform: rotate(0)
						transform-origin: 0 0
					.sub-title
						right: 0
					.title
						position: relative
						left: 0
						&::before
							content: ""
							position: absolute
							width: 100px
							height: 2px
							background-color: $white
							bottom: -20px
							left: 0
							pointer-events: none
				.wrap-right
					display: block
					@media screen and ( max-width: 576px )
						.des
							+line(4)
							height: 76px
						.seemore
							white-space: nowrap
	.wrap-button
		.button-next,
		.button-prev
			z-index: 3
			position: absolute
			z-index: 2
			padding: r(20px)
			filter: brightness(0) invert(1) grayscale(0)
			opacity: 0.8
			&:hover
				opacity: 0.8
			@media screen and ( max-width: 1519.9px )
				position: static
		.button-next
			right: 200px
		.button-prev
			left: -75px
