
.box-item
	border-radius: 20px
	background: $white
	overflow: hidden
	transition: .2s ease-in-out all
	&:hover
		.wrap-text
			.title
				color: $main-2
			time
				background: $main-2	
	.img
		width: 100%
		height: 260px
		overflow: hidden
		img
			width: 100%
			height: 100%
			object-fit: cover
	.wrap-text
		padding: 30px 20px 30px 40px
		position: relative
		.title
			font-size: 22px
			font-weight: 600
			color: $color-33
			padding-bottom: 10px
			+line(2)
			&:hover
				color: $main-2
				
		.desc
			font-size: 14px
			color: $color-33
			+line(2)
			padding-top: 10px
			padding-bottom: 20px
			
		.view-detail
			font-size: 13px
			color: $main
			text-transform: uppercase
			font-weight: 600
			position: relative
			margin-top: auto
			margin-top: 30px
			&::before
				position: absolute
				width: 20px
				content: ""
				height: 2px
				background: $main
				right: -30px
				top: 50%
				transform: translateY(-50%)
				transition: .2s ease-in-out all
			&:hover
				color: $main-2
				&::before
					background: $main-2
					transform: translateX(10px)
		time
			font-size: 13px
			padding: 2px 10px
			color: $white
			font-weight: 500
			background: $main
			position: absolute
			top: 32px
			left: -33px
			transform: rotate(270deg)
// .box-item
// 	.img.zoom-img
// 		a(href="")
// 			img(data-src="./img/tin-tuc/t1.png" alt="" class="lazyload")
// 	.wrap-text
// 		a(href="#").title BĐS phía Đông TP.HCM tiếp tục tăng nhiệt
// 		.desc Lợi thế hạ tầng giao thông đồng bộ, kinh tế phát triển của Nhơn
// 		a(href="#").view-detail Xem thêm
// 		time 15.09.2020 
