@media(min-width: 1200px)
	@mixin transition($time, $property: all, $easing: ease-in)
		transition: $property $time $easing
	$parallax-offset: 0
	$content-offset: 30vh
	$transition-speed: 2s
	$slide-number: 9
	.full-page
		overflow: hidden
		section
			background-size: cover
			background-repeat: no-repeat
			background-position: center center
			overflow: hidden
			will-change: transform
			backface-visibility: hidden
			// height: 100vh
			height: 100vh + $parallax-offset
			position: fixed
			width: 100%
			transform: translateY(100vh)
			background-image: url("/Data/Sites/1/media/home-2/bg.png")
			background-size: cover
			background-position: center center
			@include transition($transition-speed, all, cubic-bezier(0.22, 0.44, 0, 1))
			&:first-child
				opacity: 1
				transform: translateY(-$parallax-offset / 2)
	@for $i from 1 to ($slide-number + 1)
		.full-page section:nth-child(#{$i})
			// z-index: ($slide-number + 1) - $i
			z-index: ($slide-number + 1) - $i
			z-index: $i
	section.up-scroll
		transform: translate3d(0,-$parallax-offset / 2,0)
		+ section
			transform: translate3d(0,(100vh + $parallax-offset),0)
	section.down-scroll
		transform: translate3d(0,-$parallax-offset,0)
		+ section
			transform: translate3d(0,-$parallax-offset / 2,0)
		// &:last-child
		// 	transform: translate3d(0,calc(-30vh - 780px) ,0)
#nav-pagination
	position: fixed
	top: 50%
	right: 0
	padding: 10px 0px
	// padding-right: 30px
	transform: translateY(-50%)
	width: 54px
	background-color: rgba(0, 0, 0, 0.18)
	z-index: 999
	display: none
	&.active
		display: block
	@media(max-width: 1200px)
		display: none
	ul
		list-style-type: none
		padding-left: 0
		display: flex
		align-items: center
		justify-content: center
		flex-direction: column
		li
			color: white
			font-size: 26px
			font-weight: 400
			transition: .3s all  ease-in-out
			cursor: pointer
			&.active, &:hover
				color: $main
			&.active
				color: $main
			span
				font-weight: 700
				font-size: 10px
				line-height: 1
				text-transform: uppercase
				text-align: right
				white-space: nowrap
				position: absolute
				display: inline-block
				top: 0
				right: 20px
				margin: 0
				opacity: 0
				visibility: hidden
			&:hover span,
			&.active span
				opacity: 1
				transform: translate3d(0, 0, 0) scale3d(1, 1, 1)
				visibility: visible
				color: $main
@-webkit-keyframes wheel
	0%
	opacity: 0
	transform: scale(.3)
	top: 0
	50%
	opacity: 1
	transform: scale(1)
	100%
	opacity: 0
	transform: scale(.3)
	top: 20px

.wheel
	width: 30px
	height: 30px
	// right: 32px
	position: absolute
	bottom: 100px
	right: 20px
	opacity: 1
	transition: opacity .3s ease-in-out
	z-index: 5
	span
		position: absolute
		width: 100%
		height: 2px
		opacity: 0
		transform: scale(.3)
		&:first-child
			animation: wheel 2s ease-in-out .5s infinite
		&:nth-child(2)
			animation: wheel 2s ease-in-out 1s infinite
		&:nth-child(3)
			animation: wheel 2s ease-in-out infinite
		&:before, &:after
			content: ''
			position: absolute
			top: 0
			height: 100%
			width: 50%
			background-color: #fff
		&::before
			left: 0
			transform: skewY(30deg)
		&::after
			right: 0
			width: 50%
			transform: skewY(-30deg)
@keyframes fadein
	from
		opacity: 0

	to
		opacity: 1

/* Firefox < 16
@-moz-keyframes fadein
	from
	opacity: 0

	to
	opacity: 1

/* Safari, Chrome and Opera > 12.1
@-webkit-keyframes fadein
	from
	opacity: 0

	to
	opacity: 1

/* Internet Explorer
@-ms-keyframes fadein
	from
	opacity: 0

	to
	opacity: 1

/* Opera < 12.1
@-o-keyframes fadein
	from
	opacity: 0

	to
	opacity: 1
.full-page
	main
		padding-top: 0
	section
		display: flex
		@media(min-width: 1200px)
			.animation-left
				opacity: 0
				transition: 2s all cubic-bezier(0.22, 0.44, 0, 1)
				transform: translateX(-300px)
			.animation-right
				opacity: 0
				transition: 2s all cubic-bezier(0.22, 0.44, 0, 1)
				transform: translateX(300px)
			.animation-bottom
				opacity: 0
				transition: 2s all cubic-bezier(0.22, 0.44, 0, 1)
				transform: translateY(300px)
			.animation-top
				opacity: 0
				transition: 2s all cubic-bezier(0.22, 0.44, 0, 1)
				transform: translateY(-300px)
			&.current-page
				.animation-left
					opacity: 1
					transform: translateX(0)
				.animation-right
					opacity: 1
					transform: translateX(0)
				.animation-bottom
					opacity: 1
					transform: translateY(0)
				.animation-top
					opacity: 1
					transform: translateY(0)
			> div
				display: flex
				flex-direction: column
				> .Module
					width: 100%
					height: 100%
#fake-header
	position: fixed
	top: -50%
	opacity: 0
	visibility: hidden
	pointer-events: none
	left: 0
	transform: translateX(-50%)
	z-index: 9999
	min-width: 0
	min-height: 0
	border-radius: 50%
	display: flex
	justify-content: center
	align-items: center
	background-color: $main
	background-image: linear-gradient(to top, rgba(255, 255, 255, 0.75) 0%, rgba($color: #ffcb04, $alpha: 0.75) 100%)
	background-size: cover
	background-position: center center
	transition: all 2s cubic-bezier(.22, .44, 0, 1)
	.menu-fake-header
		list-style: none
		padding-left: 0
		text-align: left
		li
			opacity: 0
			transition: 2s all cubic-bezier(0.22, 0.44, 0, 1)
			transform: translateX(300px)
			a
				transition: .5s ease-in-out all
				text-transform: uppercase
				display: inline-block
				opacity: 0
				// color: $title
				font-size: 30/16*1rem
				font-weight: 700
				line-height: 60px
				padding: 0
			&:hover
				a
					position: relative
					color: $white
					transform: translateX(35px)
					&::before
						position: absolute
						left: -30px
						top: 50%
						content: ''
						width: 20px
						height: 1px
						background-color: #ffffff
	.close-button
		// color: $title
		display: flex
		align-items: center
		position: absolute
		transform: rotate(90deg) translateY(-50%)
		top: 45%
		cursor: pointer
		right: 20px
		transition: all 1s cubic-bezier(.22, .44, 0, 1)
		em
			font-size: 36px
			margin-right: 10px
		span
			text-transform: uppercase
		&:hover
			color: #ffffff
	.logo-fake-header
		position: absolute
		top: 15vh
		z-index: 11
		opacity: 0
		transition: 2s all cubic-bezier(0.22, 0.44, 0, 1)
		transform: translateY(300px)
	.menu-fake-header
		ul
			.item
				a
				span
					display: none
	.menu-fake-header
		.Module-141
			display: flex
			justify-content: center
			align-items: center
			opacity: 0
			transition: 2s all cubic-bezier(0.22, 0.44, 0, 1)
			transform: translateY(300px)
			@media(min-width: 1025px)
				display: none
	.menu-fake-bottom
		position: absolute
		top: 80%
		left: 60%
		.socail
			display: flex
			align-items: center
			list-style: none
			margin-top: 30px
			padding-left: 0
			li
				width: 36px
				height: 36px
				border-radius: 50%
				background-color: #ffffff
				display: flex
				align-items: center
				justify-content: center
				margin-right: 10px
				transition: all .5s
				a
					em
						color: #666666
						font-size: 24/16*1rem
						font-weight: 400
				&:hover
					transition: all .5s
					a
						em
							color: #ffffff
					&:first-child
						background-color: #38569a
					&:nth-child(2)
						background-color: #f80000
					&:last-child
						background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d)

	@media(min-width: 1025px)
		.logo-fake-header
			position: initial
			top: initial
			z-index: 21

		.menu-fake-header
			position: absolute
			z-index: 11
			justify-content: flex-end
			left: 60%
			top: 38%
			padding-top: 60px
			ul
				// text-align: right
				// padding-right: 50px
				.item
					position: relative
					display: flex
					align-items: center
					justify-content: flex-end
					a
						position: relative
					.number
						font-size: 18px
						font-weight: 300
						display: block
						padding-left: 25px
						color: rgba(#ffffff,0.5)
					&.active, &:hover
						a
							text-decoration: underline
						.number
							color: rgba(#ffffff,1)
	// @media(min-width: 1200px)
	// 	.menu-fake-header
	// 		ul
				// padding-right: 70px
	@media screen and ( max-width: 1199.98px)
		display: none
	&.active
		transition: all 2s cubic-bezier(.22, .44, 0, 1)
		min-width: 1200px
		min-height: 1200px
		opacity: .9
		visibility: visible
		pointer-events: initial
		overflow: hidden
		.logo-fake-header
			opacity: 1
			transform: translateX(0)
		.menu-fake-header
			li
				opacity: 1
				transform: translateX(0)
				&:nth-child(1)
					display: none
				&:nth-child(2)
					transition-delay: .2s
				&:nth-child(3)
					transition-delay: .3s
				&:nth-child(4)
					transition-delay: .4s
				&:nth-child(5)
					transition-delay: .5s
				&:nth-child(6)
					transition-delay: .6s
				&:nth-child(7)
					transition-delay: .7s
				&:nth-child(8)
					transition-delay: .8s
				&:nth-child(9)
					transition-delay: .9s
			a
				opacity: 1
		.menu-fake-header
			.Module-141
				opacity: 1
				transform: translateX(0)
				// transition-delay: 1s
		.main-menu
			.register-news
				opacity: 1
				transform: translateX(0)
@keyframes fadein
	from
		opacity: 0

	to
		opacity: 1

/* Firefox < 16
@-moz-keyframes fadein
	from
	opacity: 0

	to
	opacity: 1

/* Safari, Chrome and Opera > 12.1
@-webkit-keyframes fadein
	from
	opacity: 0

	to
	opacity: 1

/* Internet Explorer
@-ms-keyframes fadein
	from
	opacity: 0

	to
	opacity: 1

/* Opera < 12.1
@-o-keyframes fadein
	from
	opacity: 0

	to
	opacity: 1
#fake-header-open
	position: absolute
	transition: 2s cubic-bezier(0.22, 0.44, 0, 1) all
	transform: translateY(-150px) rotate(90deg)
	right: 20px
	top: 60px
	z-index: 9998
	text-transform: uppercase
	// display: none
	.img
		width: 50px
		box-shadow: 0 2px 7px #0000003d
		border-radius: 50%
		cursor: pointer
	&.active
		transform: translateY(0) rotate(0deg)
		// .open-button
		// 	opacity: 0
		// 	visibility: hidden
	.open-button
		user-select: none
		cursor: pointer
		font-weight: 600
		font-size: 14px
		padding: 5px 12px
		background: $main
		color: #fff

		position: relative
		&:after
			content: ''
			height: calc(100% + 8px)
			width: calc(100% + 8px)
			position: absolute
			border: 1px solid $main
			top: 50%
			left: 50%
			transform: translate(-50%, -50%)
		&::before
			content: ''
			height: 60px
			width: 1px
			left: 50%
			top: -200%
			background: $main
			position: absolute
	@media screen and ( max-width: 1199.98px)
		display: none
// @media (max-width: 1025px)
// 	#fake-header-open
// 		position: fixed
// 		// transform: translateY(-150px) rotate(90deg)
// 		// display: none
// 		right: 0
// 		.open-button
// 			background: #c7931c
// 			color: #fff
// 			&:after
// 				border-color: #c7931c
// 			&:before
// 				border-color: #c7931c
// 	#fake-header
// 		.close-button
// 			right: -10px
// 			top: 25px
// 		ul
// 			a
// 				font-size: 16px
// 1600/ 1440 - 768 / 1440 -657 / 1400 - 567 / 1366 - (768 - 675)

.menu-fullscreen
	height: 100vh
	position: fixed
	z-index: 9999
	background: $white
	width: 100%
	top: 100%
	opacity: 0
	visibility: hidden
	transform: translateY(100%)
	transition: all .95s cubic-bezier(.68,-.55,.265,1.55)
	&.active
		top: 0
		opacity: 1
		visibility: visible
		transform: translateY(0)
		.close-button
			transform: translateY(5px) rotate(90deg)
	.close-button
		position: absolute
		transition: 1s cubic-bezier(0.22, 0.44, 0, 1) all
		transform: translateY(-300px) rotate(90deg)
		right: 20px
		top: 60px
		z-index: 9998
		text-transform: uppercase
		.icon-close
			user-select: none
			cursor: pointer
			font-weight: 600
			font-size: 14px
			padding: 5px 12px
			background: $main
			color: #fff
			position: relative
			&:after
				content: ''
				height: calc(100% + 8px)
				width: calc(100% + 8px)
				position: absolute
				border: 1px solid $main
				top: 50%
				left: 50%
				transform: translate(-50%, -50%)
			&::before
				content: ''
				height: 1px
				width: 45px
				left: -46px
				top: 45%
				background: $main
				position: absolute
	.primary-menu
		display: flex
		flex-direction: column
		justify-content: flex-start
		align-items: center
		padding: r(60px)
		margin-top: r(30px)
		.logo
			order: -1
			margin: 0 auto r(15px)
		ul
			display: flex
			flex-direction: column
			align-items: center
			height: auto
			justify-content: flex-start
			li
				display: block
				text-align: center
				margin: 0 0 r(15px)
				font-size: r(20px)
				width: max-content
				&:hover
					.dropdown
						display: none
		.dropdown-menu
			.title
				em
					padding-left: 5px
					display: inline-block
		.dropdown
			position: relative
			min-width: 190px
			transform: translate(0)
			top: 0
			left: 0
			opacity: 1
			visibility: visible
			transition: unset
			.dropdown-item
				padding: r(15px)
				color: $white
				border: 0
				border-bottom: 1px solid $color-eb
				background: $bg-gradient
				&:last-child
					border-bottom: 0
				&:hover
					a
						color: $white
						text-decoration: underline
				.dropdown
					top: 0		
		.dropdown
			display: none
			background-color: $main-2
				