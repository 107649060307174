.tuyen-dung-ct
	.wrapper-box 
		margin-top: 30px
		
		.box-left-top
			border: 1px dashed $main
			border-radius: 20px
			overflow: hidden
			background: $white
			margin-bottom: 30px
			.img
				width: 100%
				height: 100%
				img
					width: 100%
					height: 100%
					object-fit: cover
		.wrap-content
			padding-bottom: r(30px)
			.heading
				border-bottom: thin solid #e1e1e1
				display: flex
				justify-content: space-between
				align-items: center
				padding: 15px 0 15px 25px

				.fb
					font-size: 18px
					color: $color-99
					padding-right: 20px
					transition: .3s all ease-in-out
					&:hover
						color: $main
			.info
				overflow-x: auto
				&::-webkit-scrollbar
					height: 1px
				&::-webkit-scrollbar-thumb
					background: $main-2!important
					width: 5px
					&:hover
						background: $main-2!important
				table
					width: 100%
					@media screen and ( max-width: 767.9px )
						min-width: 510px
					tr
						// display: flex
						padding: 0 20px
						@media screen and ( max-width: 587px )
							td
								&:first-child
									width: 10%
									
								font-size: 12px
						&:last-child
							td
								*
									color: #e60606
						
						
					td
						// display: flex
						// align-items: center
						padding: 10px 
						color: $color-66
						&:first-child
							width: 10%
							text-align: center
						&:nth-child(2)
							width: 35%
						&:last-child
							width: 55%
						i
							margin-right: 10px
						p
							font-size: 15px
				
			.wrap-option
				display: flex
				padding: 0 20px
				@media screen and ( max-width: 767.9px )
					flex-direction: column
					align-content: center
				.btn
					color: $white
					&::before
						filter: brightness(0) invert(1)
					&:hover
						transform: translateY(-3px)
				.blue
					background: $main
					margin-right: r(30px)
				.green
					background: $main-2
					width: fit-content
					padding-right: 60px
		.wrap-desc
			.info
				padding: 10px 20px 30px 40px 
				li
					font-size: 16px
					color: $color-33
					padding: 10px 0
					
					position: relative
					
					&::before
						position: absolute
						
						content: "\EB7C"
						font-family: "remixicon"
						transform: translateY(7px)
						font-size: 8px
						left: -20px
						color: #999999
			.wrap-desc-content
				padding: 20px 20px 20px 40px
				ul 
					padding-left: 25px
	.box-right
		border: 1px dashed $main
		border-radius: 20px
		background: $white
		overflow: hidden
		
		.box-content
			.box-list
				display: block
				padding: 20px
				border-bottom: 1px solid #e1e1e1
				transition: .3s all ease-in-out
				cursor: pointer
				background: $white
				&:hover
					background: $bg-gradient2
					transition: .3s all ease-in-out
					>*
						color: $white
						transition: .3s all ease-in-out
					.number
						color: $white
						transition: .3s all ease-in-out
				.title
					font-size: 14px
					text-transform: uppercase
					font-weight: 600
				.address,.number
					display: flex
					align-items: center
					font-size: 15px
					padding: 5px 0
					em
						font-size: 15px
						margin-right: 10px
				.number
					color: $main-2
	.heading
		padding: 20px
		border-bottom: 1px solid #e1e1e1
		.title
			font-size: 24px
			color: $main
			font-weight: 500
			@media screen and ( max-width: 578px)
				font-size: 22px

.news-detail-page 
	.form-apply
		display: none
	.tuyen-dung-ct .wrapper-box
		margin-top: 0
		padding-top: 30px
	.tuyen-dung-1
		background-image: none !important
		position: relative
		z-index: 111
	.tuyen-dung-ct
		padding-top: 65px
		margin-top: -65px
		@media(min-width: 1280px)
			padding-top: 85px
