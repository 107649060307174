[class*="about-us"]
    .container
        @media screen and ( min-width: 1280px )
            max-width: 1250px
        @media screen and ( min-width: 1600px )
            max-width: 1440px
.about-us-1
    background-position: center bottom -176px!important
    padding-bottom: 200px
    padding-top: 73px
    @media screen and ( max-width: 1600px )
        padding-top: 80px
        padding-bottom: 80px
    @media screen and ( max-width: 1280px )
        padding-bottom: 60px
        padding-top: 60px
    .wrap-content
        margin-top: 92px
        @media screen and ( max-width: 992px )
            margin-top: 30px
        .img
            padding-right: 73px
            width: 100%
            height: auto
            border-radius: 0px 40px 0px 40px
            overflow: hidden
            @media screen and ( max-width: 1600px )
                padding-right: 0px
            @media screen and ( max-width: 1280px )
                height: auto
                padding-right: 0px
                margin-bottom: 30px
            img
                width: 100%
                height: 100%
                object-fit: cover
        .content
            padding-left: 29px
            @media screen and ( max-width: 1280px )
                padding-left: 0
            .title
                color: #1a1a1a
                font-size: 1.8rem
                font-weight: 600
                line-height: 42px
                margin-bottom: 13px
                text-transform: uppercase
                @media screen and ( max-width: 992px )
                    line-height: 1.4
            .mota
                height: calc( 634px  - 168px )
                overflow: auto
                margin-right: -27px
                padding-right: 20px
                @media screen and ( max-width: 1600px )
                    margin-right: 0px
                    height: 371px
                @media screen and ( max-width: 1024px )
                    height: 250px

                &::-webkit-scrollbar
                    width: 3px
                &::-webkit-scrollbar-track
                    background: #957e6e
                &::-webkit-scrollbar-thumb
                    background: $main
                *
                    color: #000000
                    font-size: r(20px)
                    font-weight: 400
                    line-height: 32px
.about-us-2
    background-color: $main
    padding-top: 51px
    padding-bottom: 220px
    @media screen and ( max-width: 1600px )
        padding-bottom: 100px
    @media screen and ( max-width: 768px )
        padding-bottom: 50px
    .title-small
        margin-bottom: 100px
        @media screen and ( max-width: 575px )
            margin-bottom: 50px
    .swiper-wrapper
        align-items: center
    .wrap-slide
        position: relative
    .wrap-button
        max-width: 1230px
        margin: 0 auto
        position: absolute
        top: 50%
        z-index: 999
        width: 100%
        left: 50%
        transform: translate(-50%,-50%)
        @media screen and ( max-width: 998px )
            position: static
            transform: translate(0)
            display: flex
            align-items: center
            justify-content: center
            padding-top: 30px
        @media screen and ( max-width: 1200px )
            max-width: 955px
        @media screen and ( max-width: 998px )
            max-width: 900px
        .button-next-1,.button-prev-1
            width: 57px
            height: 57px
            @media screen and ( max-width: 998px )
                position: static
                transform: translate(0)
                margin: 0 10px
    .swiper-slide-active
        .post-item
            transform: scaleY(1)
            transition: .3s all  ease-in-out
            .img
                filter: blur(0px)
                &::before
                    backdrop-filter: blur(0px)
                    background-color: rgba(0, 0, 0, 0.0)
                // padding-top: 55%!important
                // transition: .3s all  ease-in-out
    .post-item
        display: block
        height: 100%
        transform: scaleY(0.9)
        transition: .3s all  ease-in-out
        @media screen and ( max-width: 1280px )
            transform: scaleY(0.95)
        .img
            +img-ratio(436/783)
            padding-top: 55%!important
            transition: .3s all  ease-in-out
            &::before
                content: ''
                position: absolute
                top: 0
                left: 0
                width: 100%
                height: 100%
                background-color: rgba(0, 0, 0, 0.5)
                backdrop-filter: blur(7px)
                transition: .3s all  ease-in-out
                z-index: 2
            // transition: .3s all  ease-in-out
        .text
            height: 143px
            background-color: #e3e3e3
            position: relative
            padding: 0 28px
            padding-bottom: 10px
            padding-top: 16px
            z-index: 3
            *
                color: #000000
                font-size: r(22px)
                font-weight: 500
                @media screen and ( max-width: 575px )
                    font-size: 1.2rem

            .date
                text-shadow: 2px 3px 6px rgba(0, 0, 0, 0.68)
                color: #ffffff
                font-size: 58px
                font-weight: 700
                line-height: 1
                position: absolute
                padding-bottom: 26px
                top: -79px
                @media screen and ( max-width: 575px )
                    font-size: 35px
                    top: -56px
                &::before
                    content: ''
                    position: absolute
                    bottom: 0
                    left: 0
                    height: 10px
                    width: 100%
                    background-color: $main
.about-us-3
    min-height: 1080px
    padding-top: 100px
    padding-bottom: 50px
    @media screen and ( max-width: 998px )
        min-height: auto
        padding-top: 60px
        padding-bottom: 60px
    .col-xl-4
        @media screen and ( max-width: 998px )
            order: 1
    .col-xl-8
        @media screen and ( max-width: 998px )
            order: 2
    .wrap-content
        margin-top: 250px
        @media screen and ( max-width: 992px )
            margin-top: 50px
    .wrap-box
        display: flex
        padding-right: 80px
        @media screen and ( max-width: 998px )
            padding-right: 10px
            margin-top: 20px
        .title
            color: $main
            font-size: r(42px)
            font-weight: 700
            letter-spacing: 0.84px
            text-transform: uppercase
        .content
            color: #1a1a1a
            font-size: 1.8rem
            font-weight: 400
            @media screen and ( max-width: 998px )
                font-size: r(30px)
        img
            width: 28px
            height: 23px
            margin-right: 16px
            margin-top: 6px
    .circle
        width: 100%
        background-color: #ffffff
        opacity: 0.85
        border-radius: 50%
        display: flex
        align-items: center
        justify-content: center
        margin-top: -119px
        width: 100%
        position: relative
        &::before
            content: ''
            padding-top: 100%
            display: block
        @media screen and ( max-width: 1280px )
            margin-top: 0
        @media screen and ( max-width: 998px )
            margin: 0 auto
            margin-bottom: 30px
        p
            max-width: 336px
            width: 100%
            color: $main
            font-size: r(42px)
            font-weight: 600
            text-align: center
            text-transform: uppercase
            margin-top: -50px
            @media screen and ( max-width: 998px )
                margin-top: 0
                font-size: r(38px)

.about-us-4
    min-height: 1080px
    padding-top: 83px
    padding-bottom: 52px
    .circle
        width: 353px
        height: 354px
        background-color: #ffffff
        opacity: 0.85
        border-radius: 50%
        overflow: hidden
    .text
        display: flex
        max-width: 348px
        img
            height: 31px
            width: auto
            margin-right: 10px
        .content
            color: #ffffff
            font-size: r(20px)
            font-weight: 700
        i
            color: #ffffff
            font-size: r(20px)
            font-weight: 700
            font-style: italic
            display: block
    .wrap-content-1
        display: flex
        align-items: center
        justify-content: flex-end
        margin-top: -81px
        @media screen and ( max-width: 998px )
            margin-top: 0px
        @media screen and ( max-width: 765px )
            flex-wrap: wrap
            justify-content: center
        .circle
            margin-top: 0
        .text
            display: flex
            margin-left: -30px
            flex: 1
            @media screen and ( max-width: 765px )
                flex: 0 0 100%
                margin-top: 30px
                margin-left: 0px
            img
                height: 31px
                width: auto
                margin-right: 10px
            .content
                color: #ffffff
                font-size: r(20px)
                font-weight: 700
                i
                    color: #ffffff
                    font-size: r(20px)
                    font-weight: 700
                    font-style: italic
                    display: block
    .wrap-content-2
        display: flex
        align-items: center
        justify-content: space-evenly
        margin-top: 48px
        flex-wrap: wrap
        .item-content
            margin-bottom: 30px
        .circle
            margin-bottom: 35px
.about-us-5
    max-height: 1080px
    height: 100%
    position: relative
    .img
        display: block
        height: 100%
        width: 100%
        position: relative
        &::before
            content: ''
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%
            background-color: rgba(0,0,0,0.3)
        img
            width: 100%
            height: 100%
            object-fit: cover
    .button-play
        position: absolute
        top: 50%
        left: 50%
        pointer-events: none
        transform: translate(-50%,-50%)
        @media screen and ( max-width: 575px )
            width: 50px
            height: auto

.about-us-6
    min-height: 1080px
    padding-top: 50px
    padding-bottom: 50px
    @media screen and ( max-width: 1600px )
        min-height: auto
    .swiper-slide
        .img
            +img-ratio(669/1170)
            border-radius: 0 40px 0px 40px
    .text
        color: #000000
        font-size: r(22px)
        font-weight: 400
        max-width: 946px
        margin-top: 48px
        padding-right: 15px
        @media screen and ( max-width: 768px )
            margin-top: 25px
            padding-right: 0
    .wrap-button-text
        display: flex
        align-items: center
        justify-content: space-between
        @media screen and ( max-width: 768px )
            flex-wrap: wrap
            justify-content: flex-start
    .button
        display: flex
        align-items: center
        @media screen and ( max-width: 768px )
            margin-top: 20px
            justify-content: center
            flex: 0 0 100%
        .button-next, .button-prev
            width: 57px
            height: 57px
            border: 2px solid #000000
            display: flex
            align-items: center
            justify-content: center
            border-radius: 50%
        .button-next
            margin-left: 18px
.modal-about-slide
    background-color: #333333!important
    max-width: 990px!important
    width: 100%!important
    padding: 0!important
    margin: 0 auto
    display: none
    .button
        display: flex
        align-items: center
        justify-content: center
        margin-top: 20px
    .fancybox-close-small
        position: fixed!important
        color: white!important
        transition: .3s all  ease-in-out
        font-weight: bold
        background: #4e4e4e!important
    .swiper-container
        width: 100%
    .swiper-slide
        .img
            width: 100%
            height: 574px
            @media screen and ( max-width: 992px )
                height: auto
            img
                width: 100%
                height: 100%
                object-fit: cover
    .button-prev,.button-next
        width: 57px
        height: 57px
        border: 2px solid #ffffff
        display: flex
        align-items: center
        justify-content: center
        border-radius: 50%
        margin: 0 9px
        @media screen and ( max-width: 575px )
            width: 40px
            height: 40px
    .fancybox-content
        padding: 0px
    .swiper-pagination
        position: static
        color: #ffffff
        font-size: r(20px)
        font-weight: 400
        margin-top: 90px
        @media screen and ( max-width: 998px )
            margin-top: 45px
        @media screen and ( max-width: 575px )
            margin-top: 20px

.About
    .fancybox-bg
        background-color: #333333
        opacity: 1!important
