$main: #134d90
$main-2: #17a654
$body: #ffffff
$color-60: #606060
$color-66: #666666
$color-99: #999999
$color-33: #333333
$color-eb: #ebebeb
$color-00: #000000
$black: #111111
$white: #ffffff
$tran: transparent
$bg-gradient: linear-gradient(to top, #17a654 0%, rgba(23, 166, 84, 0.99) 1%, rgba(16, 125, 194, 0.35) 50%, rgba(16, 125, 194, 0.22) 60%)
$bg-gradient2: linear-gradient(to left, rgba(23, 166, 84, 0.99), rgba(16, 125, 194, 1) )
$bg-gradient3: linear-gradient(to top, rgba(23, 166, 84, 1), rgba(16, 125, 194, 1) )
.bg-gradient2
	background: $bg-gradient2
.bg-gradient3
	background: $bg-gradient3
