.btn
	+dc
	width: 160px
	height: 44px
	border-radius: 10px
	text-transform: uppercase
	position: relative
	transition: .3s all ease-in-out
	a
		+dc
		justify-content: flex-start
		padding-left: r(15px)
		position: relative
		width: 100%
		height: 100%
	
	&.btn-primary
		background: $white
		color: $main
		font-size: 14px
		position: relative
		font-weight: 700
		margin-top: r(30px)
		&::before
			content: ""
			position: absolute
			right: 0
			top: 0
			background: url("../img/btn-icon.png") no-repeat 100% 100%
			background-position-x: right
			background-size: contain
			width: 100%
			height: 100%
			pointer-events: none
			transition: .3s all ease-in-out
		&:hover
			color: $main-2
			&::before
				background: url("../img/btn-icon-green.png")no-repeat
				background-position-x: right

.btn-play
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	position: absolute
	width: 100px
	height: 100px
	background-image: linear-gradient(to top, #17a654 0%, #134d90 100%)
	border-radius: 100%
	border: 10px solid rgba($white, .8)
	transition: .3s all ease-in-out
	&::before
		+icon("\f00a") 
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		font-size: 4rem
		color: $white
	&:hover
		transform: translate(-50%, -55%)

// Swiper Button
.wrap-button
	position: absolute
	top: 0
	width: 100%
	height: 100%
	@media screen and ( max-width: 1519.9px )
		position: static
		display: flex
		justify-content: center
	.button-next,
	.button-prev
		position: absolute
		top: 50%
		transform: translateY(-50%)
		width: 50px
		height: 10px
		transition: .3s all ease-in-out
		@media screen and ( max-width: 1023.9px )
			width: 30px
		@media screen and ( max-width: 1519.9px )
			position: static
			margin-top: r(30px)
		&.gray
			filter: brightness(1) invert(0)
			transition: .3s all ease-in-out
			&:hover
				filter: brightness(0) invert(1)
	.button-prev
		background: url("../img/icon-2.png") no-repeat
		left: -75px
		transition: .3s all ease-in-out
		filter: grayscale(1)
		background-size: contain
		&:hover
			filter: grayscale(0)
			transition: .3s all ease-in-out
		@media screen and ( max-width: 1519.9px )
			margin-right: auto
			transform: translateX(-80px)
	.button-next
		background: url("../img/icon-1.png") no-repeat
		background-size: contain
		right: -75px
		filter: grayscale(1)
		transition: .3s all ease-in-out
		@media screen and ( max-width: 1519.9px )
			margin-left: auto
			transform: translateX(80px)
		&:hover
			filter: grayscale(0)
			transition: .3s all ease-in-out
		// &.gray
		// 	filter: greyscale(100%)
	&.white
		.button-next,
		.button-prev
			&:hover
				transition: .3s all
				filter: brightness(0) invert(1)
		
.bg-blue
	background: $main
	color: $white
.bg-green
	background: $main-2
	color: $white

				
		
