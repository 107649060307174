@charset "UTF-8";
.bg-gradient2 {
  background: linear-gradient(to left, rgba(23, 166, 84, 0.99), #107dc2); }

.bg-gradient3 {
  background: linear-gradient(to top, #17a654, #107dc2); }

body {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto; }

.container-16 {
  max-width: 100%;
  width: calc(100% + 10px);
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto; }

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 100%; }

@media (min-width: 576px) {
  .container, .container-16 {
    max-width: 540px;
    padding-right: 15px;
    padding-left: 15px; } }

@media (min-width: 768px) {
  .container, .container-16 {
    max-width: 720px; } }

@media (min-width: 1024px) {
  .container, .container-16 {
    max-width: 960px; } }

@media (min-width: 1280px) {
  .container {
    max-width: 1260px; }
  .container-16 {
    max-width: 1260px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }

.no-gutters {
  margin-left: 0;
  margin-right: 0; }

.no-gutters > *,
.no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0; }

[class*="col-"] {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1024px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1280px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

html {
  font-family: 'Be Vietnam', sans-serif;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

body {
  font-family: 'Be Vietnam', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  background-color: #ffffff;
  color: #333333;
  margin-top: 0;
  margin-bottom: 0; }

body, html {
  font-size: 16px; }
  @media screen and (max-width: 1024px) {
    body, html {
      font-size: 15px; } }
  @media screen and (max-width: 768px) {
    body, html {
      font-size: 14px; } }
  @media screen and (max-width: 575px) {
    body, html {
      font-size: 13px; } }

* {
  box-sizing: border-box; }
  *::before, *::after {
    box-sizing: border-box; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0; }

p {
  margin-top: 0;
  margin-bottom: 0; }

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit; }

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0;
  margin-left: 0; }

blockquote {
  margin: 0; }

b, strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  text-decoration: none;
  background-color: transparent;
  font-size: inherit;
  font-weight: inherit;
  color: inherit; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0;
  padding-bottom: 0;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

button:focus {
  outline: none;
  outline: 5px auto -webkit-focus-ring-color; }

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

select {
  word-wrap: normal; }

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden], .d-none, .hidden {
  display: none !important; }

input:focus, button:focus, a:focus, .button-prev:focus, .button-next:focus, textarea:focus, select:focus {
  outline: none; }

img {
  max-width: 100%;
  height: auto; }

.zoom-img {
  overflow: hidden; }
  .zoom-img:hover img {
    transform: scale(1.05); }
  .zoom-img img {
    transition: .3s all  ease-in-out; }

@keyframes Toptodown {
  from {
    color: #000000; }
  to {
    color: #ffffff; } }

@keyframes $zoom {
  from {
    transform: scale(1); }
  to {
    transform: scale(1.2); } }

.div {
  animation: zoom linear .2s; }

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@-webkit-keyframes returnToNormal {
  from { }
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
  to { }
  opacity: 1;
  -webkit-transform: none;
  transform: none; }

@keyframes returnToNormal {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes growth {
  from {
    transform: scale(0.7); }
  to {
    transform: scale(1); } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes backInDown {
  0% {
    transform: translateY(-1200px) scale(0.7);
    opacity: .7; }
  50% {
    transform: translateY(0px) scale(0.7);
    opacity: .7; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes backInUp {
  0% {
    transform: translateY(1200px) scale(0.7);
    opacity: .7; }
  80% {
    transform: translateY(0px) scale(0.7);
    opacity: .7; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes backInLeft {
  0% {
    transform: translateX(-2000px) scale(0.7);
    opacity: .7; }
  80% {
    transform: translateX(0px) scale(0.7);
    opacity: .7; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes fadeInTopLeft {
  from {
    opacity: 0;
    transform: translate3d(-15%, -180%, 0); }
  to {
    opacity: 1;
    transform: translateZ(0); } }

@keyframes fadeInTopRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, -100%, 0); }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0); } }

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(1200px) rotateX(90deg);
    transform: perspective(1200px) rotateX(90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(1200px) rotateX(-20deg);
    transform: perspective(1200px) rotateX(-20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(1200px) rotateX(10deg);
    transform: perspective(1200px) rotateX(10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(1200px) rotateX(-5deg);
    transform: perspective(1200px) rotateX(-5deg); }
  to {
    -webkit-transform: perspective(1200px);
    transform: perspective(1200px); } }

@media screen and (min-width: 1200px) {
  .animate__animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  .animation-left {
    opacity: 0;
    transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
    transform: translateX(-300px); }
  .animation-right {
    opacity: 0;
    transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
    transform: translateX(300px); }
  .animation-bottom {
    opacity: 0;
    transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
    transform: translateY(300px); }
  .animation-top {
    opacity: 0;
    transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
    transform: translateY(-300px); }
  .animation-left {
    opacity: 1;
    transform: translateX(0); }
  .animation-right {
    opacity: 1;
    transform: translateX(0); }
  .animation-bottom {
    opacity: 1;
    transform: translateY(0); }
  .animation-top {
    opacity: 1;
    transform: translateY(0); }
  .fadeInTopLeft, .fadeInTopRight {
    opacity: 0; }
  .current-page .backInLeft {
    -webkit-animation: backInLeft 1s;
    animation: backInLeft 1s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  .current-page .backInUp {
    animation: backInUp 1s;
    animation-timing-function: ease-out; }
  .current-page .fadeInTopLeft {
    animation: fadeInTopLeft;
    animation-duration: 1s;
    animation-delay: 400ms;
    animation-fill-mode: forwards; }
  .current-page .fadeInTopRight {
    animation: fadeInTopRight;
    animation-duration: 1s;
    animation-delay: 400ms;
    animation-fill-mode: forwards; }
  .current-page .moveInRight {
    animation: moveInRight 1.5s;
    animation-timing-function: ease-out; }
  .current-page .moveInLeft {
    animation: moveInLeft 1.5s;
    animation-timing-function: ease-out; }
  .current-page .backInDown {
    -webkit-animation: backInDown 1.5s;
    animation: backInDown 1s;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  .current-page .flipInX {
    -webkit-animation: flipInX 1.5s;
    animation: flipInX 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; } }

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 44px;
  border-radius: 10px;
  text-transform: uppercase;
  position: relative;
  transition: .3s all ease-in-out; }
  .btn a {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0.9375rem;
    position: relative;
    width: 100%;
    height: 100%; }
  .btn.btn-primary {
    background: #ffffff;
    color: #134d90;
    font-size: 14px;
    position: relative;
    font-weight: 700;
    margin-top: 1.875rem; }
    .btn.btn-primary::before {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      background: url("../img/btn-icon.png") no-repeat 100% 100%;
      background-position-x: right;
      background-size: contain;
      width: 100%;
      height: 100%;
      pointer-events: none;
      transition: .3s all ease-in-out; }
    .btn.btn-primary:hover {
      color: #17a654; }
      .btn.btn-primary:hover::before {
        background: url("../img/btn-icon-green.png") no-repeat;
        background-position-x: right; }

.btn-play {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100px;
  height: 100px;
  background-image: linear-gradient(to top, #17a654 0%, #134d90 100%);
  border-radius: 100%;
  border: 10px solid rgba(255, 255, 255, 0.8);
  transition: .3s all ease-in-out; }
  .btn-play::before {
    content: "";
    position: absolute;
    font-family: remixicon;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 4rem;
    color: #ffffff; }
  .btn-play:hover {
    transform: translate(-50%, -55%); }

.wrap-button {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%; }
  @media screen and (max-width: 1519.9px) {
    .wrap-button {
      position: static;
      display: flex;
      justify-content: center; } }
  .wrap-button .button-next,
  .wrap-button .button-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 10px;
    transition: .3s all ease-in-out; }
    @media screen and (max-width: 1023.9px) {
      .wrap-button .button-next,
      .wrap-button .button-prev {
        width: 30px; } }
    @media screen and (max-width: 1519.9px) {
      .wrap-button .button-next,
      .wrap-button .button-prev {
        position: static;
        margin-top: 1.875rem; } }
    .wrap-button .button-next.gray,
    .wrap-button .button-prev.gray {
      filter: brightness(1) invert(0);
      transition: .3s all ease-in-out; }
      .wrap-button .button-next.gray:hover,
      .wrap-button .button-prev.gray:hover {
        filter: brightness(0) invert(1); }
  .wrap-button .button-prev {
    background: url("../img/icon-2.png") no-repeat;
    left: -75px;
    transition: .3s all ease-in-out;
    filter: grayscale(1);
    background-size: contain; }
    .wrap-button .button-prev:hover {
      filter: grayscale(0);
      transition: .3s all ease-in-out; }
    @media screen and (max-width: 1519.9px) {
      .wrap-button .button-prev {
        margin-right: auto;
        transform: translateX(-80px); } }
  .wrap-button .button-next {
    background: url("../img/icon-1.png") no-repeat;
    background-size: contain;
    right: -75px;
    filter: grayscale(1);
    transition: .3s all ease-in-out; }
    @media screen and (max-width: 1519.9px) {
      .wrap-button .button-next {
        margin-left: auto;
        transform: translateX(80px); } }
    .wrap-button .button-next:hover {
      filter: grayscale(0);
      transition: .3s all ease-in-out; }
  .wrap-button.white .button-next:hover,
  .wrap-button.white .button-prev:hover {
    transition: .3s all;
    filter: brightness(0) invert(1); }

.bg-blue {
  background: #134d90;
  color: #ffffff; }

.bg-green {
  background: #17a654;
  color: #ffffff; }

.wrap-form .form-row {
  display: flex;
  margin: 0 -15px; }
  @media screen and (max-width: 768px) {
    .wrap-form .form-row {
      flex-wrap: wrap; } }
  .wrap-form .form-row .form-group {
    flex: 0 0 50%;
    padding: 0 15px;
    margin-bottom: 25px; }
    @media screen and (max-width: 768px) {
      .wrap-form .form-row .form-group {
        flex: 0 0 100%; } }
    .wrap-form .form-row .form-group input[type="text"] {
      width: 100%;
      height: 86px;
      border-radius: 5px;
      border: 1px solid #707070;
      background-color: #ffffff;
      padding: 20px;
      color: #000000;
      font-size: 1.25rem;
      font-weight: 400; }
      .wrap-form .form-row .form-group input[type="text"]::placeholder {
        color: #000000; }

@media (min-width: 1200px) {
  .full-page {
    overflow: hidden; }
    .full-page section {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      overflow: hidden;
      will-change: transform;
      backface-visibility: hidden;
      height: 100vh;
      position: fixed;
      width: 100%;
      transform: translateY(100vh);
      background-image: url("/Data/Sites/1/media/home-2/bg.png");
      background-size: cover;
      background-position: center center;
      transition: all 2s cubic-bezier(0.22, 0.44, 0, 1); }
      .full-page section:first-child {
        opacity: 1;
        transform: translateY(0); }
  .full-page section:nth-child(1) {
    z-index: 9;
    z-index: 1; }
  .full-page section:nth-child(2) {
    z-index: 8;
    z-index: 2; }
  .full-page section:nth-child(3) {
    z-index: 7;
    z-index: 3; }
  .full-page section:nth-child(4) {
    z-index: 6;
    z-index: 4; }
  .full-page section:nth-child(5) {
    z-index: 5;
    z-index: 5; }
  .full-page section:nth-child(6) {
    z-index: 4;
    z-index: 6; }
  .full-page section:nth-child(7) {
    z-index: 3;
    z-index: 7; }
  .full-page section:nth-child(8) {
    z-index: 2;
    z-index: 8; }
  .full-page section:nth-child(9) {
    z-index: 1;
    z-index: 9; }
  section.up-scroll {
    transform: translate3d(0, 0, 0); }
    section.up-scroll + section {
      transform: translate3d(0, 100vh, 0); }
  section.down-scroll {
    transform: translate3d(0, 0, 0); }
    section.down-scroll + section {
      transform: translate3d(0, 0, 0); } }

#nav-pagination {
  position: fixed;
  top: 50%;
  right: 0;
  padding: 10px 0px;
  transform: translateY(-50%);
  width: 54px;
  background-color: rgba(0, 0, 0, 0.18);
  z-index: 999;
  display: none; }
  #nav-pagination.active {
    display: block; }
  @media (max-width: 1200px) {
    #nav-pagination {
      display: none; } }
  #nav-pagination ul {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    #nav-pagination ul li {
      color: white;
      font-size: 26px;
      font-weight: 400;
      transition: .3s all  ease-in-out;
      cursor: pointer; }
      #nav-pagination ul li.active, #nav-pagination ul li:hover {
        color: #134d90; }
      #nav-pagination ul li.active {
        color: #134d90; }
      #nav-pagination ul li span {
        font-weight: 700;
        font-size: 10px;
        line-height: 1;
        text-transform: uppercase;
        text-align: right;
        white-space: nowrap;
        position: absolute;
        display: inline-block;
        top: 0;
        right: 20px;
        margin: 0;
        opacity: 0;
        visibility: hidden; }
      #nav-pagination ul li:hover span,
      #nav-pagination ul li.active span {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
        visibility: visible;
        color: #134d90; }

@-webkit-keyframes wheel {
  0% { }
  opacity: 0;
  transform: scale(0.3);
  top: 0;
  50% { }
  opacity: 1;
  transform: scale(1);
  100% { }
  opacity: 0;
  transform: scale(0.3);
  top: 20px; }

.wheel {
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 100px;
  right: 20px;
  opacity: 1;
  transition: opacity .3s ease-in-out;
  z-index: 5; }
  .wheel span {
    position: absolute;
    width: 100%;
    height: 2px;
    opacity: 0;
    transform: scale(0.3); }
    .wheel span:first-child {
      animation: wheel 2s ease-in-out .5s infinite; }
    .wheel span:nth-child(2) {
      animation: wheel 2s ease-in-out 1s infinite; }
    .wheel span:nth-child(3) {
      animation: wheel 2s ease-in-out infinite; }
    .wheel span:before, .wheel span:after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 50%;
      background-color: #fff; }
    .wheel span::before {
      left: 0;
      transform: skewY(30deg); }
    .wheel span::after {
      right: 0;
      width: 50%;
      transform: skewY(-30deg); }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { }
  opacity: 0;
  to { }
  opacity: 1; }

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { }
  opacity: 0;
  to { }
  opacity: 1; }

/* Internet Explorer */
@-ms-keyframes fadein {
  opacity: 0;
  opacity: 1; }

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { }
  opacity: 0;
  to { }
  opacity: 1; }

.full-page main {
  padding-top: 0; }

.full-page section {
  display: flex; }
  @media (min-width: 1200px) {
    .full-page section .animation-left {
      opacity: 0;
      transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
      transform: translateX(-300px); }
    .full-page section .animation-right {
      opacity: 0;
      transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
      transform: translateX(300px); }
    .full-page section .animation-bottom {
      opacity: 0;
      transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
      transform: translateY(300px); }
    .full-page section .animation-top {
      opacity: 0;
      transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
      transform: translateY(-300px); }
    .full-page section.current-page .animation-left {
      opacity: 1;
      transform: translateX(0); }
    .full-page section.current-page .animation-right {
      opacity: 1;
      transform: translateX(0); }
    .full-page section.current-page .animation-bottom {
      opacity: 1;
      transform: translateY(0); }
    .full-page section.current-page .animation-top {
      opacity: 1;
      transform: translateY(0); }
    .full-page section > div {
      display: flex;
      flex-direction: column; }
      .full-page section > div > .Module {
        width: 100%;
        height: 100%; } }

#fake-header {
  position: fixed;
  top: -50%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  left: 0;
  transform: translateX(-50%);
  z-index: 9999;
  min-width: 0;
  min-height: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #134d90;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.75) 0%, rgba(255, 203, 4, 0.75) 100%);
  background-size: cover;
  background-position: center center;
  transition: all 2s cubic-bezier(0.22, 0.44, 0, 1); }
  #fake-header .menu-fake-header {
    list-style: none;
    padding-left: 0;
    text-align: left; }
    #fake-header .menu-fake-header li {
      opacity: 0;
      transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
      transform: translateX(300px); }
      #fake-header .menu-fake-header li a {
        transition: .5s ease-in-out all;
        text-transform: uppercase;
        display: inline-block;
        opacity: 0;
        font-size: 1.875rem;
        font-weight: 700;
        line-height: 60px;
        padding: 0; }
      #fake-header .menu-fake-header li:hover a {
        position: relative;
        color: #ffffff;
        transform: translateX(35px); }
        #fake-header .menu-fake-header li:hover a::before {
          position: absolute;
          left: -30px;
          top: 50%;
          content: '';
          width: 20px;
          height: 1px;
          background-color: #ffffff; }
  #fake-header .close-button {
    display: flex;
    align-items: center;
    position: absolute;
    transform: rotate(90deg) translateY(-50%);
    top: 45%;
    cursor: pointer;
    right: 20px;
    transition: all 1s cubic-bezier(0.22, 0.44, 0, 1); }
    #fake-header .close-button em {
      font-size: 36px;
      margin-right: 10px; }
    #fake-header .close-button span {
      text-transform: uppercase; }
    #fake-header .close-button:hover {
      color: #ffffff; }
  #fake-header .logo-fake-header {
    position: absolute;
    top: 15vh;
    z-index: 11;
    opacity: 0;
    transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
    transform: translateY(300px); }
  #fake-header .menu-fake-header ul .item span {
    display: none; }
  #fake-header .menu-fake-header .Module-141 {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 2s all cubic-bezier(0.22, 0.44, 0, 1);
    transform: translateY(300px); }
    @media (min-width: 1025px) {
      #fake-header .menu-fake-header .Module-141 {
        display: none; } }
  #fake-header .menu-fake-bottom {
    position: absolute;
    top: 80%;
    left: 60%; }
    #fake-header .menu-fake-bottom .socail {
      display: flex;
      align-items: center;
      list-style: none;
      margin-top: 30px;
      padding-left: 0; }
      #fake-header .menu-fake-bottom .socail li {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        transition: all .5s; }
        #fake-header .menu-fake-bottom .socail li a em {
          color: #666666;
          font-size: 1.5rem;
          font-weight: 400; }
        #fake-header .menu-fake-bottom .socail li:hover {
          transition: all .5s; }
          #fake-header .menu-fake-bottom .socail li:hover a em {
            color: #ffffff; }
          #fake-header .menu-fake-bottom .socail li:hover:first-child {
            background-color: #38569a; }
          #fake-header .menu-fake-bottom .socail li:hover:nth-child(2) {
            background-color: #f80000; }
          #fake-header .menu-fake-bottom .socail li:hover:last-child {
            background: linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d); }
  @media (min-width: 1025px) {
    #fake-header .logo-fake-header {
      position: initial;
      top: initial;
      z-index: 21; }
    #fake-header .menu-fake-header {
      position: absolute;
      z-index: 11;
      justify-content: flex-end;
      left: 60%;
      top: 38%;
      padding-top: 60px; }
      #fake-header .menu-fake-header ul .item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end; }
        #fake-header .menu-fake-header ul .item a {
          position: relative; }
        #fake-header .menu-fake-header ul .item .number {
          font-size: 18px;
          font-weight: 300;
          display: block;
          padding-left: 25px;
          color: rgba(255, 255, 255, 0.5); }
        #fake-header .menu-fake-header ul .item.active a, #fake-header .menu-fake-header ul .item:hover a {
          text-decoration: underline; }
        #fake-header .menu-fake-header ul .item.active .number, #fake-header .menu-fake-header ul .item:hover .number {
          color: white; } }
  @media screen and (max-width: 1199.98px) {
    #fake-header {
      display: none; } }
  #fake-header.active {
    transition: all 2s cubic-bezier(0.22, 0.44, 0, 1);
    min-width: 1200px;
    min-height: 1200px;
    opacity: .9;
    visibility: visible;
    pointer-events: initial;
    overflow: hidden; }
    #fake-header.active .logo-fake-header {
      opacity: 1;
      transform: translateX(0); }
    #fake-header.active .menu-fake-header li {
      opacity: 1;
      transform: translateX(0); }
      #fake-header.active .menu-fake-header li:nth-child(1) {
        display: none; }
      #fake-header.active .menu-fake-header li:nth-child(2) {
        transition-delay: .2s; }
      #fake-header.active .menu-fake-header li:nth-child(3) {
        transition-delay: .3s; }
      #fake-header.active .menu-fake-header li:nth-child(4) {
        transition-delay: .4s; }
      #fake-header.active .menu-fake-header li:nth-child(5) {
        transition-delay: .5s; }
      #fake-header.active .menu-fake-header li:nth-child(6) {
        transition-delay: .6s; }
      #fake-header.active .menu-fake-header li:nth-child(7) {
        transition-delay: .7s; }
      #fake-header.active .menu-fake-header li:nth-child(8) {
        transition-delay: .8s; }
      #fake-header.active .menu-fake-header li:nth-child(9) {
        transition-delay: .9s; }
    #fake-header.active .menu-fake-header a {
      opacity: 1; }
    #fake-header.active .menu-fake-header .Module-141 {
      opacity: 1;
      transform: translateX(0); }
    #fake-header.active .main-menu .register-news {
      opacity: 1;
      transform: translateX(0); }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { }
  opacity: 0;
  to { }
  opacity: 1; }

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { }
  opacity: 0;
  to { }
  opacity: 1; }

/* Internet Explorer */
@-ms-keyframes fadein {
  opacity: 0;
  opacity: 1; }

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { }
  opacity: 0;
  to { }
  opacity: 1; }

#fake-header-open {
  position: absolute;
  transition: 2s cubic-bezier(0.22, 0.44, 0, 1) all;
  transform: translateY(-150px) rotate(90deg);
  right: 20px;
  top: 60px;
  z-index: 9998;
  text-transform: uppercase; }
  #fake-header-open .img {
    width: 50px;
    box-shadow: 0 2px 7px #0000003d;
    border-radius: 50%;
    cursor: pointer; }
  #fake-header-open.active {
    transform: translateY(0) rotate(0deg); }
  #fake-header-open .open-button {
    user-select: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    padding: 5px 12px;
    background: #134d90;
    color: #fff;
    position: relative; }
    #fake-header-open .open-button:after {
      content: '';
      height: calc(100% + 8px);
      width: calc(100% + 8px);
      position: absolute;
      border: 1px solid #134d90;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    #fake-header-open .open-button::before {
      content: '';
      height: 60px;
      width: 1px;
      left: 50%;
      top: -200%;
      background: #134d90;
      position: absolute; }
  @media screen and (max-width: 1199.98px) {
    #fake-header-open {
      display: none; } }

.menu-fullscreen {
  height: 100vh;
  position: fixed;
  z-index: 9999;
  background: #ffffff;
  width: 100%;
  top: 100%;
  opacity: 0;
  visibility: hidden;
  transform: translateY(100%);
  transition: all 0.95s cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .menu-fullscreen.active {
    top: 0;
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }
    .menu-fullscreen.active .close-button {
      transform: translateY(5px) rotate(90deg); }
  .menu-fullscreen .close-button {
    position: absolute;
    transition: 1s cubic-bezier(0.22, 0.44, 0, 1) all;
    transform: translateY(-300px) rotate(90deg);
    right: 20px;
    top: 60px;
    z-index: 9998;
    text-transform: uppercase; }
    .menu-fullscreen .close-button .icon-close {
      user-select: none;
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      padding: 5px 12px;
      background: #134d90;
      color: #fff;
      position: relative; }
      .menu-fullscreen .close-button .icon-close:after {
        content: '';
        height: calc(100% + 8px);
        width: calc(100% + 8px);
        position: absolute;
        border: 1px solid #134d90;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .menu-fullscreen .close-button .icon-close::before {
        content: '';
        height: 1px;
        width: 45px;
        left: -46px;
        top: 45%;
        background: #134d90;
        position: absolute; }
  .menu-fullscreen .primary-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 3.75rem;
    margin-top: 1.875rem; }
    .menu-fullscreen .primary-menu .logo {
      order: -1;
      margin: 0 auto 0.9375rem; }
    .menu-fullscreen .primary-menu ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto;
      justify-content: flex-start; }
      .menu-fullscreen .primary-menu ul li {
        display: block;
        text-align: center;
        margin: 0 0 0.9375rem;
        font-size: 1.25rem;
        width: max-content; }
        .menu-fullscreen .primary-menu ul li:hover .dropdown {
          display: none; }
    .menu-fullscreen .primary-menu .dropdown-menu .title em {
      padding-left: 5px;
      display: inline-block; }
    .menu-fullscreen .primary-menu .dropdown {
      position: relative;
      min-width: 190px;
      transform: translate(0);
      top: 0;
      left: 0;
      opacity: 1;
      visibility: visible;
      transition: unset; }
      .menu-fullscreen .primary-menu .dropdown .dropdown-item {
        padding: 0.9375rem;
        color: #ffffff;
        border: 0;
        border-bottom: 1px solid #ebebeb;
        background: linear-gradient(to top, #17a654 0%, rgba(23, 166, 84, 0.99) 1%, rgba(16, 125, 194, 0.35) 50%, rgba(16, 125, 194, 0.22) 60%); }
        .menu-fullscreen .primary-menu .dropdown .dropdown-item:last-child {
          border-bottom: 0; }
        .menu-fullscreen .primary-menu .dropdown .dropdown-item:hover a {
          color: #ffffff;
          text-decoration: underline; }
        .menu-fullscreen .primary-menu .dropdown .dropdown-item .dropdown {
          top: 0; }
    .menu-fullscreen .primary-menu .dropdown {
      display: none;
      background-color: #17a654; }

.news-list {
  content-visibility: auto;
  contain-intrinsic-size: 1410px; }

.dropdown {
  position: relative;
  cursor: pointer; }
  .dropdown .dropdown-menu {
    position: absolute;
    top: 150%;
    right: 0;
    width: max-content;
    background: #ffffff;
    border: 1px solid #134d90;
    padding: 10px 15px;
    opacity: 0;
    pointer-events: none;
    transition: .2s ease-in-out all;
    z-index: 11; }
  .dropdown a {
    transition: .2s ease-in-out all; }
    .dropdown a:hover {
      color: #134d90; }
  .dropdown.active .dropdown-menu, .dropdown:hover .dropdown-menu {
    top: 100%;
    pointer-events: auto;
    opacity: 1; }

.back-to-top {
  position: fixed;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #134d90;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 55px;
  right: 15px;
  z-index: 999;
  transition: .2s ease-in-out all;
  cursor: pointer;
  opacity: 0;
  pointer-events: none; }
  .back-to-top em {
    font-size: 20px;
    color: #134d90;
    transition: .2s ease-in-out all; }
  .back-to-top:hover {
    background: #134d90; }
    .back-to-top:hover em {
      color: #ffffff; }
  .back-to-top.active {
    opacity: 1;
    pointer-events: auto; }
  @media (max-width: 576px) {
    .back-to-top {
      width: 35px;
      height: 35px;
      background: #ffffff; }
      .back-to-top em {
        font-size: 14px; } }

.full-content {
  font-size: 1.25rem;
  font-weight: 400; }
  .full-content * {
    font-size: 1.25rem;
    font-weight: 400; }
    .full-content * + * {
      margin-top: 15px; }
  .full-content li {
    margin-top: 10px; }

ul.type-none {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.box-item {
  border-radius: 20px;
  background: #ffffff;
  overflow: hidden;
  transition: .2s ease-in-out all; }
  .box-item:hover .wrap-text .title {
    color: #17a654; }
  .box-item:hover .wrap-text time {
    background: #17a654; }
  .box-item .img {
    width: 100%;
    height: 260px;
    overflow: hidden; }
    .box-item .img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .box-item .wrap-text {
    padding: 30px 20px 30px 40px;
    position: relative; }
    .box-item .wrap-text .title {
      font-size: 22px;
      font-weight: 600;
      color: #333333;
      padding-bottom: 10px;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; }
      .box-item .wrap-text .title:hover {
        color: #17a654; }
    .box-item .wrap-text .desc {
      font-size: 14px;
      color: #333333;
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding-top: 10px;
      padding-bottom: 20px; }
    .box-item .wrap-text .view-detail {
      font-size: 13px;
      color: #134d90;
      text-transform: uppercase;
      font-weight: 600;
      position: relative;
      margin-top: auto;
      margin-top: 30px; }
      .box-item .wrap-text .view-detail::before {
        position: absolute;
        width: 20px;
        content: "";
        height: 2px;
        background: #134d90;
        right: -30px;
        top: 50%;
        transform: translateY(-50%);
        transition: .2s ease-in-out all; }
      .box-item .wrap-text .view-detail:hover {
        color: #17a654; }
        .box-item .wrap-text .view-detail:hover::before {
          background: #17a654;
          transform: translateX(10px); }
    .box-item .wrap-text time {
      font-size: 13px;
      padding: 2px 10px;
      color: #ffffff;
      font-weight: 500;
      background: #134d90;
      position: absolute;
      top: 32px;
      left: -33px;
      transform: rotate(270deg); }

.filed-nav {
  display: flex;
  width: 100%;
  justify-content: center; }
  @media screen and (min-width: 992px) {
    .filed-nav {
      position: sticky;
      top: 82px;
      z-index: 9999; } }
  .filed-nav ul {
    display: flex;
    align-items: center;
    margin-top: -22px;
    z-index: 99; }
    .filed-nav ul::-webkit-scrollbar {
      height: 4px; }
    .filed-nav ul::-webkit-scrollbar-thumb {
      background: #134d90; }
    .filed-nav ul::-webkit-scrollbar-thumb:hover {
      background: #134d90; }
    @media screen and (max-width: 1200px) {
      .filed-nav ul {
        overflow: auto;
        padding-bottom: 8px;
        scroll-snap-type: x mandatory; } }
    .filed-nav ul li {
      position: relative;
      scroll-snap-align: start; }
      .filed-nav ul li + li {
        margin-left: 10px; }
      .filed-nav ul li.active a {
        background-color: #134d90;
        color: white; }
      .filed-nav ul li a {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 15px 0 15px;
        overflow: hidden;
        height: 68px;
        padding: 0 20px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16);
        background-color: white;
        z-index: 99;
        color: #000000;
        font-size: 1.25rem;
        font-weight: 700;
        position: relative;
        transition: .3s all  ease-in-out;
        white-space: nowrap;
        cursor: pointer; }
        .filed-nav ul li a.active {
          background-color: #134d90;
          color: white; }
        .filed-nav ul li a:hover {
          color: white; }
          .filed-nav ul li a:hover::before {
            left: 0; }
        .filed-nav ul li a::before {
          content: '';
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background-color: #134d90;
          transition: .3s all  ease-in-out;
          z-index: -1; }

.apply-frm {
  padding: 30px 10px !important; }
  .apply-frm .title {
    font-weight: 700;
    font-size: 2rem; }
    @media screen and (min-width: 1280px) {
      .apply-frm .title {
        font-size: 24px; } }
  .apply-frm h4 {
    display: block;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 16px; }
    .apply-frm h4 span {
      margin: 0 -0.1875rem; }
  .apply-frm .form-group {
    margin-bottom: 20px;
    position: relative; }
    .apply-frm .form-group label {
      color: #134d90;
      font-size: 1rem;
      font-weight: 400;
      transition: .5s all ease-in-out; }
      .apply-frm .form-group label span {
        color: #ff0000; }
    .apply-frm .form-group input, .apply-frm .form-group textarea {
      padding: 10px 0;
      width: 100%;
      border: 0;
      height: 40px;
      outline: 0;
      background-color: transparent;
      border-bottom: thin solid #e0e0e0; }
  .apply-frm .frm-btnwrap {
    padding: 0 15px; }
    .apply-frm .frm-btnwrap .frm-btn {
      margin-left: 0;
      position: relative; }
      .apply-frm .frm-btnwrap .frm-btn.btn-seemore {
        background-color: transparent; }
        .apply-frm .frm-btnwrap .frm-btn.btn-seemore:hover {
          background-color: #134d90 !important; }
          .apply-frm .frm-btnwrap .frm-btn.btn-seemore:hover input {
            color: #ffffff; }
    .apply-frm .frm-btnwrap input {
      border-bottom: 0;
      color: #134d90;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      border: 1px solid #134d90; }
  .apply-frm .attachfile1, .apply-frm .attachfile2 {
    padding: 0 15px; }
    .apply-frm .attachfile1 label, .apply-frm .attachfile2 label {
      position: static; }
      .apply-frm .attachfile1 label span, .apply-frm .attachfile2 label span {
        color: #999999; }
    .apply-frm .attachfile1 .left .ruFileWrap.ruStyled, .apply-frm .attachfile2 .left .ruFileWrap.ruStyled {
      width: 160px;
      height: 40px;
      border-radius: 20px;
      background-color: #f5f5f5 !important;
      transition: .3s all ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative; }
      .apply-frm .attachfile1 .left .ruFileWrap.ruStyled input, .apply-frm .attachfile2 .left .ruFileWrap.ruStyled input {
        color: #333333;
        font-size: 0;
        font-weight: 400;
        border: 0 !important;
        outline: 0 !important;
        background: none !important; }
      .apply-frm .attachfile1 .left .ruFileWrap.ruStyled::before, .apply-frm .attachfile2 .left .ruFileWrap.ruStyled::before {
        content: "Chọn Tệp";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1rem;
        pointer-events: none; }

.en-us .apply-frm .attachfile1 .left .ruFileWrap.ruStyled::before, .en-us .apply-frm .attachfile2 .left .ruFileWrap.ruStyled::before {
  content: "Chose File";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  pointer-events: none; }

.main-section {
  padding: 25px 0; }
  @media (min-width: 768px) {
    .main-section {
      padding: 30px 0; } }
  @media (min-width: 1024px) {
    .main-section {
      padding: 30px 0; } }
  @media (min-width: 1280px) {
    .main-section {
      padding: 18px 0; } }
  @media only screen and (min-width: 1200px) and (max-height: 768px) {
    .main-section {
      padding: 20px 0; } }

.section-post {
  padding: 5rem 0px; }
  @media screen and (max-width: 992px) {
    .section-post {
      padding: 30px; } }
  @media screen and (max-width: 375px) {
    .section-post {
      padding: 20px; } }

.global-tab .tab-wrap ul {
  display: flex;
  justify-content: center;
  align-items: center; }

.global-tab .tab-wrap li {
  padding: 20px 40px;
  margin-right: 2px;
  background: #ffffff;
  font-size: 16px; }
  @media (max-width: 576px) {
    .global-tab .tab-wrap li {
      padding: 10px 20px; } }
  .global-tab .tab-wrap li a {
    color: #999999;
    font-weight: 600; }
  .global-tab .tab-wrap li:hover {
    cursor: pointer;
    background: linear-gradient(to left, rgba(23, 166, 84, 0.99), #107dc2); }
    .global-tab .tab-wrap li:hover a {
      color: #ffffff; }
  .global-tab .tab-wrap li:active {
    cursor: pointer;
    background: linear-gradient(to left, rgba(23, 166, 84, 0.99), #107dc2); }
    .global-tab .tab-wrap li:active a {
      color: #ffffff; }

.menu-list-tablets {
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(19, 77, 144, 0.28); }
  .menu-list-tablets li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; }
    .menu-list-tablets li a {
      font-size: 1.375rem;
      font-weight: 400;
      transition: .2s ease-in-out all;
      text-transform: uppercase;
      position: relative; }
      .menu-list-tablets li a::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        background: #134d90;
        width: 0;
        height: 2px; }
      .menu-list-tablets li a:hover::before {
        width: 100%; }
    .menu-list-tablets li.active a {
      text-shadow: 0.7px 0 0 #606060; }
      .menu-list-tablets li.active a::before {
        width: 100%; }

@media screen and (min-width: 768px) {
  .menu-list-tablets {
    justify-content: center;
    padding-top: 40px; }
    .menu-list-tablets li {
      width: auto;
      margin: 0 5%;
      margin-bottom: 20px;
      position: relative; }
      .menu-list-tablets li::before {
        content: '';
        position: absolute;
        top: 50%;
        right: -35%;
        transform: translateY(-50%);
        background: rgba(19, 77, 144, 0.28);
        width: 1px;
        height: 40px; }
      .menu-list-tablets li:last-child::before {
        display: none; }
      .menu-list-tablets li a {
        font-size: 1.375rem; } }

@media screen and (min-width: 1024px) {
  .menu-list-tablets {
    padding-top: 50px; }
    .menu-list-tablets li {
      margin-bottom: 35px; }
      .menu-list-tablets li::before {
        height: 50px; } }

@media screen and (min-width: 1280px) {
  .menu-list-tablets li {
    margin: 0 165px;
    margin-bottom: 35px; }
    .menu-list-tablets li:first-child {
      margin-left: 0; }
    .menu-list-tablets li:last-child {
      margin-right: 0; }
    .menu-list-tablets li::before {
      height: 57px;
      right: -165px; } }

.title-center {
  text-align: center;
  margin-bottom: 1.875rem; }
  .title-center .section-title {
    display: inline; }

.section-title {
  font-size: 2.25rem;
  font-weight: 700;
  color: #134d90;
  line-height: 2.625rem;
  position: relative;
  padding-left: 50px;
  margin-bottom: 1.875rem; }
  @media screen and (max-width: 767.9px) {
    .section-title {
      font-size: 2rem; } }
  .section-title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 33px;
    height: 46px;
    background: url("../img/bg-title.png") no-repeat; }
  .section-title.no-icon {
    padding-left: 0; }
    .section-title.no-icon::before {
      display: none; }
  .section-title.white {
    color: #ffffff;
    padding-left: 3.125rem; }
    .section-title.white::before {
      background: url("../img/bg-title-white.png") no-repeat; }
  .section-title.green {
    color: #17a654; }
  .section-title.main {
    color: #134d90; }

.title {
  color: #333333;
  font-weight: 700; }

.section-title2 {
  font-size: 2.25rem;
  font-weight: 700;
  color: #134d90;
  line-height: 2.625rem;
  position: relative;
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (max-width: 578px) {
    .section-title2 {
      font-size: 18px; } }
  .section-title2 .img {
    width: 33px;
    height: 46px;
    margin-right: 20px; }
  .section-title2 p {
    margin-bottom: -18px; }
  .section-title2.white p {
    color: #ffffff; }

.title-small {
  color: #666666;
  font-weight: 700;
  text-transform: uppercase; }
  .title-small.white {
    color: #ffffff; }

.title-lg {
  font-size: 2.25rem;
  font-weight: 700;
  color: transparent;
  background-image: linear-gradient(to top, #17a654 0%, #134d90 100%);
  background-clip: text; }

.d-flex {
  display: flex; }

.justify-between {
  justify-content: space-between; }

.justify-center {
  justify-content: center; }

.align-center {
  align-items: center; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.flex-wrap {
  flex-wrap: wrap; }

.text-white {
  color: #ffffff; }

.button-prev, .button-next {
  cursor: pointer; }

.line-bottom {
  border-bottom: 1px solid rgba(19, 77, 144, 0.28); }

.lcl {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical; }
  .lcl-1 {
    -webkit-line-clamp: 1; }
  .lcl-2 {
    -webkit-line-clamp: 2; }
  .lcl-3 {
    -webkit-line-clamp: 3; }
  .lcl-4 {
    -webkit-line-clamp: 4; }

.view-more {
  display: flex;
  justify-content: center; }
  .view-more .icon-link {
    width: 43px;
    height: 43px;
    border: 2px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-top: 10px;
    transition: .3s all  ease-in-out; }
    .view-more .icon-link:hover {
      background-color: #fff; }
      .view-more .icon-link:hover em {
        color: black; }
    .view-more .icon-link.black {
      border: 2px solid black; }
      .view-more .icon-link.black:hover {
        transform: translateX(-3px); }
    .view-more .icon-link em {
      color: white;
      transition: .3s all  ease-in-out; }

.tuyendung #pagebanner .box-text, .list-post #pagebanner .box-text, .post-detail #pagebanner .box-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 100%; }
  .tuyendung #pagebanner .box-text .title-line, .list-post #pagebanner .box-text .title-line, .post-detail #pagebanner .box-text .title-line {
    text-align: center;
    position: relative;
    padding-bottom: 3px; }
    .tuyendung #pagebanner .box-text .title-line::before, .list-post #pagebanner .box-text .title-line::before, .post-detail #pagebanner .box-text .title-line::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 179px;
      height: 3px;
      background-color: #fff; }
  .tuyendung #pagebanner .box-text .title-small, .tuyendung #pagebanner .box-text .desc, .list-post #pagebanner .box-text .title-small, .list-post #pagebanner .box-text .desc, .post-detail #pagebanner .box-text .title-small, .post-detail #pagebanner .box-text .desc {
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    padding-top: 5px;
    text-transform: none;
    max-width: 455px; }

.circle {
  overflow: hidden; }
  .circle img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.wrap-gallery-thumb {
  position: absolute;
  bottom: 30px;
  max-width: 972px;
  width: 100%;
  left: 5%;
  background-color: #134d90;
  border-radius: 0 40px;
  height: 164px;
  padding: 0 35px;
  z-index: 2; }
  @media screen and (max-width: 1198.98px) {
    .wrap-gallery-thumb {
      left: 50%;
      transform: translateX(-50%); } }
  @media screen and (max-width: 768px) {
    .wrap-gallery-thumb {
      max-width: 85%;
      padding: 0 25px; } }
  .wrap-gallery-thumb .gallery-thumbs-product {
    margin-top: -35px; }
    .wrap-gallery-thumb .gallery-thumbs-product .swiper-slide {
      cursor: pointer;
      width: auto; }
      .wrap-gallery-thumb .gallery-thumbs-product .swiper-slide .wrapper {
        position: relative; }
        .wrap-gallery-thumb .gallery-thumbs-product .swiper-slide .wrapper .bg-slide-service {
          border-radius: 0 30px 0 30px;
          height: 165px;
          padding-top: 36px;
          padding-left: 30px;
          overflow: hidden;
          position: relative;
          border: 1px solid #707070;
          transition: .3s all  ease-in-out;
          backdrop-filter: blur(25px); }
          .wrap-gallery-thumb .gallery-thumbs-product .swiper-slide .wrapper .bg-slide-service::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            border-radius: 0 30px 0 30px;
            overflow: hidden;
            background: rgba(255, 255, 255, 0.25);
            backdrop-filter: blur(25px);
            top: 0;
            left: 0; }
          .wrap-gallery-thumb .gallery-thumbs-product .swiper-slide .wrapper .bg-slide-service .title-slide {
            color: #1a1a1a;
            font-size: 1.75rem;
            font-weight: 700;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            padding-top: 22px;
            padding-left: 6px;
            text-transform: uppercase;
            position: relative;
            line-height: 1.2; }
            .wrap-gallery-thumb .gallery-thumbs-product .swiper-slide .wrapper .bg-slide-service .title-slide::before {
              content: '';
              position: absolute;
              top: 0;
              left: 8px;
              width: 125px;
              height: 5px;
              background: #e98727; }
            @media screen and (max-width: 1024.98px) {
              .wrap-gallery-thumb .gallery-thumbs-product .swiper-slide .wrapper .bg-slide-service .title-slide {
                font-size: 1.5625rem; } }
            @media screen and (max-width: 768px) {
              .wrap-gallery-thumb .gallery-thumbs-product .swiper-slide .wrapper .bg-slide-service .title-slide {
                font-size: 1.375rem; } }
    .wrap-gallery-thumb .gallery-thumbs-product .swiper-slide-thumb-active .wrapper .bg-slide-service .title-slide {
      color: #ffffff; }

.frm-btn-reset {
  display: none; }

.qupload {
  flex: 0 0 calc( 50% - 30px) !important;
  margin: 0 auto;
  border-radius: 5px;
  border: 1px solid #707070;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 768px) {
    .qupload {
      flex: 0 0 calc( 100% - 30px) !important;
      height: 86px; } }
  .qupload label {
    color: #000000;
    font-size: 1.25rem;
    font-weight: 400; }
  .qupload .RadUpload {
    width: fit-content; }
    .qupload .RadUpload .ruFileWrap {
      width: 146px;
      height: 54px;
      border-radius: 5px;
      background-color: #727272; }
    .qupload .RadUpload .ruBrowse {
      width: 146px;
      height: 54px;
      border-radius: 5px;
      background-color: #727272;
      color: #ffffff;
      font-size: 1.25rem;
      font-weight: 400;
      text-transform: uppercase; }

.btn-view-more {
  transition: .3s all  ease-in-out; }
  .btn-view-more a {
    transition: .3s all  ease-in-out; }
    .btn-view-more a:hover {
      color: #134d90 !important; }
      .btn-view-more a:hover img {
        background-color: #134d90; }
    .btn-view-more a img {
      transition: .3s all  ease-in-out; }
    .btn-view-more a:hover img {
      margin-left: 5px !important; }

span.fa-exclamation-triangle {
  color: #b10e02; }
  span.fa-exclamation-triangle::before {
    margin-right: 10px; }

@media screen and (min-width: 1200px) {
  .full-page .home-2 > div > .Module {
    display: flex;
    align-items: center;
    justify-content: center; } }

@media screen and (min-width: 1200px) {
  .full-page .home-3 > div > .Module {
    display: flex;
    align-items: center;
    justify-content: center; } }

@media screen and (min-width: 1200px) {
  .full-page .home-5 > div > .Module {
    display: flex;
    align-items: center;
    justify-content: center; } }

@media screen and (min-width: 1200px) {
  .full-page .home-6 > div > .Module {
    display: flex;
    align-items: center;
    justify-content: center; } }

@media screen and (min-width: 1200px) {
  .full-page .home-7 > div > .Module {
    display: flex;
    align-items: center;
    justify-content: center; } }

.full-page .ModuleContent {
  width: 100%; }

.wrap-table-main {
  overflow: auto; }
  .wrap-table-main table {
    width: 700px !important; }

.animation-zoom-img {
  width: 100vw !important;
  height: 100vh !important;
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  opacity: 1 !important; }

#fsm_actual {
  transition: .3s all  ease-in-out;
  z-index: -1; }
  #fsm_actual img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .3s all  ease-in-out; }
  #fsm_actual .title {
    display: none; }

.growing {
  transition: .3s all  ease-in-out; }

.sw-index {
  z-index: -1; }

.hidden-image {
  display: none; }

@keyframes testani {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

#wrapper {
  overflow: hidden; }

.inner-date {
  height: 24px;
  width: 96px;
  background-color: #134d90;
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  letter-spacing: 1.3px;
  color: #ffffff;
  font-size: 13px;
  text-align: center;
  transition: .3s all; }

.seemore {
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  color: #134d90;
  letter-spacing: 2.4px;
  display: inline-block;
  margin-top: 1.875rem;
  transition: .3s all; }
  .seemore::before {
    content: "";
    position: absolute;
    right: -25px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 1px;
    background-color: #134d90; }
  .seemore:hover {
    color: #17a654; }
    .seemore:hover::before {
      background-color: #17a654; }

.des {
  color: #666666;
  line-height: 1.5rem; }
  .des.white {
    color: #ffffff; }

.section {
  padding: 3.75rem 0 5rem; }
  @media screen and (max-width: 1023.9px) {
    .section {
      padding: 1.875rem 0; } }

.edit-link i:before {
  content: '✏️'; }

.ui-menu {
  display: none; }

@media screen and (min-width: 1280px) {
  [class*="about-us"] .container {
    max-width: 1250px; } }

@media screen and (min-width: 1600px) {
  [class*="about-us"] .container {
    max-width: 1440px; } }

.about-us-1 {
  background-position: center bottom -176px !important;
  padding-bottom: 200px;
  padding-top: 73px; }
  @media screen and (max-width: 1600px) {
    .about-us-1 {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media screen and (max-width: 1280px) {
    .about-us-1 {
      padding-bottom: 60px;
      padding-top: 60px; } }
  .about-us-1 .wrap-content {
    margin-top: 92px; }
    @media screen and (max-width: 992px) {
      .about-us-1 .wrap-content {
        margin-top: 30px; } }
    .about-us-1 .wrap-content .img {
      padding-right: 73px;
      width: 100%;
      height: auto;
      border-radius: 0px 40px 0px 40px;
      overflow: hidden; }
      @media screen and (max-width: 1600px) {
        .about-us-1 .wrap-content .img {
          padding-right: 0px; } }
      @media screen and (max-width: 1280px) {
        .about-us-1 .wrap-content .img {
          height: auto;
          padding-right: 0px;
          margin-bottom: 30px; } }
      .about-us-1 .wrap-content .img img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .about-us-1 .wrap-content .content {
      padding-left: 29px; }
      @media screen and (max-width: 1280px) {
        .about-us-1 .wrap-content .content {
          padding-left: 0; } }
      .about-us-1 .wrap-content .content .title {
        color: #1a1a1a;
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 42px;
        margin-bottom: 13px;
        text-transform: uppercase; }
        @media screen and (max-width: 992px) {
          .about-us-1 .wrap-content .content .title {
            line-height: 1.4; } }
      .about-us-1 .wrap-content .content .mota {
        height: calc( 634px  - 168px);
        overflow: auto;
        margin-right: -27px;
        padding-right: 20px; }
        @media screen and (max-width: 1600px) {
          .about-us-1 .wrap-content .content .mota {
            margin-right: 0px;
            height: 371px; } }
        @media screen and (max-width: 1024px) {
          .about-us-1 .wrap-content .content .mota {
            height: 250px; } }
        .about-us-1 .wrap-content .content .mota::-webkit-scrollbar {
          width: 3px; }
        .about-us-1 .wrap-content .content .mota::-webkit-scrollbar-track {
          background: #957e6e; }
        .about-us-1 .wrap-content .content .mota::-webkit-scrollbar-thumb {
          background: #134d90; }
        .about-us-1 .wrap-content .content .mota * {
          color: #000000;
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 32px; }

.about-us-2 {
  background-color: #134d90;
  padding-top: 51px;
  padding-bottom: 220px; }
  @media screen and (max-width: 1600px) {
    .about-us-2 {
      padding-bottom: 100px; } }
  @media screen and (max-width: 768px) {
    .about-us-2 {
      padding-bottom: 50px; } }
  .about-us-2 .title-small {
    margin-bottom: 100px; }
    @media screen and (max-width: 575px) {
      .about-us-2 .title-small {
        margin-bottom: 50px; } }
  .about-us-2 .swiper-wrapper {
    align-items: center; }
  .about-us-2 .wrap-slide {
    position: relative; }
  .about-us-2 .wrap-button {
    max-width: 1230px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    z-index: 999;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media screen and (max-width: 998px) {
      .about-us-2 .wrap-button {
        position: static;
        transform: translate(0);
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 30px; } }
    @media screen and (max-width: 1200px) {
      .about-us-2 .wrap-button {
        max-width: 955px; } }
    @media screen and (max-width: 998px) {
      .about-us-2 .wrap-button {
        max-width: 900px; } }
    .about-us-2 .wrap-button .button-next-1, .about-us-2 .wrap-button .button-prev-1 {
      width: 57px;
      height: 57px; }
      @media screen and (max-width: 998px) {
        .about-us-2 .wrap-button .button-next-1, .about-us-2 .wrap-button .button-prev-1 {
          position: static;
          transform: translate(0);
          margin: 0 10px; } }
  .about-us-2 .swiper-slide-active .post-item {
    transform: scaleY(1);
    transition: .3s all  ease-in-out; }
    .about-us-2 .swiper-slide-active .post-item .img {
      filter: blur(0px); }
      .about-us-2 .swiper-slide-active .post-item .img::before {
        backdrop-filter: blur(0px);
        background-color: rgba(0, 0, 0, 0); }
  .about-us-2 .post-item {
    display: block;
    height: 100%;
    transform: scaleY(0.9);
    transition: .3s all  ease-in-out; }
    @media screen and (max-width: 1280px) {
      .about-us-2 .post-item {
        transform: scaleY(0.95); } }
    .about-us-2 .post-item .img {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 55.68327%;
      padding-top: 55% !important;
      transition: .3s all  ease-in-out; }
      .about-us-2 .post-item .img img, .about-us-2 .post-item .img iframe, .about-us-2 .post-item .img video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
      .about-us-2 .post-item .img::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(7px);
        transition: .3s all  ease-in-out;
        z-index: 2; }
    .about-us-2 .post-item .text {
      height: 143px;
      background-color: #e3e3e3;
      position: relative;
      padding: 0 28px;
      padding-bottom: 10px;
      padding-top: 16px;
      z-index: 3; }
      .about-us-2 .post-item .text * {
        color: #000000;
        font-size: 1.375rem;
        font-weight: 500; }
        @media screen and (max-width: 575px) {
          .about-us-2 .post-item .text * {
            font-size: 1.2rem; } }
      .about-us-2 .post-item .text .date {
        text-shadow: 2px 3px 6px rgba(0, 0, 0, 0.68);
        color: #ffffff;
        font-size: 58px;
        font-weight: 700;
        line-height: 1;
        position: absolute;
        padding-bottom: 26px;
        top: -79px; }
        @media screen and (max-width: 575px) {
          .about-us-2 .post-item .text .date {
            font-size: 35px;
            top: -56px; } }
        .about-us-2 .post-item .text .date::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: 10px;
          width: 100%;
          background-color: #134d90; }

.about-us-3 {
  min-height: 1080px;
  padding-top: 100px;
  padding-bottom: 50px; }
  @media screen and (max-width: 998px) {
    .about-us-3 {
      min-height: auto;
      padding-top: 60px;
      padding-bottom: 60px; } }
  @media screen and (max-width: 998px) {
    .about-us-3 .col-xl-4 {
      order: 1; } }
  @media screen and (max-width: 998px) {
    .about-us-3 .col-xl-8 {
      order: 2; } }
  .about-us-3 .wrap-content {
    margin-top: 250px; }
    @media screen and (max-width: 992px) {
      .about-us-3 .wrap-content {
        margin-top: 50px; } }
  .about-us-3 .wrap-box {
    display: flex;
    padding-right: 80px; }
    @media screen and (max-width: 998px) {
      .about-us-3 .wrap-box {
        padding-right: 10px;
        margin-top: 20px; } }
    .about-us-3 .wrap-box .title {
      color: #134d90;
      font-size: 2.625rem;
      font-weight: 700;
      letter-spacing: 0.84px;
      text-transform: uppercase; }
    .about-us-3 .wrap-box .content {
      color: #1a1a1a;
      font-size: 1.8rem;
      font-weight: 400; }
      @media screen and (max-width: 998px) {
        .about-us-3 .wrap-box .content {
          font-size: 1.875rem; } }
    .about-us-3 .wrap-box img {
      width: 28px;
      height: 23px;
      margin-right: 16px;
      margin-top: 6px; }
  .about-us-3 .circle {
    width: 100%;
    background-color: #ffffff;
    opacity: 0.85;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -119px;
    width: 100%;
    position: relative; }
    .about-us-3 .circle::before {
      content: '';
      padding-top: 100%;
      display: block; }
    @media screen and (max-width: 1280px) {
      .about-us-3 .circle {
        margin-top: 0; } }
    @media screen and (max-width: 998px) {
      .about-us-3 .circle {
        margin: 0 auto;
        margin-bottom: 30px; } }
    .about-us-3 .circle p {
      max-width: 336px;
      width: 100%;
      color: #134d90;
      font-size: 2.625rem;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
      margin-top: -50px; }
      @media screen and (max-width: 998px) {
        .about-us-3 .circle p {
          margin-top: 0;
          font-size: 2.375rem; } }

.about-us-4 {
  min-height: 1080px;
  padding-top: 83px;
  padding-bottom: 52px; }
  .about-us-4 .circle {
    width: 353px;
    height: 354px;
    background-color: #ffffff;
    opacity: 0.85;
    border-radius: 50%;
    overflow: hidden; }
  .about-us-4 .text {
    display: flex;
    max-width: 348px; }
    .about-us-4 .text img {
      height: 31px;
      width: auto;
      margin-right: 10px; }
    .about-us-4 .text .content {
      color: #ffffff;
      font-size: 1.25rem;
      font-weight: 700; }
    .about-us-4 .text i {
      color: #ffffff;
      font-size: 1.25rem;
      font-weight: 700;
      font-style: italic;
      display: block; }
  .about-us-4 .wrap-content-1 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: -81px; }
    @media screen and (max-width: 998px) {
      .about-us-4 .wrap-content-1 {
        margin-top: 0px; } }
    @media screen and (max-width: 765px) {
      .about-us-4 .wrap-content-1 {
        flex-wrap: wrap;
        justify-content: center; } }
    .about-us-4 .wrap-content-1 .circle {
      margin-top: 0; }
    .about-us-4 .wrap-content-1 .text {
      display: flex;
      margin-left: -30px;
      flex: 1; }
      @media screen and (max-width: 765px) {
        .about-us-4 .wrap-content-1 .text {
          flex: 0 0 100%;
          margin-top: 30px;
          margin-left: 0px; } }
      .about-us-4 .wrap-content-1 .text img {
        height: 31px;
        width: auto;
        margin-right: 10px; }
      .about-us-4 .wrap-content-1 .text .content {
        color: #ffffff;
        font-size: 1.25rem;
        font-weight: 700; }
        .about-us-4 .wrap-content-1 .text .content i {
          color: #ffffff;
          font-size: 1.25rem;
          font-weight: 700;
          font-style: italic;
          display: block; }
  .about-us-4 .wrap-content-2 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 48px;
    flex-wrap: wrap; }
    .about-us-4 .wrap-content-2 .item-content {
      margin-bottom: 30px; }
    .about-us-4 .wrap-content-2 .circle {
      margin-bottom: 35px; }

.about-us-5 {
  max-height: 1080px;
  height: 100%;
  position: relative; }
  .about-us-5 .img {
    display: block;
    height: 100%;
    width: 100%;
    position: relative; }
    .about-us-5 .img::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3); }
    .about-us-5 .img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .about-us-5 .button-play {
    position: absolute;
    top: 50%;
    left: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%); }
    @media screen and (max-width: 575px) {
      .about-us-5 .button-play {
        width: 50px;
        height: auto; } }

.about-us-6 {
  min-height: 1080px;
  padding-top: 50px;
  padding-bottom: 50px; }
  @media screen and (max-width: 1600px) {
    .about-us-6 {
      min-height: auto; } }
  .about-us-6 .swiper-slide .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 57.17949%;
    border-radius: 0 40px 0px 40px; }
    .about-us-6 .swiper-slide .img img, .about-us-6 .swiper-slide .img iframe, .about-us-6 .swiper-slide .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .about-us-6 .text {
    color: #000000;
    font-size: 1.375rem;
    font-weight: 400;
    max-width: 946px;
    margin-top: 48px;
    padding-right: 15px; }
    @media screen and (max-width: 768px) {
      .about-us-6 .text {
        margin-top: 25px;
        padding-right: 0; } }
  .about-us-6 .wrap-button-text {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      .about-us-6 .wrap-button-text {
        flex-wrap: wrap;
        justify-content: flex-start; } }
  .about-us-6 .button {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 768px) {
      .about-us-6 .button {
        margin-top: 20px;
        justify-content: center;
        flex: 0 0 100%; } }
    .about-us-6 .button .button-next, .about-us-6 .button .button-prev {
      width: 57px;
      height: 57px;
      border: 2px solid #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%; }
    .about-us-6 .button .button-next {
      margin-left: 18px; }

.modal-about-slide {
  background-color: #333333 !important;
  max-width: 990px !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 auto;
  display: none; }
  .modal-about-slide .button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px; }
  .modal-about-slide .fancybox-close-small {
    position: fixed !important;
    color: white !important;
    transition: .3s all  ease-in-out;
    font-weight: bold;
    background: #4e4e4e !important; }
  .modal-about-slide .swiper-container {
    width: 100%; }
  .modal-about-slide .swiper-slide .img {
    width: 100%;
    height: 574px; }
    @media screen and (max-width: 992px) {
      .modal-about-slide .swiper-slide .img {
        height: auto; } }
    .modal-about-slide .swiper-slide .img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .modal-about-slide .button-prev, .modal-about-slide .button-next {
    width: 57px;
    height: 57px;
    border: 2px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 9px; }
    @media screen and (max-width: 575px) {
      .modal-about-slide .button-prev, .modal-about-slide .button-next {
        width: 40px;
        height: 40px; } }
  .modal-about-slide .fancybox-content {
    padding: 0px; }
  .modal-about-slide .swiper-pagination {
    position: static;
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: 400;
    margin-top: 90px; }
    @media screen and (max-width: 998px) {
      .modal-about-slide .swiper-pagination {
        margin-top: 45px; } }
    @media screen and (max-width: 575px) {
      .modal-about-slide .swiper-pagination {
        margin-top: 20px; } }

.About .fancybox-bg {
  background-color: #333333;
  opacity: 1 !important; }

.banner-home .swiper-container {
  width: 100%;
  height: 100%; }

.banner-home .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center; }

.banner-home .box-banner {
  position: relative;
  height: 67vh; }

.banner-home .img {
  height: 100%;
  position: relative; }
  .banner-home .img img {
    height: 100%;
    width: 100%;
    object-fit: cover; }
  .banner-home .img:before {
    content: "";
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 57%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }

.banner-home .box-text {
  position: absolute;
  top: 50%;
  left: 18%;
  transform: translateY(-50%); }
  @media screen and (max-width: 768px) {
    .banner-home .box-text {
      left: 5%; } }
  .banner-home .box-text h2 {
    font-size: 3rem;
    font-weight: 800;
    text-transform: uppercase;
    color: white;
    text-align: left;
    line-height: 1; }
    .banner-home .box-text h2 span {
      font-size: 1.875rem; }

#pagebanner {
  position: relative;
  height: auto; }
  @media screen and (max-width: 1024px) {
    #pagebanner {
      min-height: auto;
      height: auto; } }
  #pagebanner .img {
    width: 100%;
    height: auto;
    position: relative; }
    #pagebanner .img::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      pointer-events: none;
      background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9)); }
    #pagebanner .img img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  #pagebanner .box-text {
    position: absolute;
    bottom: 7.5rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    z-index: 111; }
    #pagebanner .box-text .title-line {
      color: #ffffff;
      font-size: 2.5rem;
      font-weight: 400;
      letter-spacing: 0.84px;
      text-transform: uppercase;
      position: relative;
      padding-bottom: 18px; }
      #pagebanner .box-text .title-line::before {
        width: 70%;
        height: 1.5px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        content: ""; }
  @media screen and (max-width: 1023.9px) {
    #pagebanner .title-line {
      font-size: 1.75rem; } }
  @media screen and (max-width: 768px) {
    #pagebanner .img {
      height: 50vh; }
    #pagebanner .box-text .title-line {
      padding-bottom: 12px; }
    #pagebanner .box-text .title-small {
      font-size: 1.75rem; } }
  @media screen and (max-width: 576px) {
    #pagebanner .img {
      height: auto; }
    #pagebanner .box-text .title-line {
      font-size: 1.875rem; } }

.main-san-pham .sp-banner {
  margin-top: 82px; }

.sp-banner {
  min-height: 1080px;
  position: relative;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 992px) {
    .sp-banner {
      height: 80vh; } }
  @media screen and (max-width: 575px) {
    .sp-banner {
      min-height: auto;
      height: auto;
      padding-top: 50px;
      padding-bottom: 120px; } }
  .sp-banner .line {
    height: 152px;
    width: 5px;
    background-color: #134d90;
    position: absolute;
    bottom: -76px;
    left: 50%;
    transform: translateX(-50%); }
  @media screen and (max-width: 1280px) {
    .sp-banner .wrap-text {
      margin-bottom: 30px; } }
  .sp-banner .wrap-text .title {
    color: #1a1a1a;
    font-size: 2.1875rem;
    font-weight: 700; }
  .sp-banner .wrap-text .title-sp {
    color: #134d90;
    font-size: 5.5rem;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 1.76px;
    text-transform: uppercase; }
    .sp-banner .wrap-text .title-sp span {
      font-size: 51px; }
  .sp-banner .wrap-product-main {
    padding-top: 300px; }
    @media screen and (max-width: 1280px) {
      .sp-banner .wrap-product-main {
        padding-top: 0px; } }
  .sp-banner .wrap-product {
    margin-top: -100px; }
    @media screen and (max-width: 1280px) {
      .sp-banner .wrap-product {
        margin-top: 0; } }
    .sp-banner .wrap-product .img {
      display: flex;
      justify-content: center;
      padding: 0 20px; }
    .sp-banner .wrap-product .box-ke {
      max-width: 906px;
      width: 100%;
      height: 132px;
      background-color: #134d90;
      margin-top: -67px; }
      @media screen and (max-width: 1280px) {
        .sp-banner .wrap-product .box-ke {
          max-width: 100%; } }
      @media screen and (max-width: 765px) {
        .sp-banner .wrap-product .box-ke {
          height: 100px; } }

.global-breadcrumb {
  width: 100%;
  position: absolute;
  transform: translateY(-100px);
  background-color: transparent;
  z-index: 111; }
  .global-breadcrumb .breadcrumb {
    display: flex;
    align-items: center;
    background-color: transparent;
    flex-wrap: wrap;
    padding: 10px 0;
    list-style: none;
    margin-bottom: 0;
    justify-content: center; }
    .global-breadcrumb .breadcrumb li {
      position: relative;
      padding: 0 15px;
      display: flex;
      align-items: center; }
      .global-breadcrumb .breadcrumb li a {
        font-size: 16px;
        color: #ffffff;
        font-weight: 500;
        text-decoration: none;
        transition: .2s all; }
        @media (max-width: 991.98px) {
          .global-breadcrumb .breadcrumb li a {
            font-size: 10px; } }
      .global-breadcrumb .breadcrumb li:hover a {
        color: #17a654; }
      .global-breadcrumb .breadcrumb li:first-child {
        padding-left: 0; }
        .global-breadcrumb .breadcrumb li:first-child a {
          font-size: 0; }
          .global-breadcrumb .breadcrumb li:first-child a::after {
            font-family: 'remixicon';
            font-size: 16px;
            content: "\ee20";
            color: #ffffff; }
    .global-breadcrumb .breadcrumb li + li::before {
      position: absolute;
      top: 50%;
      left: -7px;
      transform: translateY(-50%);
      font-family: Material Icons;
      font-size: 18px;
      content: "keyboard_arrow_right";
      color: #ffffff; }
  @media (min-width: 768.98px) {
    .global-breadcrumb {
      margin-bottom: 0; } }
  @media (min-width: 320px) {
    .global-breadcrumb {
      margin-bottom: 0; } }

.modulepager .pagination {
  margin-top: 20px;
  display: flex;
  list-style-type: none;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0; }
  @media screen and (min-width: 1024px) {
    .modulepager .pagination {
      margin-top: 30px; } }
  .modulepager .pagination li {
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .modulepager .pagination li:last-child {
      margin-right: 0; }
    .modulepager .pagination li a, .modulepager .pagination li span {
      width: 35px;
      height: 35px;
      font-size: 16px;
      border: 1px solid #134d90;
      color: #134d90;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      background: #ffffff;
      transition: .2s ease-in-out all;
      overflow: hidden;
      font-size: 1.125rem; }
    .modulepager .pagination li.active a, .modulepager .pagination li.active span, .modulepager .pagination li:hover a, .modulepager .pagination li:hover span {
      color: #ffffff;
      background: #17a654;
      border: 1px solid #17a654; }
    @media (min-width: 1024px) {
      .modulepager .pagination li a, .modulepager .pagination li span {
        width: 40px;
        height: 40px; } }

.search-page {
  padding: 40px 0; }
  .search-page h1 {
    letter-spacing: -2px;
    margin-bottom: 20px;
    font-size: 45px;
    line-height: 55px;
    font-weight: 500;
    color: #333333; }
    @media (max-width: 1024px) {
      .search-page h1 {
        font-size: 24px;
        line-height: 30px; } }
    .search-page h1.center {
      text-align: center; }
  .search-page .btn-reindex {
    display: none; }
  .search-page .searchcontrols .form-inline {
    position: relative; }
    .search-page .searchcontrols .form-inline:before {
      position: absolute;
      content: '\f349';
      font-family: "Material Design Icons";
      top: 0;
      right: 10px;
      z-index: 1;
      font-size: 24px;
      color: #4285f4;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .search-page .searchcontrols .form-inline .form-group:nth-child(2), .search-page .searchcontrols .form-inline .form-group:nth-child(3) {
      display: none; }
    .search-page .searchcontrols .form-inline .form-group:nth-child(4) {
      position: absolute;
      top: 0;
      right: 5px;
      z-index: 11; }
      .search-page .searchcontrols .form-inline .form-group:nth-child(4)::before {
        content: "\F0CD";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        font-family: remixicon !important;
        color: #134d90; }
  .search-page .searchcontrols .frm-btn {
    position: absolute;
    top: 0;
    right: 10px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2; }
  .search-page input[type="text"] {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 54px;
    border: 1px solid #dfe1e5;
    background-color: #fff;
    color: #333;
    font-size: .875rem;
    font-weight: 400;
    height: 44px;
    border-radius: 22px;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
    transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    outline: none; }
    .search-page input[type="text"]:focus {
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
      border-color: rgba(223, 225, 229, 0); }
  .search-page input[type="submit"] {
    border-radius: 2px;
    user-select: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    box-shadow: none;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    outline: none;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    font-size: 0; }
  .search-page .text-danger {
    margin-top: 20px;
    font-size: 15px;
    color: #333;
    font-style: italic;
    font-weight: 600; }
  .search-page .searchresultsummary {
    margin-bottom: 20px; }
  .search-page .searchresults {
    margin-top: 30px; }
    .search-page .searchresults .modulepager:first-child {
      display: none; }
  .search-page .searchresultlist {
    margin-bottom: 20px; }
  .search-page .searchresult {
    margin-bottom: 30px;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    padding: 16px;
    border-radius: 8px; }
    .search-page .searchresult:last-child {
      margin-bottom: 0; }
    .search-page .searchresult h3 {
      font-size: 18px;
      line-height: 1.33;
      font-weight: 400;
      margin-bottom: 10px; }
      .search-page .searchresult h3 a {
        color: #1A0DAB;
        text-decoration: none;
        font-weight: 500; }
        .search-page .searchresult h3 a:hover {
          text-decoration: underline; }
    .search-page .searchresult .searchresultdesc {
      color: #545454;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small;
      font-weight: 400;
      margin-bottom: 10px; }
    .search-page .searchresult .searchterm {
      color: #6a6a6a;
      font-weight: bold;
      font-style: normal;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small; }
    .search-page .searchresult hr {
      display: none !important; }

.search-document {
  padding-bottom: 20px; }
  .search-document .main-search {
    width: 100%; }
    .search-document .main-search .form-group {
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -ms-flex-wrap: wrap;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center; }
    .search-document .main-search input {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 85%;
      flex: 0 0 85%;
      border: 1px solid #134d90;
      padding: 5px 10px;
      max-width: 85%;
      height: 40px;
      margin-bottom: 0;
      background: #fff; }
    .search-document .main-search a {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 15%;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex: 0 0 15%;
      align-items: center;
      justify-content: center;
      border: 1px solid #00a650;
      background: #00a650;
      max-width: 15%;
      height: 40px; }
      .search-document .main-search a em {
        color: #fff; }

.custom-daotao .tintuc-ds {
  padding-bottom: 20px; }

.custom-date-view {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-box-align: center;
  -ms-flex-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .custom-date-view .view-number {
    color: #999;
    font-size: 14px; }

.tlm-tailieu .main-table table tbody tr td:nth-child(2) {
  text-align: left; }

.tlm-tailieu {
  padding-top: 0;
  padding-bottom: 0; }
  .tlm-tailieu .main-table table {
    border-collapse: collapse;
    width: 100%;
    text-align: center; }
  .tlm-tailieu .main-table td,
  .tlm-tailieu .main-table th {
    padding: 15px;
    line-height: 1; }
  .tlm-tailieu .main-table thead tr {
    background: #00a650; }
    .tlm-tailieu .main-table thead tr th {
      border-right: 1px solid #eaeee4;
      border-left: 1px solid #eaeee4; }
      .tlm-tailieu .main-table thead tr th:first-child {
        border-left: 1px solid #eff9e2; }
      .tlm-tailieu .main-table thead tr th:last-child {
        border-right: 1px solid #eff9e2; }
  .tlm-tailieu .main-table th {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase; }
  .tlm-tailieu .main-table td {
    color: #333;
    font-size: 16px;
    font-weight: 400; }
  .tlm-tailieu .main-table tbody tr {
    -webkit-transition: all .4s ease-in-out;
    -o-transition: .4s ease-in-out all;
    transition: all 0.4s ease-in-out;
    background: #fff; }
    .tlm-tailieu .main-table tbody tr a {
      -webkit-transition: all .4s ease-in-out;
      -o-transition: .4s ease-in-out all;
      transition: all 0.4s ease-in-out; }
    .tlm-tailieu .main-table tbody tr:hover {
      -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      cursor: pointer;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1); }
      .tlm-tailieu .main-table tbody tr:hover a,
      .tlm-tailieu .main-table tbody tr:hover td {
        color: #8dc63f;
        font-weight: 700; }
    .tlm-tailieu .main-table tbody tr td {
      border-right: 1px solid #eaeee4;
      border-left: 1px solid #eaeee4; }
      .tlm-tailieu .main-table tbody tr td:nth-child(2) {
        color: #333; }
      .tlm-tailieu .main-table tbody tr td:nth-child(3) {
        width: 240px; }
      .tlm-tailieu .main-table tbody tr td:last-child {
        border-right: 1px solid #eff9e2;
        width: 240px; }
      .tlm-tailieu .main-table tbody tr td:first-child {
        border-left: 1px solid #eff9e2;
        width: 90px; }
    .tlm-tailieu .main-table tbody tr:nth-child(2n) {
      background: #eff9e2; }

@media (max-width: 1025px) {
  .tlm-tailieu .main-table {
    overflow-x: auto; }
    .tlm-tailieu .main-table::-webkit-scrollbar {
      width: 2px;
      height: 4px; }
    .tlm-tailieu .main-table::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      box-shadow: none; }
    .tlm-tailieu .main-table::-webkit-scrollbar-thumb {
      background: #8dc63f; }
      .tlm-tailieu .main-table::-webkit-scrollbar-thumb:hover {
        background: #8dc63f; }
    .tlm-tailieu .main-table table {
      min-width: 960px; } }

.mdi-download:before {
  content: "\f1da"; }

.tong-quan-1.duan-ct .wrap-left .wrap-top {
  margin-bottom: 0; }

.tong-quan-1.duan-ct .wrap-left table tr td {
  margin-bottom: 1.875rem; }
  .tong-quan-1.duan-ct .wrap-left table tr td:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62px;
    height: 62px;
    border-radius: 10px;
    background-color: #ffffff; }
    @media screen and (max-width: 480px) {
      .tong-quan-1.duan-ct .wrap-left table tr td:first-child {
        padding: 5px 10px; } }
  .tong-quan-1.duan-ct .wrap-left table tr td:last-child {
    padding-left: 1.875rem;
    vertical-align: top; }

.tong-quan-1.duan-ct .wrap-left table tr:last-child {
  margin-bottom: 0; }

.tong-quan-1.duan-ct .btn-primary {
  color: #ffffff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(to top, #17a654 0%, #134d90 100%); }
  .tong-quan-1.duan-ct .btn-primary a {
    padding-left: 2.5rem; }
  .tong-quan-1.duan-ct .btn-primary::before {
    filter: brightness(0) invert(1); }
  .tong-quan-1.duan-ct .btn-primary:hover {
    transform: all .3s ease-in-out;
    transform: translateY(-5px); }

.duan-ct-2 .title-center {
  margin-bottom: 2.5rem; }

.duan-ct-2 .wrap-box {
  display: flex;
  border-radius: 20px;
  overflow: hidden; }
  @media screen and (max-width: 767.9px) {
    .duan-ct-2 .wrap-box {
      flex-wrap: wrap;
      border-radius: 0; } }
  .duan-ct-2 .wrap-box .item {
    width: 25%;
    position: relative; }
    @media screen and (max-width: 767.9px) {
      .duan-ct-2 .wrap-box .item {
        width: 50%; } }
    @media screen and (max-width: 479.9px) {
      .duan-ct-2 .wrap-box .item {
        width: 100%; } }
    .duan-ct-2 .wrap-box .item .img {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 100%; }
      .duan-ct-2 .wrap-box .item .img img, .duan-ct-2 .wrap-box .item .img iframe, .duan-ct-2 .wrap-box .item .img video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
    .duan-ct-2 .wrap-box .item .inner {
      position: absolute;
      bottom: 30px;
      left: 20px;
      z-index: 11; }
      .duan-ct-2 .wrap-box .item .inner .icon {
        margin-bottom: 10px; }
    .duan-ct-2 .wrap-box .item::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%); }
    .duan-ct-2 .wrap-box .item::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 52%, rgba(0, 0, 0, 0.3) 100%);
      z-index: 1;
      pointer-events: none;
      transition: .3s ease-in-out all; }

.du-an .wrap-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.75rem;
  flex-wrap: wrap; }
  @media screen and (max-width: 1023.9px) {
    .du-an .wrap-box {
      display: block; } }
  .du-an .wrap-box:last-child {
    margin-bottom: 0; }
  .du-an .wrap-box .wrap-left {
    flex: 0 0 50%; }
    @media screen and (max-width: 1023.9px) {
      .du-an .wrap-box .wrap-left {
        flex: 0 0 100%; } }
    .du-an .wrap-box .wrap-left .img {
      border-radius: 25px;
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 75.35322%; }
      .du-an .wrap-box .wrap-left .img img, .du-an .wrap-box .wrap-left .img iframe, .du-an .wrap-box .wrap-left .img video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
  .du-an .wrap-box .wrap-right {
    margin-left: 1.875rem;
    flex: 0 0 45%; }
    @media screen and (max-width: 1023.9px) {
      .du-an .wrap-box .wrap-right {
        flex: 0 0 100%;
        margin-left: 0; } }
    .du-an .wrap-box .wrap-right .wrap-content {
      padding-top: 1.875rem; }
      .du-an .wrap-box .wrap-right .wrap-content .content p {
        margin-bottom: 1.25rem; }
        .du-an .wrap-box .wrap-right .wrap-content .content p:first-child {
          font-weight: 700; }
  .du-an .wrap-box:nth-child(even) {
    flex-direction: row-reverse; }
    .du-an .wrap-box:nth-child(even) .wrap-right {
      margin-left: 0;
      margin-right: 1.875rem; }
      @media screen and (max-width: 1023.9px) {
        .du-an .wrap-box:nth-child(even) .wrap-right {
          margin-right: 0; } }

.lien-he {
  padding-top: 75px;
  padding-bottom: 95px; }
  @media screen and (max-width: 768px) {
    .lien-he {
      padding: 40px 0px; } }
  .lien-he > .container-16 > .row > * {
    padding-right: 0;
    padding-left: 0; }
  .lien-he .wrap-left .title {
    font-size: 22px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    background: linear-gradient(to left, rgba(23, 166, 84, 0.99), #107dc2);
    padding: 20px; }
  .lien-he .wrap-left .wrap-text-lh {
    background: #ffffff;
    padding: 20px 30px;
    border-bottom: 1px solid #e1e1e1; }
    .lien-he .wrap-left .wrap-text-lh:hover {
      background: #f5faff;
      cursor: pointer; }
    .lien-he .wrap-left .wrap-text-lh .title-lh {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      color: #134d90; }
    .lien-he .wrap-left .wrap-text-lh ul li {
      display: flex;
      padding: 10px 0;
      font-size: 14px; }
      .lien-he .wrap-left .wrap-text-lh ul li em {
        color: #134d90;
        margin-right: 10px;
        line-height: 1.7; }
  .lien-he .wrap-right {
    width: 100%;
    height: 100%; }

.lien-he-2 {
  padding-top: 95px;
  padding-bottom: 100px; }
  .lien-he-2 .wrap-form-lh {
    width: 80%;
    margin: 0 auto; }
    .lien-he-2 .wrap-form-lh .wrap-form input[type='text'] {
      height: 50px;
      border-radius: 50px;
      border: none;
      background-color: rgba(255, 255, 255, 0.78);
      color: #999999;
      font-size: 0.875rem;
      font-weight: 500;
      padding: 16px 25px; }
      .lien-he-2 .wrap-form-lh .wrap-form input[type='text']::placeholder {
        color: #999999;
        font-size: 14px;
        font-weight: 300; }
      .lien-he-2 .wrap-form-lh .wrap-form input[type='text']:focus {
        outline: none;
        border: 1px solid #134d90; }
    .lien-he-2 .wrap-form-lh .wrap-form textarea {
      width: 100%;
      height: 103px;
      border-radius: 20px;
      outline: none;
      border: none;
      background-color: rgba(255, 255, 255, 0.78);
      color: #999999;
      font-size: 0.875rem;
      font-weight: 500;
      padding: 16px 25px; }
      .lien-he-2 .wrap-form-lh .wrap-form textarea::placeholder {
        color: #999999;
        font-size: 14px;
        font-weight: 300; }
      .lien-he-2 .wrap-form-lh .wrap-form textarea:focus {
        outline: none;
        border: 1px solid #134d90; }
    .lien-he-2 .wrap-form-lh .frm-btn {
      display: flex;
      justify-content: flex-end;
      position: relative;
      overflow: hidden; }
      .lien-he-2 .wrap-form-lh .frm-btn input {
        color: #134d90;
        text-transform: uppercase;
        border: none;
        font-size: 0.875rem;
        font-weight: 600;
        margin-top: 10px;
        border-radius: 10px;
        width: 197px;
        height: 60px;
        transition: .3s all  ease-in-out;
        background-color: rgba(255, 255, 255, 0.78); }
        .lien-he-2 .wrap-form-lh .frm-btn input:hover {
          background-color: #134d90;
          color: white; }
      .lien-he-2 .wrap-form-lh .frm-btn::before {
        content: "";
        position: absolute;
        right: -30px;
        bottom: 0;
        background: url("../img/btn-icon.png") no-repeat;
        background-size: contain;
        width: 63px;
        height: 50px; }
      @media (max-width: 576px) {
        .lien-he-2 .wrap-form-lh .frm-btn input {
          box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
          font-size: 1.125rem;
          height: 50px;
          width: 130px; } }

.primary-nav {
  margin: 0 0 1.875rem;
  transition: .5s all ease-in-out; }
  .primary-nav ul {
    padding: 0;
    list-style: none;
    overflow-x: scroll;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media screen and (max-width: 767.9px) {
      .primary-nav ul {
        justify-content: flex-start; } }
    .primary-nav ul::-webkit-scrollbar {
      height: 1px; }
    .primary-nav ul::-webkit-scrollbar-thumb {
      background: #17a654 !important;
      width: 5px; }
      .primary-nav ul::-webkit-scrollbar-thumb:hover {
        background: #17a654 !important; }
    .primary-nav ul li {
      background: #ffffff;
      margin-right: 5px; }
      .primary-nav ul li:last-child {
        margin-right: 0; }
      .primary-nav ul li a {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 230px;
        padding: 1.25rem 0;
        transition: .5s all ease-in-out;
        font-weight: 700;
        color: #999999;
        white-space: nowrap; }
      .primary-nav ul li.active {
        background-image: linear-gradient(-145deg, #17a654 0%, #17a654 1%, #107dc2 100%); }
        .primary-nav ul li.active a {
          color: #ffffff; }
      .primary-nav ul li:hover {
        background-image: linear-gradient(-145deg, #17a654 0%, #17a654 1%, #107dc2 100%); }
        .primary-nav ul li:hover a {
          color: #ffffff; }

.thu-vien-anh {
  position: relative;
  background-image: url(../img/home-2/bg.png); }
  .thu-vien-anh.section {
    padding-top: 0; }
  .thu-vien-anh .heading {
    padding-top: 3.4375rem; }
    .thu-vien-anh .heading .sub-tab {
      margin-bottom: 1.875rem; }
      .thu-vien-anh .heading .sub-tab ul {
        display: flex;
        justify-content: center;
        align-items: center; }
        .thu-vien-anh .heading .sub-tab ul li {
          padding: 0 40px;
          position: relative;
          font-size: 16px;
          font-weight: 600;
          color: #999999; }
          @media (max-width: 576px) {
            .thu-vien-anh .heading .sub-tab ul li {
              font-size: 14px;
              padding: 0 20px; } }
          .thu-vien-anh .heading .sub-tab ul li:first-child::before {
            display: none; }
          .thu-vien-anh .heading .sub-tab ul li::before {
            width: 2px;
            height: 14px;
            position: absolute;
            content: "";
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-color: #999999; }
          .thu-vien-anh .heading .sub-tab ul li a:hover {
            color: #134d90;
            position: relative;
            transition: background-size .6s ease;
            background-image: linear-gradient(180deg, transparent 95%, #134d90 0);
            background-repeat: no-repeat;
            background-size: 0 100%; }
            .thu-vien-anh .heading .sub-tab ul li a:hover:hover {
              background-size: 100% 100%; }
          .thu-vien-anh .heading .sub-tab ul li.active a {
            text-decoration: underline;
            color: #134d90; }
      @media (max-width: 576px) {
        .thu-vien-anh .heading .sub-tab {
          display: flex;
          justify-content: center;
          align-items: center; }
          .thu-vien-anh .heading .sub-tab ul {
            overflow-x: auto;
            justify-content: flex-start; }
            .thu-vien-anh .heading .sub-tab ul li {
              width: max-content;
              white-space: nowrap; }
              .thu-vien-anh .heading .sub-tab ul li:first-child {
                padding-left: 0; } }
  .thu-vien-anh .thuvien-list .row:first-child .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 56.41026%; }
    .thu-vien-anh .thuvien-list .row:first-child .img img, .thu-vien-anh .thuvien-list .row:first-child .img iframe, .thu-vien-anh .thuvien-list .row:first-child .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .thu-vien-anh .thuvien-list .row:last-child .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 56.66667%; }
    .thu-vien-anh .thuvien-list .row:last-child .img img, .thu-vien-anh .thuvien-list .row:last-child .img iframe, .thu-vien-anh .thuvien-list .row:last-child .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .thu-vien-anh .thuvien-list .box-wrap {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    margin-bottom: 30px;
    transition: .2s ease-in-out all; }
    .thu-vien-anh .thuvien-list .box-wrap::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 100%;
      left: 0;
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 52%, rgba(0, 0, 0, 0.5) 100%);
      z-index: 21;
      pointer-events: none;
      transition: .3s ease-in-out all; }
    .thu-vien-anh .thuvien-list .box-wrap::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%);
      pointer-events: none;
      z-index: 21;
      transition: .3s ease-in-out all; }
    .thu-vien-anh .thuvien-list .box-wrap:hover::after {
      top: 0%; }
    .thu-vien-anh .thuvien-list .box-wrap:hover::before {
      top: -100%; }
    .thu-vien-anh .thuvien-list .box-wrap .info {
      position: absolute;
      left: 50px;
      bottom: 10%;
      z-index: 22; }
      .thu-vien-anh .thuvien-list .box-wrap .info a {
        font-size: 14px;
        color: #ffffff;
        transition: .2s ease-in-out all;
        position: relative; }
        .thu-vien-anh .thuvien-list .box-wrap .info a:hover {
          cursor: pointer;
          border-bottom: 1px solid #ffffff; }
        .thu-vien-anh .thuvien-list .box-wrap .info a::before {
          content: "\EB2E";
          position: absolute;
          font-family: "remixicon";
          bottom: 0;
          left: -25px;
          font-size: 18px;
          line-height: 1;
          z-index: 2; }
  .thu-vien-anh .thuvien-list .modal-img {
    display: none; }
    .thu-vien-anh .thuvien-list .modal-img .img {
      width: 100%;
      height: 100%; }

.thu-vien-video {
  position: relative;
  background-image: url(../img/home-2/bg.png); }
  .thu-vien-video.section {
    padding-top: 0; }
  .thu-vien-video .heading {
    padding-top: 3.4375rem; }
    .thu-vien-video .heading .sub-tab {
      margin-bottom: 1.875rem; }
      .thu-vien-video .heading .sub-tab ul {
        display: flex;
        justify-content: center;
        align-items: center; }
        .thu-vien-video .heading .sub-tab ul li {
          padding: 0 40px;
          position: relative;
          font-size: 16px;
          font-weight: 600;
          color: #999999; }
          @media (max-width: 576px) {
            .thu-vien-video .heading .sub-tab ul li {
              font-size: 14px;
              padding: 0 20px; } }
          .thu-vien-video .heading .sub-tab ul li:first-child {
            padding-left: 0; }
            .thu-vien-video .heading .sub-tab ul li:first-child::before {
              display: none; }
          .thu-vien-video .heading .sub-tab ul li:last-child {
            padding-right: 0; }
          .thu-vien-video .heading .sub-tab ul li::before {
            width: 2px;
            height: 14px;
            position: absolute;
            content: "";
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-color: #999999; }
          .thu-vien-video .heading .sub-tab ul li a:hover {
            color: #134d90;
            position: relative;
            transition: background-size .6s ease;
            background-image: linear-gradient(180deg, transparent 95%, #134d90 0);
            background-repeat: no-repeat;
            background-size: 0 100%; }
            .thu-vien-video .heading .sub-tab ul li a:hover:hover {
              background-size: 100% 100%; }
          .thu-vien-video .heading .sub-tab ul li.active a {
            color: #134d90;
            text-decoration: underline; }
      @media (max-width: 576px) {
        .thu-vien-video .heading .sub-tab {
          display: flex;
          justify-content: center;
          align-items: center; }
          .thu-vien-video .heading .sub-tab ul {
            overflow-x: auto;
            justify-content: flex-start; }
            .thu-vien-video .heading .sub-tab ul li {
              width: max-content;
              white-space: nowrap; }
              .thu-vien-video .heading .sub-tab ul li:first-child {
                padding-left: 0; } }
  .thu-vien-video .thuvien-list .row:first-child .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 56.41026%; }
    .thu-vien-video .thuvien-list .row:first-child .img img, .thu-vien-video .thuvien-list .row:first-child .img iframe, .thu-vien-video .thuvien-list .row:first-child .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .thu-vien-video .thuvien-list .row:last-child .img {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 56.66667%; }
    .thu-vien-video .thuvien-list .row:last-child .img img, .thu-vien-video .thuvien-list .row:last-child .img iframe, .thu-vien-video .thuvien-list .row:last-child .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }
  .thu-vien-video .thuvien-list .box {
    margin-bottom: 30px; }
  .thu-vien-video .thuvien-list .box-wrap {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    transition: .5s ease-in-out all;
    width: 100%;
    padding: 0;
    cursor: pointer; }
    .thu-vien-video .thuvien-list .box-wrap::after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 100%;
      left: 0;
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 52%, rgba(0, 0, 0, 0.5) 100%);
      z-index: 21;
      pointer-events: none;
      transition: .3s ease-in-out all; }
    .thu-vien-video .thuvien-list .box-wrap::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.1) 100%);
      pointer-events: none;
      z-index: 21;
      transition: .3s ease-in-out all; }
    .thu-vien-video .thuvien-list .box-wrap:hover::after {
      top: 0%; }
    .thu-vien-video .thuvien-list .box-wrap:hover::before {
      top: -100%; }
    .thu-vien-video .thuvien-list .box-wrap .img {
      width: 100%;
      height: 100%;
      z-index: 11; }
      .thu-vien-video .thuvien-list .box-wrap .img img {
        object-fit: cover;
        width: 100%;
        height: 100%; }
    .thu-vien-video .thuvien-list .box-wrap .info {
      position: absolute;
      left: 40px;
      bottom: 25px;
      right: 20px;
      z-index: 111;
      text-align: left; }
      .thu-vien-video .thuvien-list .box-wrap .info a {
        font-size: 14px;
        color: #ffffff;
        transition: .2s ease-in-out all;
        position: relative;
        text-align: center; }
        .thu-vien-video .thuvien-list .box-wrap .info a:hover {
          cursor: pointer;
          border-bottom: 1px solid #ffffff; }
        .thu-vien-video .thuvien-list .box-wrap .info a::before {
          content: "\F287";
          position: absolute;
          font-family: "remixicon";
          top: 3px;
          left: -25px;
          transform: translateY(0);
          font-size: 18px;
          line-height: 1;
          z-index: 2; }
  .thu-vien-video .thuvien-list .modal-img {
    display: none; }
    .thu-vien-video .thuvien-list .modal-img .img {
      width: 100%;
      height: 100%; }

.tuyen-dung-1 .section-title {
  margin-bottom: 0; }
  @media screen and (max-width: 568px) {
    .tuyen-dung-1 .section-title {
      font-size: 28px; } }

.tuyen-dung-1 .wrap-post {
  padding: 1.875rem 0; }
  .tuyen-dung-1 .wrap-post [class*='col-'] {
    margin-bottom: 10px; }
    .tuyen-dung-1 .wrap-post [class*='col-']:nth-child(odd) {
      padding-right: 5px; }
      @media screen and (max-width: 1023.9px) {
        .tuyen-dung-1 .wrap-post [class*='col-']:nth-child(odd) {
          padding-right: 0; } }
    .tuyen-dung-1 .wrap-post [class*='col-']:nth-child(even) {
      padding-left: 5px; }
      @media screen and (max-width: 1023.9px) {
        .tuyen-dung-1 .wrap-post [class*='col-']:nth-child(even) {
          padding-left: 0; } }
    .tuyen-dung-1 .wrap-post [class*='col-'] .box-wrap {
      border-radius: 20px 0 0 0; }
    .tuyen-dung-1 .wrap-post [class*='col-']:nth-child(2) .wrapper-img {
      border-radius: 0 20px 0 0; }
    .tuyen-dung-1 .wrap-post [class*='col-']:nth-child(3) .wrapper-img {
      border-radius: 0 0 0 20px; }
    .tuyen-dung-1 .wrap-post [class*='col-']:nth-child(4) .wrapper-img {
      border-radius: 0 0 20px 0; }
  .tuyen-dung-1 .wrap-post .box-wrap {
    overflow: hidden;
    padding: 3.75rem 4.6875rem 0 2.5rem;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 360px; }
    @media screen and (max-width: 1024px) {
      .tuyen-dung-1 .wrap-post .box-wrap {
        padding: 3.125rem 3.125rem 1.875rem 2.5rem; } }
    @media screen and (max-width: 768px) {
      .tuyen-dung-1 .wrap-post .box-wrap {
        padding: 1.875rem; } }
    .tuyen-dung-1 .wrap-post .box-wrap .desc p {
      font-size: 16px;
      color: #ffffff;
      line-height: 1.8;
      margin-top: 30px; }
  .tuyen-dung-1 .wrap-post .wrapper-img {
    overflow: hidden;
    position: relative;
    transition: 0.8s cubic-bezier(0.42, 0, 0.22, 1.02);
    cursor: pointer;
    height: 360px; }
    .tuyen-dung-1 .wrap-post .wrapper-img:hover::after {
      display: none; }
    .tuyen-dung-1 .wrap-post .wrapper-img:hover::before {
      top: 0; }
    .tuyen-dung-1 .wrap-post .wrapper-img:hover .text-desc .desc {
      max-height: 100%;
      opacity: 1;
      visibility: visible;
      transition: .3s all; }
    .tuyen-dung-1 .wrap-post .wrapper-img .img {
      width: 100%;
      height: 100%; }
      .tuyen-dung-1 .wrap-post .wrapper-img .img a img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .tuyen-dung-1 .wrap-post .wrapper-img::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0) 100%);
      transition: .3s all ease-in-out; }
    .tuyen-dung-1 .wrap-post .wrapper-img::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 100%;
      left: 0;
      background-image: linear-gradient(to top, #17a654 0%, rgba(23, 166, 84, 0.99) 1%, rgba(16, 125, 194, 0.34) 72%, rgba(16, 125, 194, 0.22) 85%);
      transition: .3s all ease-in-out; }
    .tuyen-dung-1 .wrap-post .wrapper-img .text-desc {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 1.875rem 1.25rem 1.5625rem 1.875rem;
      z-index: 2; }
      .tuyen-dung-1 .wrap-post .wrapper-img .text-desc .img {
        width: 45px; }
      .tuyen-dung-1 .wrap-post .wrapper-img .text-desc .title {
        font-size: 20px;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 600;
        padding-top: 12px;
        transition: .3s all; }
      .tuyen-dung-1 .wrap-post .wrapper-img .text-desc .desc {
        padding-top: 20px;
        font-size: 16px;
        color: #ffffff;
        line-height: 1.8;
        max-height: 0;
        opacity: 0;
        visibility: visible;
        transition: .3s all ease-in-out; }

.tuyeng-dung-2 {
  padding: 30px 0;
  height: 100%; }
  .tuyeng-dung-2 .box-wrap > .row {
    margin-bottom: -30px; }
    .tuyeng-dung-2 .box-wrap > .row > * {
      margin-bottom: 30px; }
  .tuyeng-dung-2 .box-wrap .box-content {
    display: block;
    border: 1px dashed #134d90;
    border-radius: 20px;
    padding: 20px;
    position: relative;
    background: #ffffff;
    transition: .3s ease-in-out all;
    box-shadow: -8px 0 46px rgba(0, 0, 0, 0.09);
    display: block;
    width: 100%; }
    @media screen and (min-width: 1520px) {
      .tuyeng-dung-2 .box-wrap .box-content {
        height: 110px; } }
    @media screen and (min-width: 576px) {
      .tuyeng-dung-2 .box-wrap .box-content {
        height: 140px; } }
    .tuyeng-dung-2 .box-wrap .box-content:hover {
      background: linear-gradient(to top, #17a654, #107dc2);
      color: #ffffff;
      cursor: pointer;
      border: 1px dashed transparent; }
      .tuyeng-dung-2 .box-wrap .box-content:hover * {
        color: #ffffff !important;
        transition: .3s all ease-in-out; }
    .tuyeng-dung-2 .box-wrap .box-content .title {
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 600; }
    .tuyeng-dung-2 .box-wrap .box-content .info {
      max-width: 60%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;
      padding: 0;
      list-style: none; }
      @media screen and (max-width: 767.9px) {
        .tuyeng-dung-2 .box-wrap .box-content .info {
          max-width: 100%; } }
      .tuyeng-dung-2 .box-wrap .box-content .info .item {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #999999;
        padding: 5px 0; }
        .tuyeng-dung-2 .box-wrap .box-content .info .item em {
          margin-right: 10px; }
        .tuyeng-dung-2 .box-wrap .box-content .info .item.green * {
          color: #17a654; }
    .tuyeng-dung-2 .box-wrap .box-content .view-more {
      font-size: 14px;
      color: #999999;
      font-weight: 600;
      position: absolute;
      right: 20px;
      bottom: 20px; }
      @media screen and (max-width: 767.9px) {
        .tuyeng-dung-2 .box-wrap .box-content .view-more {
          position: static; } }
      .tuyeng-dung-2 .box-wrap .box-content .view-more a:hover {
        position: relative;
        transition: background-size .6s ease;
        background-image: linear-gradient(180deg, transparent 95%, #ffffff 0);
        background-repeat: no-repeat;
        background-size: 0 100%; }
        .tuyeng-dung-2 .box-wrap .box-content .view-more a:hover:hover {
          background-size: 100% 100%; }

.tuyendung-list .hidden-td-list {
  display: none; }

.tuyen-dung-ct .wrapper-box {
  margin-top: 30px; }
  .tuyen-dung-ct .wrapper-box .box-left-top {
    border: 1px dashed #134d90;
    border-radius: 20px;
    overflow: hidden;
    background: #ffffff;
    margin-bottom: 30px; }
    .tuyen-dung-ct .wrapper-box .box-left-top .img {
      width: 100%;
      height: 100%; }
      .tuyen-dung-ct .wrapper-box .box-left-top .img img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .tuyen-dung-ct .wrapper-box .wrap-content {
    padding-bottom: 1.875rem; }
    .tuyen-dung-ct .wrapper-box .wrap-content .heading {
      border-bottom: thin solid #e1e1e1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0 15px 25px; }
      .tuyen-dung-ct .wrapper-box .wrap-content .heading .fb {
        font-size: 18px;
        color: #999999;
        padding-right: 20px;
        transition: .3s all ease-in-out; }
        .tuyen-dung-ct .wrapper-box .wrap-content .heading .fb:hover {
          color: #134d90; }
    .tuyen-dung-ct .wrapper-box .wrap-content .info {
      overflow-x: auto; }
      .tuyen-dung-ct .wrapper-box .wrap-content .info::-webkit-scrollbar {
        height: 1px; }
      .tuyen-dung-ct .wrapper-box .wrap-content .info::-webkit-scrollbar-thumb {
        background: #17a654 !important;
        width: 5px; }
        .tuyen-dung-ct .wrapper-box .wrap-content .info::-webkit-scrollbar-thumb:hover {
          background: #17a654 !important; }
      .tuyen-dung-ct .wrapper-box .wrap-content .info table {
        width: 100%; }
        @media screen and (max-width: 767.9px) {
          .tuyen-dung-ct .wrapper-box .wrap-content .info table {
            min-width: 510px; } }
        .tuyen-dung-ct .wrapper-box .wrap-content .info table tr {
          padding: 0 20px; }
          @media screen and (max-width: 587px) {
            .tuyen-dung-ct .wrapper-box .wrap-content .info table tr td {
              font-size: 12px; }
              .tuyen-dung-ct .wrapper-box .wrap-content .info table tr td:first-child {
                width: 10%; } }
          .tuyen-dung-ct .wrapper-box .wrap-content .info table tr:last-child td * {
            color: #e60606; }
        .tuyen-dung-ct .wrapper-box .wrap-content .info table td {
          padding: 10px;
          color: #666666; }
          .tuyen-dung-ct .wrapper-box .wrap-content .info table td:first-child {
            width: 10%;
            text-align: center; }
          .tuyen-dung-ct .wrapper-box .wrap-content .info table td:nth-child(2) {
            width: 35%; }
          .tuyen-dung-ct .wrapper-box .wrap-content .info table td:last-child {
            width: 55%; }
          .tuyen-dung-ct .wrapper-box .wrap-content .info table td i {
            margin-right: 10px; }
          .tuyen-dung-ct .wrapper-box .wrap-content .info table td p {
            font-size: 15px; }
    .tuyen-dung-ct .wrapper-box .wrap-content .wrap-option {
      display: flex;
      padding: 0 20px; }
      @media screen and (max-width: 767.9px) {
        .tuyen-dung-ct .wrapper-box .wrap-content .wrap-option {
          flex-direction: column;
          align-content: center; } }
      .tuyen-dung-ct .wrapper-box .wrap-content .wrap-option .btn {
        color: #ffffff; }
        .tuyen-dung-ct .wrapper-box .wrap-content .wrap-option .btn::before {
          filter: brightness(0) invert(1); }
        .tuyen-dung-ct .wrapper-box .wrap-content .wrap-option .btn:hover {
          transform: translateY(-3px); }
      .tuyen-dung-ct .wrapper-box .wrap-content .wrap-option .blue {
        background: #134d90;
        margin-right: 1.875rem; }
      .tuyen-dung-ct .wrapper-box .wrap-content .wrap-option .green {
        background: #17a654;
        width: fit-content;
        padding-right: 60px; }
  .tuyen-dung-ct .wrapper-box .wrap-desc .info {
    padding: 10px 20px 30px 40px; }
    .tuyen-dung-ct .wrapper-box .wrap-desc .info li {
      font-size: 16px;
      color: #333333;
      padding: 10px 0;
      position: relative; }
      .tuyen-dung-ct .wrapper-box .wrap-desc .info li::before {
        position: absolute;
        content: "\EB7C";
        font-family: "remixicon";
        transform: translateY(7px);
        font-size: 8px;
        left: -20px;
        color: #999999; }
  .tuyen-dung-ct .wrapper-box .wrap-desc .wrap-desc-content {
    padding: 20px 20px 20px 40px; }
    .tuyen-dung-ct .wrapper-box .wrap-desc .wrap-desc-content ul {
      padding-left: 25px; }

.tuyen-dung-ct .box-right {
  border: 1px dashed #134d90;
  border-radius: 20px;
  background: #ffffff;
  overflow: hidden; }
  .tuyen-dung-ct .box-right .box-content .box-list {
    display: block;
    padding: 20px;
    border-bottom: 1px solid #e1e1e1;
    transition: .3s all ease-in-out;
    cursor: pointer;
    background: #ffffff; }
    .tuyen-dung-ct .box-right .box-content .box-list:hover {
      background: linear-gradient(to left, rgba(23, 166, 84, 0.99), #107dc2);
      transition: .3s all ease-in-out; }
      .tuyen-dung-ct .box-right .box-content .box-list:hover > * {
        color: #ffffff;
        transition: .3s all ease-in-out; }
      .tuyen-dung-ct .box-right .box-content .box-list:hover .number {
        color: #ffffff;
        transition: .3s all ease-in-out; }
    .tuyen-dung-ct .box-right .box-content .box-list .title {
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600; }
    .tuyen-dung-ct .box-right .box-content .box-list .address, .tuyen-dung-ct .box-right .box-content .box-list .number {
      display: flex;
      align-items: center;
      font-size: 15px;
      padding: 5px 0; }
      .tuyen-dung-ct .box-right .box-content .box-list .address em, .tuyen-dung-ct .box-right .box-content .box-list .number em {
        font-size: 15px;
        margin-right: 10px; }
    .tuyen-dung-ct .box-right .box-content .box-list .number {
      color: #17a654; }

.tuyen-dung-ct .heading {
  padding: 20px;
  border-bottom: 1px solid #e1e1e1; }
  .tuyen-dung-ct .heading .title {
    font-size: 24px;
    color: #134d90;
    font-weight: 500; }
    @media screen and (max-width: 578px) {
      .tuyen-dung-ct .heading .title {
        font-size: 22px; } }

.news-detail-page .form-apply {
  display: none; }

.news-detail-page .tuyen-dung-ct .wrapper-box {
  margin-top: 0;
  padding-top: 30px; }

.news-detail-page .tuyen-dung-1 {
  background-image: none !important;
  position: relative;
  z-index: 111; }

.news-detail-page .tuyen-dung-ct {
  padding-top: 65px;
  margin-top: -65px; }
  @media (min-width: 1280px) {
    .news-detail-page .tuyen-dung-ct {
      padding-top: 85px; } }

.nang-luc.section {
  padding-top: 0; }

.nang-luc .title-center {
  margin-top: 2.5rem; }

.nang-luc .swiper-nang-luc {
  position: relative; }
  .nang-luc .swiper-nang-luc .img {
    text-align: center;
    margin: 0 auto;
    display: block; }
  .nang-luc .swiper-nang-luc .wrap-button .button-next {
    right: 280px; }
    @media screen and (max-width: 1599.9px) {
      .nang-luc .swiper-nang-luc .wrap-button .button-next {
        right: 180px; } }
  .nang-luc .swiper-nang-luc .wrap-button .button-prev {
    left: 280px; }
    @media screen and (max-width: 1599.9px) {
      .nang-luc .swiper-nang-luc .wrap-button .button-prev {
        left: 180px; } }

.thanh-vien {
  padding-bottom: 3.75rem; }
  .thanh-vien .heading {
    padding-top: 30px; }
  .thanh-vien .wrap-content {
    margin-bottom: 150px; }
    .thanh-vien .wrap-content:nth-child(2n) .row .col-lg-6:nth-child(2n+1) {
      order: 2; }
      .thanh-vien .wrap-content:nth-child(2n) .row .col-lg-6:nth-child(2n+1) .text-desc {
        background: #134d90;
        left: 100px; }
        @media screen and (max-width: 567px) {
          .thanh-vien .wrap-content:nth-child(2n) .row .col-lg-6:nth-child(2n+1) .text-desc {
            left: 20px; } }
        .thanh-vien .wrap-content:nth-child(2n) .row .col-lg-6:nth-child(2n+1) .text-desc::after {
          color: #17a654; }
    @media screen and (max-width: 768px) {
      .thanh-vien .wrap-content:nth-child(2n+1) .row .col-lg-6:nth-child(2n+1) {
        order: 2; } }
  .thanh-vien .box-img {
    position: relative;
    height: 100%; }
    .thanh-vien .box-img .img {
      width: 100%;
      height: 100%; }
      .thanh-vien .box-img .img img {
        border-radius: 20px;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .thanh-vien .box-img .text-desc {
      background: #17a654;
      padding: 20px;
      width: 50%;
      position: absolute;
      right: 50px;
      top: 80%; }
      @media screen and (max-width: 768px) {
        .thanh-vien .box-img .text-desc {
          width: 60%; } }
      @media screen and (max-width: 576px) {
        .thanh-vien .box-img .text-desc {
          width: 80%;
          left: 20px;
          top: 60%; }
          .thanh-vien .box-img .text-desc::after {
            display: none; } }
      .thanh-vien .box-img .text-desc::after {
        position: absolute;
        content: "\EC51";
        font-family: "remixicon";
        left: -80px;
        top: -30px;
        font-size: 70px;
        color: #ffffff; }
      .thanh-vien .box-img .text-desc li {
        display: flex;
        line-height: 1.2;
        padding: 5px 0; }
        .thanh-vien .box-img .text-desc li em {
          margin-right: 10px;
          font-size: 20px;
          color: #ffffff; }
        .thanh-vien .box-img .text-desc li p {
          font-size: 14px;
          color: #ffffff; }
  .thanh-vien .box-text {
    padding: 30px 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .thanh-vien .box-text .text {
      font-size: 16px;
      line-height: 1.8;
      padding: 20px 0; }
      .thanh-vien .box-text .text p {
        padding-top: 10px; }
    .thanh-vien .box-text .img {
      max-width: 60%;
      margin: 0 auto; }

.tong-quan {
  padding: 0 0 5rem;
  background-position: top !important; }
  .tong-quan .primary-nav {
    margin-top: -3px; }
  .tong-quan .title-center {
    margin-top: 1.875rem; }
  .tong-quan .des {
    text-align: center;
    padding: 0 12.5rem;
    margin-bottom: 3.75rem; }
    @media screen and (max-width: 1280px) {
      .tong-quan .des {
        padding: 0 5rem; } }
    @media screen and (max-width: 1023.9px) {
      .tong-quan .des {
        padding: 0 1.875rem;
        margin-bottom: 1.875rem; } }
  .tong-quan .img {
    margin: 0 auto;
    border-radius: 10px;
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 53.92157%; }
    .tong-quan .img img, .tong-quan .img iframe, .tong-quan .img video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }

.tong-quan-1 {
  background-color: #f8f8f8; }
  .tong-quan-1 .wrap-left .wrap-top {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 3.75rem;
    padding-right: 7.5rem; }
    @media screen and (max-width: 1279.9px) {
      .tong-quan-1 .wrap-left .wrap-top {
        padding-right: 2.5rem; } }
  .tong-quan-1 .wrap-left .wrap-bottom .des table tr td {
    margin-bottom: 1.875rem; }
    .tong-quan-1 .wrap-left .wrap-bottom .des table tr td:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 62px;
      height: 62px;
      border-radius: 10px;
      background-color: #ffffff; }
      @media screen and (max-width: 480px) {
        .tong-quan-1 .wrap-left .wrap-bottom .des table tr td:first-child {
          padding: 5px 10px; } }
    .tong-quan-1 .wrap-left .wrap-bottom .des table tr td:last-child {
      padding-left: 1.875rem;
      vertical-align: top; }
  .tong-quan-1 .wrap-left .wrap-bottom .des table tr:last-child {
    margin-bottom: 0; }
  .tong-quan-1 .wrap-left .wrap-bottom .item {
    display: flex;
    margin-bottom: 1.875rem; }
    .tong-quan-1 .wrap-left .wrap-bottom .item .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 62px;
      height: 62px;
      border-radius: 10px;
      background-color: #ffffff; }
      @media screen and (max-width: 480px) {
        .tong-quan-1 .wrap-left .wrap-bottom .item .icon {
          padding: 5px 10px; } }
    .tong-quan-1 .wrap-left .wrap-bottom .item .content {
      padding-left: 1.875rem; }
    .tong-quan-1 .wrap-left .wrap-bottom .item:last-child {
      margin-bottom: 0; }
  @media (max-width: 576px) {
    .tong-quan-1 {
      background-image: none !important; } }

.tong-quan-2 .box-timeline {
  position: relative;
  margin: 0 auto;
  padding: 2.5rem 0; }
  .tong-quan-2 .box-timeline::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 2px;
    top: 0;
    border-right: 2px dashed #157a72; }
    @media screen and (max-width: 767.9px) {
      .tong-quan-2 .box-timeline::after {
        left: 0;
        transform: translateX(0); } }
  .tong-quan-2 .box-timeline .item-timeline {
    width: 50%;
    padding: 0 2.5rem;
    position: relative;
    z-index: 2; }
    @media screen and (max-width: 767.9px) {
      .tong-quan-2 .box-timeline .item-timeline {
        width: 100%;
        margin-bottom: 1.25rem; } }
    .tong-quan-2 .box-timeline .item-timeline .wrap-content {
      position: relative;
      min-width: 364px;
      max-width: 394px;
      padding-bottom: 1.25rem;
      box-shadow: -8px 0 46px rgba(0, 0, 0, 0.09);
      border: 1px dashed #fff;
      background: linear-gradient(#fff, #fff) padding-box, linear-gradient(to top, #17a654 0%, #134d90 100%);
      border-radius: 10px; }
      @media screen and (max-width: 1023.9px) {
        .tong-quan-2 .box-timeline .item-timeline .wrap-content {
          max-width: 100%;
          min-width: auto; } }
    .tong-quan-2 .box-timeline .item-timeline::after {
      content: "";
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background-image: linear-gradient(to top, #17a654 0%, #134d90 100%);
      background-color: #ffffff;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
    .tong-quan-2 .box-timeline .item-timeline:nth-child(odd) {
      text-align: right;
      margin-right: auto; }
      @media screen and (max-width: 767.9px) {
        .tong-quan-2 .box-timeline .item-timeline:nth-child(odd) {
          text-align: left; } }
      .tong-quan-2 .box-timeline .item-timeline:nth-child(odd)::after {
        right: -8.3px; }
      .tong-quan-2 .box-timeline .item-timeline:nth-child(odd) .wrap-content {
        margin-left: auto;
        padding-right: 1.875rem;
        padding-left: 3.75rem; }
        .tong-quan-2 .box-timeline .item-timeline:nth-child(odd) .wrap-content::before {
          content: "";
          position: absolute;
          right: -5px;
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
          width: 10px;
          height: 10px;
          border: 1px dashed #17a654;
          border-left: 0;
          border-bottom: 0;
          background: #ffffff; }
      @media screen and (max-width: 767.9px) {
        .tong-quan-2 .box-timeline .item-timeline:nth-child(odd)::after {
          left: -7px; }
        .tong-quan-2 .box-timeline .item-timeline:nth-child(odd) .wrap-content {
          margin-right: auto;
          padding-left: 1.875rem;
          padding-right: 3.75rem; }
          .tong-quan-2 .box-timeline .item-timeline:nth-child(odd) .wrap-content::before {
            content: "";
            position: absolute;
            left: -5px;
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
            width: 10px;
            height: 10px;
            border: 1px dashed #17a654;
            border-right: 0;
            border-bottom: 0;
            background: #ffffff; } }
    .tong-quan-2 .box-timeline .item-timeline:nth-child(even) {
      text-align: left;
      margin-left: auto; }
      @media screen and (max-width: 767.9px) {
        .tong-quan-2 .box-timeline .item-timeline:nth-child(even) {
          margin-right: auto; } }
      .tong-quan-2 .box-timeline .item-timeline:nth-child(even)::after {
        left: -7px; }
      .tong-quan-2 .box-timeline .item-timeline:nth-child(even) .wrap-content {
        margin-right: auto;
        padding-left: 1.875rem;
        padding-right: 3.75rem; }
        .tong-quan-2 .box-timeline .item-timeline:nth-child(even) .wrap-content::before {
          content: "";
          position: absolute;
          left: -5px;
          top: 50%;
          transform: translateY(-50%) rotate(-45deg);
          width: 10px;
          height: 10px;
          border: 1px dashed #17a654;
          border-right: 0;
          border-bottom: 0;
          background: #ffffff; }

.tong-quan-3 .img-tong-quan-3 {
  min-height: 250px; }
  @media (min-width: 1280px) {
    .tong-quan-3 .img-tong-quan-3 {
      min-height: 500px; } }
  @media (min-width: 1600px) {
    .tong-quan-3 .img-tong-quan-3 {
      min-height: 800px; } }

.tong-quan-4 .des {
  padding: 0 18.75rem;
  margin-bottom: 2.5rem; }
  @media screen and (max-width: 1279.9px) {
    .tong-quan-4 .des {
      padding: 0 9.375rem; } }
  @media screen and (max-width: 1023.9px) {
    .tong-quan-4 .des {
      padding: 0 4.6875rem; } }
  @media screen and (max-width: 767.9px) {
    .tong-quan-4 .des {
      padding: 0 2.5rem;
      margin-bottom: 1.25rem; } }

.tong-quan-4 .img {
  text-align: center;
  box-shadow: 0 0 92px rgba(0, 0, 0, 0.13);
  max-width: 631px;
  height: 416px;
  margin: 0 auto;
  display: block; }
  @media screen and (max-width: 767.9px) {
    .tong-quan-4 .img {
      height: 350px; } }
  .tong-quan-4 .img img {
    height: 100%; }
    @media screen and (max-width: 767.9px) {
      .tong-quan-4 .img img {
        object-fit: contain; } }

.tong-quan-4 .swiper-tong-quan {
  position: relative; }
  .tong-quan-4 .swiper-tong-quan .wrap-button .button-next {
    right: 280px; }
    @media screen and (max-width: 1599.9px) {
      .tong-quan-4 .swiper-tong-quan .wrap-button .button-next {
        right: 180px; } }
  .tong-quan-4 .swiper-tong-quan .wrap-button .button-prev {
    left: 280px; }
    @media screen and (max-width: 1599.9px) {
      .tong-quan-4 .swiper-tong-quan .wrap-button .button-prev {
        left: 180px; } }

.home-page-banner {
  padding: 0;
  background-color: #fff;
  background-image: none; }
  .home-page-banner .banner-wrapper {
    width: 100%;
    height: 100%; }
    @media screen and (max-width: 768px) {
      .home-page-banner .banner-wrapper .swiper-container {
        height: 52vh; } }
  .home-page-banner .img {
    width: 100%;
    height: 100vh;
    position: relative; }
    .home-page-banner .img::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9));
      top: 0;
      left: 0;
      z-index: 2;
      pointer-events: none; }
    @media screen and (max-width: 1024px) {
      .home-page-banner .img {
        height: auto; } }
    @media screen and (max-width: 992px) {
      .home-page-banner .img {
        height: 100%; } }
    .home-page-banner .img img {
      transition: all 5s cubic-bezier(0.22, 0.44, 0, 1);
      transform: scale(1);
      height: 100%;
      width: 100%;
      overflow: hidden;
      object-fit: cover; }
  .home-page-banner .swiper-slide {
    width: 100% !important; }
  .home-page-banner .box-text {
    position: absolute;
    font-size: 2.5rem;
    color: #ffffff;
    text-align: center;
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    left: 50%;
    transform: translate3d(-50%, -40%, 0);
    bottom: 15%;
    font-weight: 400;
    text-transform: uppercase; }
    @media screen and (max-width: 1023.9px) {
      .home-page-banner .box-text {
        font-size: 1.75rem; } }
    @media screen and (max-width: 767.9px) {
      .home-page-banner .box-text {
        transform: translate3d(-50%, -20%, 0);
        bottom: 15%;
        width: 100%; } }
    .home-page-banner .box-text::before {
      content: "";
      position: absolute;
      top: -30px;
      width: 75px;
      height: 2px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #ffffff; }
  .home-page-banner .swiper-slide-active .box-text {
    z-index: 3;
    font-weight: 400 !important;
    transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s;
    bottom: 15%;
    transform: translateY(-25%) translateX(-50%) !important;
    opacity: 1; }
    @media screen and (max-width: 1199.9px) {
      .home-page-banner .swiper-slide-active .box-text {
        bottom: 14%; } }
    @media screen and (max-width: 767.9px) {
      .home-page-banner .swiper-slide-active .box-text {
        bottom: 15%; } }
  .home-page-banner .wrap-scroll {
    position: absolute;
    left: 50%;
    bottom: 9.5%;
    transform: translateX(-50%);
    width: 24px;
    height: 40px;
    border-radius: 12px;
    border: 1px solid #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3; }
    @media screen and (max-width: 1023.9px) {
      .home-page-banner .wrap-scroll {
        display: none; } }
    .home-page-banner .wrap-scroll:hover {
      cursor: pointer; }
    .home-page-banner .wrap-scroll span {
      z-index: 3;
      position: absolute;
      animation: Toptodown 2s infinite; }
      .home-page-banner .wrap-scroll span:nth-child(1) {
        top: 0; }
      .home-page-banner .wrap-scroll span:nth-child(2) {
        animation-delay: .2s;
        top: 8px; }
      .home-page-banner .wrap-scroll span:nth-child(3) {
        animation-delay: .4s;
        top: 16px; }

.home-2 {
  padding: 0;
  background-size: 105% 105% !important;
  overflow: hidden; }
  .home-2 .Module.Module-207,
  .home-2 .Module.Module-207 .ModuleContent {
    width: 100%; }
    .home-2 .Module.Module-207 .container,
    .home-2 .Module.Module-207 .ModuleContent .container {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      padding-left: 6.875rem; }
      @media (max-width: 576px) {
        .home-2 .Module.Module-207 .container,
        .home-2 .Module.Module-207 .ModuleContent .container {
          padding-left: 15px;
          padding-right: 15px; } }
  .home-2 .Module.Module-207 .ModuleContent {
    display: flex;
    height: 100%;
    align-items: center; }
    @media screen and (max-width: 1023.9px) {
      .home-2 .Module.Module-207 .ModuleContent {
        display: block;
        height: auto; } }
  .home-2 .js-ml {
    display: flex;
    flex-direction: row; }
  .home-2 .wrap-box,
  .home-2 .Module.Module-207 .ModuleContent .container {
    display: flex;
    height: 100%; }
    .home-2 .wrap-box .wrap-left,
    .home-2 .Module.Module-207 .ModuleContent .container .wrap-left {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .home-2 .wrap-box .wrap-left .section-title,
      .home-2 .Module.Module-207 .ModuleContent .container .wrap-left .section-title {
        margin-top: 1.875rem; }
  .home-2 .wrap-right {
    width: 100%;
    height: 100%;
    max-width: 975px;
    margin-left: auto;
    text-align: right; }
    .home-2 .wrap-right .img {
      height: 100vh;
      text-align: right;
      position: relative; }
      .home-2 .wrap-right .img img {
        height: 100%; }
        @media screen and (max-width: 1023.9px) {
          .home-2 .wrap-right .img img {
            height: auto; } }
  .home-2 .des {
    text-align: justify;
    color: #666666; }
    @media only screen and (min-width: 1200px) and (height: 675px) {
      .home-2 .des p {
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; } }
    @media only screen and (min-width: 1200px) and (height: 567px) {
      .home-2 .des p {
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; } }
    @media only screen and (min-width: 1200px) and (max-height: 768px) {
      .home-2 .des {
        margin-top: 15px; } }
  .home-2 .wrap-img {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media screen and (max-width: 768px) {
      .home-2 .wrap-img {
        flex-wrap: wrap; } }
    .home-2 .wrap-img .img-big, .home-2 .wrap-img .img-small {
      margin: 0 6.5px; }
      @media screen and (max-width: 768px) {
        .home-2 .wrap-img .img-big, .home-2 .wrap-img .img-small {
          margin: 0; } }
      .home-2 .wrap-img .img-big img, .home-2 .wrap-img .img-small img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .home-2 .wrap-img .img-big {
      width: 518px;
      height: 441px; }
      @media screen and (max-width: 1024px) {
        .home-2 .wrap-img .img-big {
          height: auto; } }
      @media screen and (max-width: 768px) {
        .home-2 .wrap-img .img-big {
          margin: 10px 0px; } }
      @media only screen and (min-width: 1200px) and (max-height: 768px) {
        .home-2 .wrap-img .img-big {
          height: 341px; } }
      @media only screen and (min-width: 1200px) and (height: 675px) {
        .home-2 .wrap-img .img-big {
          height: 280px; } }
      @media only screen and (min-width: 1200px) and (height: 567px) {
        .home-2 .wrap-img .img-big {
          height: 280px; } }
      @media only screen and (height: 567px) {
        .home-2 .wrap-img .img-big {
          height: 225px; } }
    .home-2 .wrap-img .img-small {
      width: 433px;
      height: 361px;
      border-radius: 0px 40px 0px 40px;
      overflow: hidden; }
      @media screen and (max-width: 768px) {
        .home-2 .wrap-img .img-small {
          flex: 0 0 100%; } }
      @media screen and (max-width: 1024px) {
        .home-2 .wrap-img .img-small {
          height: 250px; } }
      @media only screen and (min-width: 1200px) and (max-height: 768px) {
        .home-2 .wrap-img .img-small {
          height: 261px; } }
      @media only screen and (min-width: 1200px) and (height: 675px) {
        .home-2 .wrap-img .img-small {
          height: 220px; } }
      @media only screen and (min-width: 1200px) and (height: 567px) {
        .home-2 .wrap-img .img-small {
          height: 220px; } }
      @media only screen and (height: 567px) {
        .home-2 .wrap-img .img-small {
          height: 195px; } }
  @media screen and (min-width: 1200px) {
    .home-2 .text-2 p {
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3; } }
  @media screen and (min-width: 1200px) and (max-height: 768px) {
    .home-2 .text-2 p {
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      margin-top: 0;
      padding-top: 12px; } }
  @media screen and (min-width: 1200px) and (max-height: 675px) {
    .home-2 .text-2 p {
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      margin-top: 0;
      padding-top: 12px; } }
  @media screen and (min-width: 1600px) {
    .home-2 .text-2 p {
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5; } }

.home-3 {
  background-color: #fff;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center; }
  .home-3 .box-wrap {
    height: 100%;
    display: flex;
    align-items: center; }
    @media screen and (min-width: 1201.9px) {
      .home-3 .box-wrap {
        padding-top: 5rem; } }
    @media screen and (max-width: 1279.9px) {
      .home-3 .box-wrap {
        padding-top: 3.75rem; } }
    .home-3 .box-wrap [class*='col']:last-child {
      align-items: center;
      display: flex; }
      @media screen and (max-width: 1279.9px) {
        .home-3 .box-wrap [class*='col']:last-child {
          display: block; } }
    @media screen and (min-width: 1520px) {
      .home-3 .box-wrap .wrap-left {
        padding-right: 3.125rem; } }
    @media screen and (max-width: 1279.9px) {
      .home-3 .box-wrap .wrap-left {
        margin-bottom: 1.875rem; } }
    .home-3 .box-wrap .wrap-left .section-title {
      font-size: 1.125rem;
      line-height: 1; }
      .home-3 .box-wrap .wrap-left .section-title::before {
        top: auto; }
    .home-3 .box-wrap .wrap-left .desc {
      font-size: 2.25rem;
      font-weight: 700;
      color: #134d90;
      margin-bottom: 1.875rem;
      line-height: 2.625rem; }
    .home-3 .box-wrap .wrap-left .content {
      text-align: justify;
      padding-right: 6.25rem; }
      @media screen and (max-width: 767.9px) {
        .home-3 .box-wrap .wrap-left .content {
          padding-right: 0; } }
    .home-3 .box-wrap .wrap-right {
      display: flex; }
      @media screen and (max-width: 1279.9px) {
        .home-3 .box-wrap .wrap-right {
          justify-content: center; } }
      @media screen and (max-width: 767.9px) {
        .home-3 .box-wrap .wrap-right {
          flex-wrap: wrap; } }
      .home-3 .box-wrap .wrap-right .item {
        flex: 0 0 260px;
        border-radius: 18px;
        margin-right: 15px;
        position: relative;
        overflow: hidden;
        z-index: 3;
        transform: translateY(0px);
        transition: .3s all; }
        @media screen and (max-width: 1279.9px) {
          .home-3 .box-wrap .wrap-right .item {
            flex: 0 0 calc((100% / 3)-15px); } }
        @media screen and (max-width: 767.9px) {
          .home-3 .box-wrap .wrap-right .item {
            flex: 0 0 calc((100% / 2)-15px);
            margin-bottom: 0.9375rem; } }
        .home-3 .box-wrap .wrap-right .item > .img {
          position: relative;
          display: block;
          height: 0;
          overflow: hidden;
          padding-top: 153.84615%; }
          .home-3 .box-wrap .wrap-right .item > .img img, .home-3 .box-wrap .wrap-right .item > .img iframe, .home-3 .box-wrap .wrap-right .item > .img video {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
            transition: .3s ease-in-out all; }
        .home-3 .box-wrap .wrap-right .item:last-child {
          margin-right: 0; }
        .home-3 .box-wrap .wrap-right .item .wrap-inner {
          position: absolute;
          bottom: 30px;
          width: 100%;
          text-align: center;
          pointer-events: none;
          z-index: 3;
          overflow: hidden; }
          .home-3 .box-wrap .wrap-right .item .wrap-inner .content {
            font-weight: 700;
            color: #ffffff; }
          .home-3 .box-wrap .wrap-right .item .wrap-inner .icon {
            pointer-events: none; }
        .home-3 .box-wrap .wrap-right .item::after {
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 52%, rgba(0, 0, 0, 0) 100%); }
        .home-3 .box-wrap .wrap-right .item::before {
          content: "";
          background: rgba(23, 166, 84, 0.3);
          position: absolute;
          top: 100%;
          left: 0;
          opacity: 0;
          visibility: hidden;
          transition: .5s all;
          width: 100%;
          height: 100%;
          z-index: 2;
          pointer-events: none;
          border-radius: 18px; }
        .home-3 .box-wrap .wrap-right .item:hover {
          transform: translateY(-60px); }
          .home-3 .box-wrap .wrap-right .item:hover::after {
            display: none; }
          .home-3 .box-wrap .wrap-right .item:hover::before {
            opacity: 1;
            visibility: visible;
            top: 0; }
          .home-3 .box-wrap .wrap-right .item:hover .content {
            text-decoration: underline; }
  @media screen and (min-width: 1200px) {
    .home-3.down-scroll::before {
      top: -100%;
      opacity: 0; } }

.home-4 {
  flex-direction: column; }
  @media screen and (min-width: 1200px) {
    .home-4 {
      justify-content: center; } }
  .home-4 .wrap-title {
    display: flex;
    justify-content: space-between; }
    .home-4 .wrap-title .seemore::before {
      top: 20%; }
  .home-4 .seemore {
    color: #ffffff;
    margin-right: 5rem; }
    .home-4 .seemore::before {
      background: #ffffff;
      transform: translateY(0);
      top: 50%;
      transition: .3s all ease-in-out; }
    .home-4 .seemore:hover {
      color: #134d90; }
      .home-4 .seemore:hover::before {
        background: #134d90; }
  .home-4 .s4-left {
    position: relative; }
  .home-4 .index-3-slide .box-img {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); }
    .home-4 .index-3-slide .box-img .img img {
      height: 550px;
      width: 100%;
      object-fit: cover; }
      @media screen and (max-width: 1519.9px) {
        .home-4 .index-3-slide .box-img .img img {
          height: 450px; } }
  .home-4 .index-3-slide .swiper-slide {
    transition: .2s all ease-in-out;
    position: relative; }
    .home-4 .index-3-slide .swiper-slide img {
      border-radius: 25px; }
    .home-4 .index-3-slide .swiper-slide::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 25px;
      background: rgba(37, 37, 37, 0.5);
      pointer-events: none; }
    .home-4 .index-3-slide .swiper-slide .wrap-inner {
      position: absolute;
      padding: 0;
      bottom: 60px;
      width: 100%;
      display: flex;
      left: 40%;
      justify-content: space-between;
      z-index: 3; }
      @media screen and (max-width: 768px) {
        .home-4 .index-3-slide .swiper-slide .wrap-inner {
          flex-direction: column;
          height: 100%;
          bottom: 0;
          justify-content: center; } }
      .home-4 .index-3-slide .swiper-slide .wrap-inner * {
        color: #ffffff; }
      .home-4 .index-3-slide .swiper-slide .wrap-inner .wrap-left {
        text-transform: uppercase;
        position: relative; }
        .home-4 .index-3-slide .swiper-slide .wrap-inner .wrap-left .title,
        .home-4 .index-3-slide .swiper-slide .wrap-inner .wrap-left .sub-title {
          position: absolute;
          transform: rotate(-90deg);
          transform-origin: 0 0;
          transition: .2s all ease-in-out; }
        .home-4 .index-3-slide .swiper-slide .wrap-inner .wrap-left .title {
          left: 25px;
          white-space: nowrap;
          font-size: 1.5rem; }
          @media (max-width: 576px) {
            .home-4 .index-3-slide .swiper-slide .wrap-inner .wrap-left .title {
              white-space: pre-wrap; } }
        .home-4 .index-3-slide .swiper-slide .wrap-inner .wrap-left .sub-title {
          white-space: nowrap;
          margin-bottom: 10px; }
      .home-4 .index-3-slide .swiper-slide .wrap-inner .wrap-right {
        display: none; }
  .home-4 .index-3-slide .swiper-slide-active {
    width: calc(100%-380px) !important; }
    @media screen and (max-width: 1279.9px) {
      .home-4 .index-3-slide .swiper-slide-active {
        width: calc(100%-190px) !important; } }
    .home-4 .index-3-slide .swiper-slide-active::before {
      background: none;
      background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9)); }
    .home-4 .index-3-slide .swiper-slide-active .wrap-inner {
      padding: 0 5rem;
      left: 0; }
      @media screen and (max-width: 768px) {
        .home-4 .index-3-slide .swiper-slide-active .wrap-inner {
          padding: 0 3.75rem; } }
      @media screen and (max-width: 480px) {
        .home-4 .index-3-slide .swiper-slide-active .wrap-inner {
          padding: 0 1.875rem; } }
      .home-4 .index-3-slide .swiper-slide-active .wrap-inner .wrap-left {
        width: 100%;
        text-transform: uppercase;
        position: relative; }
        @media screen and (max-width: 767.9px) {
          .home-4 .index-3-slide .swiper-slide-active .wrap-inner .wrap-left {
            margin-bottom: 3.75rem; } }
        .home-4 .index-3-slide .swiper-slide-active .wrap-inner .wrap-left .title,
        .home-4 .index-3-slide .swiper-slide-active .wrap-inner .wrap-left .sub-title {
          position: static;
          transform: rotate(0);
          transform-origin: 0 0; }
        .home-4 .index-3-slide .swiper-slide-active .wrap-inner .wrap-left .sub-title {
          right: 0; }
        .home-4 .index-3-slide .swiper-slide-active .wrap-inner .wrap-left .title {
          position: relative;
          left: 0; }
          .home-4 .index-3-slide .swiper-slide-active .wrap-inner .wrap-left .title::before {
            content: "";
            position: absolute;
            width: 100px;
            height: 2px;
            background-color: #ffffff;
            bottom: -20px;
            left: 0;
            pointer-events: none; }
      .home-4 .index-3-slide .swiper-slide-active .wrap-inner .wrap-right {
        display: block; }
        @media screen and (max-width: 576px) {
          .home-4 .index-3-slide .swiper-slide-active .wrap-inner .wrap-right .des {
            overflow: hidden;
            display: -webkit-box;
            text-overflow: ellipsis;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            height: 76px; }
          .home-4 .index-3-slide .swiper-slide-active .wrap-inner .wrap-right .seemore {
            white-space: nowrap; } }
  .home-4 .wrap-button .button-next,
  .home-4 .wrap-button .button-prev {
    z-index: 3;
    position: absolute;
    z-index: 2;
    padding: 1.25rem;
    filter: brightness(0) invert(1) grayscale(0);
    opacity: 0.8; }
    .home-4 .wrap-button .button-next:hover,
    .home-4 .wrap-button .button-prev:hover {
      opacity: 0.8; }
    @media screen and (max-width: 1519.9px) {
      .home-4 .wrap-button .button-next,
      .home-4 .wrap-button .button-prev {
        position: static; } }
  .home-4 .wrap-button .button-next {
    right: 200px; }
  .home-4 .wrap-button .button-prev {
    left: -75px; }

.home-5 {
  justify-content: center;
  align-items: center; }
  .home-5 .mx-auto {
    margin-left: auto;
    margin-right: auto; }
  .home-5 .Module.Module-210 {
    max-width: 100%; }
  .home-5 .swiper-news {
    position: relative; }
    @media screen and (max-width: 1519.9px) {
      .home-5 .swiper-news .wrap-button {
        display: none; } }
    .home-5 .swiper-news .item {
      display: flex;
      flex-direction: column;
      border-radius: 20px;
      background-color: #ffffff;
      cursor: pointer;
      overflow: hidden; }
      .home-5 .swiper-news .item .img {
        display: flex;
        max-width: 100%;
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 65.38462%; }
        .home-5 .swiper-news .item .img img, .home-5 .swiper-news .item .img iframe, .home-5 .swiper-news .item .img video {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: .3s ease-in-out all; }
      .home-5 .swiper-news .item .box-content {
        position: relative;
        padding: 0.9375rem 1.5625rem 1.5625rem 3.125rem; }
        .home-5 .swiper-news .item .box-content .title {
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          font-size: 1.5rem;
          line-height: 1.2;
          margin-bottom: 1.25rem;
          transition: .3s all;
          height: 84px; }
          @media screen and (max-width: 767.9px) {
            .home-5 .swiper-news .item .box-content .title {
              height: 40px;
              font-size: 16px; } }
        .home-5 .swiper-news .item .box-content .desc {
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          font-size: 0.875rem;
          line-height: 1.125rem; }
        .home-5 .swiper-news .item .box-content .inner-date {
          top: 95px;
          bottom: 0;
          left: 0; }
      .home-5 .swiper-news .item:hover .title {
        color: #17a654; }
      .home-5 .swiper-news .item:hover .inner-date {
        background-color: #17a654; }

.home-6 .bg-fake {
  padding-top: 0.625rem;
  width: 100%;
  height: 100%;
  background-position: -70% -100% !important;
  background-repeat: no-repeat;
  background-size: auto !important;
  justify-content: center; }
  .home-6 .bg-fake .swiper-partner {
    position: relative; }
    .home-6 .bg-fake .swiper-partner .item {
      background-color: #ffffff;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.5625rem;
      height: 100px; }
      @media screen and (max-width: 767.9px) {
        .home-6 .bg-fake .swiper-partner .item {
          height: 120px; } }
      .home-6 .bg-fake .swiper-partner .item img {
        object-fit: contain; }

.footer {
  margin-top: 0.625rem; }
  .footer .wrap-address {
    display: flex;
    width: 100%; }
    @media screen and (max-width: 1023.9px) {
      .footer .wrap-address {
        flex-wrap: wrap; } }
    @media screen and (max-width: 767.9px) {
      .footer .wrap-address {
        display: block; } }
    .footer .wrap-address .item {
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: linear-gradient(to top, #17a654 0%, #134d90 100%);
      opacity: 0.85;
      height: 160px;
      flex-direction: column;
      flex: 0 0 33.33333%;
      border-right: 1px solid #17a654;
      justify-content: flex-start;
      padding-top: 1.875rem; }
      .footer .wrap-address .item:nth-child(1) {
        border-radius: 10px 0 0 10px; }
        @media screen and (max-width: 1023.9px) {
          .footer .wrap-address .item:nth-child(1) {
            flex: 0 0 50%;
            border-radius: 10px 0 0 0; } }
        @media screen and (max-width: 767.9px) {
          .footer .wrap-address .item:nth-child(1) {
            border-right: 0;
            border-radius: 10px 10px 0 0; } }
      .footer .wrap-address .item:nth-child(2) {
        border-radius: 0; }
        @media screen and (max-width: 1023.9px) {
          .footer .wrap-address .item:nth-child(2) {
            flex: 0 0 50%;
            border-right: 0;
            border-radius: 0 10px 0 0; } }
        @media screen and (max-width: 767.9px) {
          .footer .wrap-address .item:nth-child(2) {
            border-radius: 0; } }
        @media screen and (max-width: 575.9px) {
          .footer .wrap-address .item:nth-child(2) .item-content {
            width: 100%; } }
      .footer .wrap-address .item:nth-child(3) {
        border-radius: 0 10px 10px 0;
        border-right: 0;
        align-items: flex-start; }
        @media screen and (max-width: 1023.9px) {
          .footer .wrap-address .item:nth-child(3) {
            flex: 0 0 100%;
            border-radius: 0 0 10px 10px; } }
        .footer .wrap-address .item:nth-child(3) .item-content {
          width: 100%; }
          .footer .wrap-address .item:nth-child(3) .item-content .subscribe .subscribefrm {
            padding-left: 6rem; }
            @media screen and (max-width: 1279.9px) {
              .footer .wrap-address .item:nth-child(3) .item-content .subscribe .subscribefrm {
                padding-left: 1.875rem; } }
            .footer .wrap-address .item:nth-child(3) .item-content .subscribe .subscribefrm input {
              max-width: 90%;
              border-radius: 23px;
              width: 100%;
              height: 46px;
              border: none;
              padding-left: 20px;
              font-size: 14px;
              color: #666666; }
            .footer .wrap-address .item:nth-child(3) .item-content .subscribe .subscribefrm button {
              margin-left: -80px;
              border-radius: 20px;
              background-color: #17a654;
              width: 75px;
              height: 40px;
              border: none;
              font-size: 0.8125rem;
              color: #ffffff;
              text-transform: uppercase;
              font-weight: 700;
              border: thin solid transparent;
              transition: .3s all ease-in-out; }
              .footer .wrap-address .item:nth-child(3) .item-content .subscribe .subscribefrm button:hover {
                border: thin solid #17a654;
                color: #17a654;
                background-color: #ffffff; }
      .footer .wrap-address .item .title {
        font-size: 1.125rem;
        margin-bottom: 0.9375rem;
        padding: 0 3rem;
        display: flex;
        color: #ffffff; }
        @media screen and (max-width: 1365.9px) {
          .footer .wrap-address .item .title {
            padding: 0 0.625rem 0 3rem; } }
        @media screen and (max-width: 479.9px) {
          .footer .wrap-address .item .title {
            padding: 0 0.625rem 0 1.25rem; } }
      .footer .wrap-address .item em {
        padding-right: 20px;
        font-size: 24px;
        color: #ffffff;
        font-weight: 700; }
      .footer .wrap-address .item .content {
        padding: 0 6rem;
        color: #ebebeb; }
        @media screen and (max-width: 1365.9px) {
          .footer .wrap-address .item .content {
            padding: 0 1.875rem 0 6rem; } }
        @media screen and (max-width: 479.9px) {
          .footer .wrap-address .item .content {
            padding: 0 1.875rem 0 2.5rem; } }
  .footer .footer-menu {
    margin: 1.25rem 0; }
    .footer .footer-menu .Module.Module-150 {
      margin-top: 0.625rem; }
      .footer .footer-menu .Module.Module-150 li a {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1.2px;
        text-transform: none; }
    .footer .footer-menu ul {
      list-style: none;
      padding: 0;
      display: flex;
      overflow: auto;
      padding-bottom: 0.3125rem; }
      .footer .footer-menu ul::-webkit-scrollbar {
        height: 1px; }
      .footer .footer-menu ul::-webkit-scrollbar-thumb {
        background: #17a654 !important;
        width: 5px; }
        .footer .footer-menu ul::-webkit-scrollbar-thumb:hover {
          background: #17a654 !important; }
      .footer .footer-menu ul li {
        margin-right: 20px;
        position: relative; }
        .footer .footer-menu ul li:last-child {
          margin-right: 0; }
          .footer .footer-menu ul li:last-child::before {
            display: none; }
        .footer .footer-menu ul li::before {
          content: "";
          position: absolute;
          width: 1px;
          background-color: #ffffff;
          height: 60%;
          top: 50%;
          right: -10px;
          transform: translateY(-50%); }
        .footer .footer-menu ul li.active a {
          text-decoration: underline; }
        .footer .footer-menu ul li a {
          font-weight: 700;
          color: #ffffff;
          text-transform: uppercase;
          letter-spacing: 0.7px;
          display: inline-block;
          position: relative;
          white-space: nowrap; }
          .footer .footer-menu ul li a::before {
            content: "";
            position: absolute;
            left: 0;
            width: 0;
            height: 1px;
            background-color: #ffffff;
            bottom: 0;
            transition: .3s all ease-in-out; }
          .footer .footer-menu ul li a:hover {
            color: #ffffff; }
            .footer .footer-menu ul li a:hover::before {
              width: 100%; }
    .footer .footer-menu .copyright {
      margin-top: 5px;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      letter-spacing: 1.2px; }
    .footer .footer-menu .social {
      display: flex;
      margin-top: 0.625rem; }
      .footer .footer-menu .social a {
        display: block;
        margin-right: 1.25rem;
        color: #ffffff;
        font-size: 18px;
        transform: translateY(0);
        transition: all .3s ease-in-out; }
        .footer .footer-menu .social a:last-child {
          margin-right: 0; }
        .footer .footer-menu .social a:hover {
          transform: translateY(-3px); }

.home-6 .Module.Module-211, .Module.Module-211 .ModuleContent {
  width: 100%; }

.wrap-post-detail {
  background-image: url(../img/tin-tuc/bntt.png); }
  .wrap-post-detail .post-content {
    padding: 30px 150px; }
    @media (max-width: 1024px) {
      .wrap-post-detail .post-content {
        padding: 30px 0; } }
    .wrap-post-detail .post-content .heading-wrap .title {
      font-size: 30px;
      font-weight: 600;
      color: #134d90;
      border-bottom: 1px solid #134d90;
      padding-bottom: 10px; }
      @media (max-width: 768px) {
        .wrap-post-detail .post-content .heading-wrap .title {
          font-size: 25px; } }
    .wrap-post-detail .post-content .heading-wrap ul {
      display: flex;
      padding: 10px 0;
      border-bottom: 1px solid #e1e1e1; }
      .wrap-post-detail .post-content .heading-wrap ul li {
        padding: 0 20px;
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        position: relative; }
        @media (max-width: 576px) {
          .wrap-post-detail .post-content .heading-wrap ul li {
            font-size: 14px;
            padding: 0 10px; } }
        .wrap-post-detail .post-content .heading-wrap ul li:first-child {
          padding-left: 0; }
          .wrap-post-detail .post-content .heading-wrap ul li:first-child::before {
            display: none; }
        .wrap-post-detail .post-content .heading-wrap ul li::before {
          position: absolute;
          content: "";
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 18px;
          background: #134d90; }
    .wrap-post-detail .post-content .heading-wrap .time span {
      font-size: 12px;
      color: #999999; }
    .wrap-post-detail .post-content .heading-wrap .share {
      display: flex;
      justify-content: center;
      align-items: center; }
      .wrap-post-detail .post-content .heading-wrap .share iframe {
        display: flex;
        padding-top: 5px; }
        .wrap-post-detail .post-content .heading-wrap .share iframe * {
          font-size: 18px !important; }
      .wrap-post-detail .post-content .heading-wrap .share p {
        padding-right: 10px; }
    .wrap-post-detail .post-content .wrap-content {
      padding: 20px 0; }
      .wrap-post-detail .post-content .wrap-content * {
        margin-bottom: 1.875rem; }
      .wrap-post-detail .post-content .wrap-content strong {
        font-size: 16px;
        color: #333333;
        font-weight: 600;
        line-height: 1.8; }
      .wrap-post-detail .post-content .wrap-content p {
        font-size: 16px;
        line-height: 1.8; }
      .wrap-post-detail .post-content .wrap-content img {
        margin: 0 auto; }
      .wrap-post-detail .post-content .wrap-content h3 {
        font-size: 20px;
        color: #17a654;
        padding: 20px 0; }
  .wrap-post-detail .wrap-post-related {
    background-image: url(../img/tin-tuc/bndt.png);
    padding: 30px 0; }
    .wrap-post-detail .wrap-post-related .white {
      color: #ffffff; }
    .wrap-post-detail .wrap-post-related .slider {
      position: relative; }
      .wrap-post-detail .wrap-post-related .slider .button-slider .button-prev, .wrap-post-detail .wrap-post-related .slider .button-slider .button-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
      .wrap-post-detail .wrap-post-related .slider .button-slider .button-prev {
        left: -65px; }
      .wrap-post-detail .wrap-post-related .slider .button-slider .button-next {
        right: -65px; }
      @media screen and (max-width: 1519.9px) {
        .wrap-post-detail .wrap-post-related .slider .button-slider .button-next, .wrap-post-detail .wrap-post-related .slider .button-slider .button-prev {
          position: static;
          top: auto;
          right: auto;
          left: auto;
          bottom: auto;
          transform: none;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 10px; } }

.wrap-post-list {
  background-image: url(../img/tin-tuc/bntt.png); }
  .wrap-post-list .section-title2 {
    padding-top: 1.875rem; }
  .wrap-post-list .box-item .wrap-text .title {
    height: 72px; }
  .wrap-post-list .box-item .wrap-text .desc {
    padding: 0;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; }
  .wrap-post-list .box-item .wrap-text .view-detail {
    display: inline-block;
    margin-top: 30px; }
  .wrap-post-list .post-wrap > .row {
    margin-bottom: -30px; }
    .wrap-post-list .post-wrap > .row > * {
      margin-bottom: 30px; }

.dao-tao-pages .heading .title-center {
  display: none; }

.dao-tao-pages .wrap-text .title {
  height: 76px; }

.dao-tao-pages .wrap-text .view-detail {
  margin-top: 1.875rem;
  display: inline-block; }

.dao-tao-pages [class*='col'] {
  margin-bottom: 1.875rem; }

.wrap-post-list .section-title {
  padding-top: 1.875rem; }

.news-detail-page .thu-vien-anh > .container {
  max-width: 100%;
  padding-right: 0;
  padding-left: 0; }

.news-detail-page .wrap-post-related .wrap-text .title {
  height: 73px; }

.news-detail-page .wrap-post-related .wrap-text .view-detail {
  margin-top: 1.875rem;
  display: inline-block; }

.news-detail-page .wrap-post-list > .container {
  width: 100%;
  max-width: 100%;
  padding: 0; }

.full-page .footer {
  padding-bottom: 0px; }

footer .footer {
  padding: 3.75rem 0;
  margin-top: 0; }
  footer .footer .footer-menu {
    margin-bottom: 0; }

#to-top,
#mail,
#phone {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 30px;
  border-radius: 100%;
  text-align: center;
  cursor: pointer;
  transition: .3s all ease-in-out;
  text-transform: uppercase;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  bottom: 15%;
  display: flex;
  align-items: center; }
  #to-top a,
  #mail a,
  #phone a {
    height: 100%; }
  #to-top.active,
  #mail.active,
  #phone.active {
    opacity: 1;
    visibility: visible; }

#to-top {
  display: block;
  background-image: linear-gradient(to top, #17a654 0%, #134d90 100%);
  bottom: 180px; }
  @media screen and (max-width: 767.9px) {
    #to-top {
      bottom: 20px; } }
  #to-top:hover {
    transform: translateY(-5px); }
  #to-top * {
    display: block;
    color: #ffffff; }
  #to-top em {
    font-size: 45px;
    height: 32px;
    pointer-events: none;
    margin-top: -5px; }

#mail,
#phone {
  background: #ffffff; }
  #mail::before,
  #phone::before {
    content: "";
    position: absolute;
    font-family: remixicon;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #17a654;
    font-size: 36px;
    pointer-events: none; }
  #mail *,
  #phone * {
    display: block; }

#mail {
  bottom: 110px; }
  @media screen and (max-width: 767.9px) {
    #mail {
      bottom: 20px;
      right: 170px; } }

#phone {
  bottom: 40px; }
  @media screen and (max-width: 767.9px) {
    #phone {
      right: 100px;
      bottom: 20px; } }
  #phone * {
    display: block; }
  #phone::before {
    content: "";
    position: absolute;
    font-family: remixicon;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #17a654;
    font-size: 36px;
    pointer-events: none; }

#mail .inner,
#phone .inner {
  position: absolute;
  display: inline-flex;
  align-items: center;
  width: max-content;
  transition: .3s all ease-in-out;
  overflow: hidden;
  color: #17a654;
  border-radius: 25px;
  font-weight: 700;
  padding: 0 3.125rem 0 1.25rem;
  right: 20%;
  border-radius: 40px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  background-color: #ffffff; }

#mail:hover .inner,
#phone:hover .inner {
  opacity: 1;
  visibility: visible;
  transition: .3s all ease-in-out; }

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99; }
  header.active {
    transform: translateY(-100%);
    transition: all 1s cubic-bezier(0.22, 0.44, 0, 1); }

.header-top {
  background-image: linear-gradient(89deg, #f2f3f3 0%, #fbfcfc 100%);
  height: 35px;
  display: flex;
  align-items: center;
  width: 100%; }

.header-top-primary {
  display: flex;
  align-items: center; }
  @media screen and (max-width: 479.9px) {
    .header-top-primary {
      justify-content: space-between; }
      .header-top-primary .Module.Module-138 {
        display: none; } }
  .header-top-primary .wrap-left {
    display: flex;
    align-items: center; }
    .header-top-primary .wrap-left em {
      font-size: 24px;
      font-weight: 500;
      padding-right: 10px; }
    @media screen and (max-width: 479.9px) {
      .header-top-primary .wrap-left {
        display: none; } }
    .header-top-primary .wrap-left * {
      color: transparent;
      -webkit-background-clip: text !important;
      background-image: linear-gradient(to right, #134d90 0%, #17a654 100%);
      background-clip: text;
      font-weight: 700; }
  .header-top-primary .wrap-search {
    margin-left: auto; }
    @media screen and (max-width: 479.9px) {
      .header-top-primary .wrap-search {
        margin-left: 0;
        order: 1; } }
    .header-top-primary .wrap-search .searchbox {
      position: relative; }
      .header-top-primary .wrap-search .searchbox::before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: linear-gradient(to top, #17a654 0%, rgba(23, 166, 84, 0.99) 1%, rgba(16, 125, 194, 0.35) 50%, rgba(16, 125, 194, 0.22) 60%); }
      .header-top-primary .wrap-search .searchbox input {
        border: unset;
        font-size: 12px;
        padding: 5px;
        background: transparent; }
      .header-top-primary .wrap-search .searchbox button {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        right: 0;
        border: unset;
        background-color: transparent;
        font-size: 0; }
        .header-top-primary .wrap-search .searchbox button::before {
          content: "";
          position: absolute;
          font-family: remixicon;
          top: 50%;
          transform: translateY(-50%);
          color: transparent;
          -webkit-background-clip: text !important;
          background-image: linear-gradient(to right, #134d90 0%, #17a654 100%);
          background-clip: text;
          right: 0;
          font-size: 1.125rem; }
  .header-top-primary .wrap-language {
    position: relative;
    cursor: pointer;
    margin-left: 1.5625rem;
    padding-right: 15px;
    color: #999999;
    font-size: 14px;
    text-transform: uppercase; }
    .header-top-primary .wrap-language::before {
      content: "";
      position: absolute;
      font-family: remixicon;
      top: 50%;
      transform: translateY(-50%);
      color: transparent;
      -webkit-background-clip: text !important;
      background-image: linear-gradient(to right, #134d90 0%, #17a654 100%);
      background-clip: text;
      right: 0; }
    .header-top-primary .wrap-language .dropdown {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      transform: translateY(30%);
      transition: .3s all;
      padding: 0.3125rem;
      background-color: #17a654;
      color: #ffffff;
      z-index: 3; }
    .header-top-primary .wrap-language:hover {
      color: #17a654; }
      .header-top-primary .wrap-language:hover .dropdown {
        opacity: 1;
        visibility: visible;
        transform: translateY(0); }
        .header-top-primary .wrap-language:hover .dropdown a:hover {
          color: #ffffff; }

.header-bottom {
  height: 65px;
  background: #ffffff; }
  .header-bottom .container {
    height: 100%; }
  @media screen and (max-width: 767.9px) {
    .header-bottom {
      height: 65px;
      position: relative; } }
  @media screen and (max-width: 1023.9px) {
    .header-bottom .primary-menu .logo {
      margin: 0 auto; }
    .header-bottom .primary-menu ul {
      display: none; } }
  @media screen and (max-width: 767.9px) {
    .header-bottom .primary-menu .logo {
      margin: auto auto auto 0; }
      .header-bottom .primary-menu .logo .img {
        align-items: center; }
      .header-bottom .primary-menu .logo img {
        width: 75px;
        height: 40px; } }
  .header-bottom .Module.Module-141,
  .header-bottom .Module.Module-141 .ModuleContent,
  .header-bottom .Module.Module-142,
  .header-bottom .Module.Module-142 .ModuleContent {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between; }

.primary-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%; }
  @media screen and (min-width: 1023.9px) {
    .primary-menu .dropdown-menu .title em {
      display: none; } }
  .primary-menu .logo {
    margin: 0 8.125rem;
    padding: 10px 0; }
    @media screen and (max-width: 1279.9px) {
      .primary-menu .logo {
        margin: 0 3.75rem; } }
    .primary-menu .logo .img {
      display: flex;
      width: 100px; }
      .primary-menu .logo .img img {
        margin: 0 auto; }
  .primary-menu > ul li::hover .title::before {
    width: 100%; }
  .primary-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between; }
    .primary-menu ul li {
      font-weight: 700;
      text-transform: uppercase;
      position: relative;
      display: inline-flex;
      align-items: center;
      cursor: pointer; }
      .primary-menu ul li::hover .title::before {
        width: 100%; }
      .primary-menu ul li > .title {
        position: relative; }
      .primary-menu ul li.active .title {
        position: relative; }
        .primary-menu ul li.active .title::before {
          content: "";
          position: absolute;
          bottom: 1px;
          height: 2px;
          border-radius: 1px;
          left: 0;
          width: 100%;
          background: linear-gradient(to top, #17a654 0%, rgba(23, 166, 84, 0.99) 1%, rgba(16, 125, 194, 0.35) 50%, rgba(16, 125, 194, 0.22) 60%); }
      .primary-menu ul li.active a, .primary-menu ul li.active em {
        color: transparent;
        -webkit-background-clip: text !important;
        background-image: linear-gradient(to bottom, #17a654 0%, #134d90 100%);
        background-clip: text;
        text-decoration: underline; }
      .primary-menu ul li > .title::before {
        content: "";
        position: absolute;
        bottom: 1px;
        height: 2px;
        left: 0;
        border-radius: 1px;
        width: 0;
        background: linear-gradient(to top, #17a654 0%, rgba(23, 166, 84, 0.99) 1%, rgba(16, 125, 194, 0.35) 50%, rgba(16, 125, 194, 0.22) 60%);
        transition: .3s all; }
      .primary-menu ul li:hover .title::before {
        width: 100%; }
      .primary-menu ul li:hover .title a {
        color: transparent;
        -webkit-background-clip: text !important;
        background-image: linear-gradient(to bottom, #17a654 0%, #134d90 100%);
        background-clip: text;
        transition: .3s all ease-in-out; }
      .primary-menu ul li:hover .dropdown {
        opacity: 1;
        visibility: visible; }
      @media screen and (min-width: 1024px) {
        .primary-menu ul li .dropdown {
          position: absolute;
          top: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          opacity: 0;
          visibility: hidden;
          background-color: #ffffff;
          left: 50%;
          transform: translateX(-50%);
          transition: .3s all;
          width: max-content; }
          .primary-menu ul li .dropdown .dropdown-item {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 190px;
            width: 100%;
            padding: 1.25rem;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            color: #333333;
            border: 1px solid #ebebeb; }
            .primary-menu ul li .dropdown .dropdown-item:hover * {
              color: transparent;
              -webkit-background-clip: text !important;
              background-image: linear-gradient(to bottom, #17a654 0%, #134d90 100%);
              background-clip: text; } }

.toggle-menu {
  width: 30px;
  padding: 0;
  cursor: pointer;
  position: absolute;
  transition: .3s all ease-in-out;
  margin-left: auto;
  right: 30px;
  top: 25%;
  margin-top: 5px; }
  @media screen and (min-width: 1024px) {
    .toggle-menu {
      display: none; } }
  .toggle-menu span {
    position: absolute;
    width: 100%;
    display: block;
    height: 3px;
    background-color: #17a654;
    transform: rotate(0deg);
    transition: .4s all ease-in-out;
    left: 0; }
    @media screen and (max-width: 767.9px) {
      .toggle-menu span {
        background-color: #17a654; } }
    .toggle-menu span:first-child {
      top: 0; }
    .toggle-menu span:nth-child(2), .toggle-menu span:nth-child(3) {
      top: 10px; }
    .toggle-menu span:last-child {
      top: 20px; }
  .toggle-menu.active span:first-child, .toggle-menu.active span:last-child {
    left: 50%;
    width: 0; }
  .toggle-menu.active span:nth-child(2) {
    transform: rotate(45deg); }
  .toggle-menu.active span:nth-child(3) {
    transform: rotate(-45deg); }
