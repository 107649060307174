// .fancybox-content
// 	max-width: 920px !important
// 	margin: 0 auto
// 	padding: 0 60px !important
// 	border-radius: 20px 0
// 	background-color: $white
// overflow: hidden !important
.apply-frm
	padding: 30px 10px!important
	.title
		font-weight: 700
		font-size: 2rem
		@media screen and ( min-width: 1280px )
			font-size: 24px
	h4
		display: block
		margin-bottom: 15px
		font-weight: 700
		font-size: 16px
		span
			margin: 0 r(-3px)
	.form-group
		margin-bottom: 20px
		position: relative
		label
			color: $main
			font-size: r(16px)
			font-weight: 400
			transition: .5s all ease-in-out
			span
				color: #ff0000
		input,textarea
			padding: 10px 0
			width: 100%
			border: 0
			height: 40px
			outline: 0
			background-color: $tran
			border-bottom: thin solid #e0e0e0
			// &:focus,&:valid
			// 	+ label
			// 		top: -2px
			// 		font-size: r(14px)
	.frm-btnwrap
		padding: 0 15px
		.frm-btn
			margin-left: 0
			position: relative
			&.btn-seemore
				background-color: $tran
				&:hover
					background-color: $main!important
					input
						color: $white
		input
			border-bottom: 0
			color: $main
			font-weight: 700
			text-align: center
			text-transform: uppercase
			border: 1px solid $main
	.attachfile1,.attachfile2
		padding: 0 15px
		label
			position: static
			span
				color: $color-99
		.left
			.ruFileWrap.ruStyled
				width: 160px
				height: 40px
				border-radius: 20px
				background-color: #f5f5f5 !important
				transition: .3s all ease-in-out
				display: flex
				justify-content: center
				align-items: center
				position: relative
				input
					color: $color-33
					font-size: 0
					font-weight: 400
					border: 0 !important
					outline: 0 !important
					background: none !important
				&::before
					content: "Chọn Tệp"
					position: absolute
					top: 50%
					left: 50%
					transform: translate(-50%, -50%)
					font-size: r(16px)
					pointer-events: none
.en-us
	.apply-frm
		.attachfile1,.attachfile2
			.left
				.ruFileWrap.ruStyled
					&::before
						content: "Chose File"
						position: absolute
						top: 50%
						left: 50%
						transform: translate(-50%, -50%)
						font-size: r(16px)
						pointer-events: none

