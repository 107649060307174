#pagebanner
	position: relative
	height: auto
	@media screen and ( max-width: 1024px )
		min-height: auto
		height: auto
		
	.img
		width: 100%
		height: auto
		position: relative
		// &::before
		// 	content: ''
		// 	position: absolute
		// 	left: 0
		// 	top: 0
		// 	width: 100%
		// 	height: 100%
		// 	background-color: rgba(0,0,0 ,0.4 )
		&::before
			content: ""
			position: absolute
			width: 100%
			height: 100%
			top: 0
			left: 0
			z-index: 2
			pointer-events: none 
			// background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3) 10%, rgba(0, 0, 0, 0.1) 60%, rgba(0, 0, 0, 0) 100%)
			background-image: linear-gradient(180deg, transparent,rgba(0, 0, 0, 0.1),rgba(0, 0, 0, .1),rgba(0, 0, 0, .2),rgba(0, 0, 0, .2),rgba(0, 0, 0, .2), rgba(0, 0, 0, .3),rgba(0, 0, 0, .3), rgba(0, 0, 0, .4), rgba(0, 0, 0, .5), rgba(0, 0, 0, .6), rgba(0, 0, 0, .7), rgba(0, 0, 0, .8), rgba(0, 0, 0, .9))
		img
			width: 100%
			height: 100%
			object-fit: cover
	.box-text
		position: absolute
		bottom: r(120px)
		left: 50%
		transform: translateX(-50%)
		display: flex
		justify-content: center
		align-items: center
		flex-direction: column
		width: 100%
		z-index: 111 
		.title-line
			color: #ffffff
			font-size: r(40px)
			font-weight: 400
			letter-spacing: 0.84px
			text-transform: uppercase	
			position: relative
			padding-bottom: 18px
			&::before
				width: 70%
				height: 1.5px
				position: absolute
				bottom: 0
				left: 50%
				transform: translateX(-50%)
				background-color: #fff
				content: ""
				
			
	@media screen and ( max-width: 1023.9px)
		.title-line
			font-size: r(28px)
	@media screen and ( max-width: 768px)
		.img
			height: 50vh
		.box-text
			.title-line
				// font-size: (30px)
				padding-bottom: 12px
			.title-small
				font-size: r(28px)
	@media screen and ( max-width: 576px)
		.img 
			height: auto 
		.box-text
			.title-line
				font-size: r(30px)
