.tong-quan-1.duan-ct
	.wrap-left
		.wrap-top
			margin-bottom: 0
		table
			tr
				td
					margin-bottom: r(30px)
					&:first-child
						+dc
						width: 62px
						height: 62px
						border-radius: 10px
						background-color: $white
						@media screen and ( max-width: 480px )
							padding: 5px 10px
					&:last-child
						padding-left: r(30px)
						vertical-align: top
				&:last-child
					margin-bottom: 0
	.btn-primary
		a
			padding-left: r(40px)
		color: $white
		box-shadow: 0 0 30px rgba(0, 0, 0, 0.1)
		background-image: linear-gradient(to top, #17a654 0%, #134d90 100%)
		&::before  
			filter: brightness(0) invert(1)
		&:hover
			transform: all .3s ease-in-out
			transform: translateY(-5px)
.duan-ct-2
	.title-center
		margin-bottom: r(40px)
	.wrap-box
		display: flex
		border-radius: 20px
		overflow: hidden
		@media screen and ( max-width: 767.9px )
			flex-wrap: wrap
			border-radius: 0
		.item
			width: 25%
			position: relative
			@media screen and ( max-width: 767.9px )
				width: 50%
			@media screen and ( max-width: 479.9px )
				width: 100%
			.img
				+img-ratio(305/305)
			.inner
				position: absolute
				bottom: 30px
				left: 20px
				z-index: 11 
				.icon
					margin-bottom: 10px
			&::before
				content: ""
				position: absolute
				width: 100%
				height: 100%
				background-color: #ffffff
				background-image: linear-gradient(to top, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%)
			&::after
				content: ""
				position: absolute
				height: 100%
				width: 100%
				top: 0
				left: 0
				background-image: linear-gradient(to top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 52%, rgba(0, 0, 0, 0.3) 100%)
				z-index: 1
				pointer-events: none 
				transition: .3s ease-in-out all


