.home-3
	background-color: #fff
	position: relative
	overflow: hidden
	justify-content: center
	align-items: center
	.box-wrap
		height: 100%
		display: flex
		align-items: center
		@media screen and ( min-width: 1201.9px )
			padding-top: r(80px)
		@media screen and ( max-width: 1279.9px )
			padding-top: r(60px)
		[class*='col']:last-child
			align-items: center
			display: flex
			@media screen and ( max-width: 1279.9px )
				display: block
		.wrap-left
			@media screen and ( min-width: 1520px )
				padding-right: r(50px)
			@media screen and ( max-width: 1279.9px )
				margin-bottom: r(30px)
			.section-title
				font-size: r(18px)
				line-height: 1
				&::before
					top: auto
			.desc
				font-size: r(36px)
				font-weight: 700
				color: $main
				margin-bottom: r(30px)
				line-height: r(42px)
			.content
				text-align: justify
				padding-right: r(100px)
				@media screen and ( max-width: 767.9px )
					padding-right: 0
		.wrap-right
			display: flex
			@media screen and ( max-width: 1279.9px )
				justify-content: center
			@media screen and ( max-width: 767.9px )
				flex-wrap: wrap
			.item
				flex: 0 0 260px
				border-radius: 18px
				margin-right: 15px
				position: relative
				overflow: hidden
				z-index: 3
				transform: translateY(0px)
				transition: .3s all
				@media screen and ( max-width: 1279.9px )
					flex: 0 0 calc((100% / 3)-15px)
				@media screen and ( max-width: 767.9px )
					flex: 0 0 calc((100% / 2)-15px)
					margin-bottom: r(15px)
				>.img
					+img-ratio(400/260)
				&:last-child
					margin-right: 0
				.wrap-inner
					position: absolute
					bottom: 30px
					width: 100%
					text-align: center
					pointer-events: none
					z-index: 3
					overflow: hidden
					.content
						font-weight: 700
						color: $white
					.icon
						pointer-events: none
				&::after
					content: ""
					position: absolute
					height: 100%
					width: 100%
					top: 0
					left: 0
					background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 52%, rgba(0, 0, 0, 0) 100%)
				&::before
					content: ""
					background: rgba($main-2, .3)
					position: absolute
					top: 100%
					left: 0
					opacity: 0
					visibility: hidden
					transition: .5s all
					width: 100%
					height: 100%
					z-index: 2
					pointer-events: none
					border-radius: 18px
				&:hover
					transform: translateY(-60px)
					&::after
						display: none
					&::before
						opacity: 1
						visibility: visible
						top: 0
					.content
						text-decoration: underline
					

	// .container
	// 	position: relative
	// 	z-index: 3
	// &:before
	// 	content: ''
	// 	background-color: #e98727
	// 	width: 100%
	// 	height: 30vh
	// 	position: absolute
	// 	top: -100%
	// 	left: 0
	// 	transition: .7s all  ease-in-out
	// 	opacity: 1
	// 	top: 0
	// 	z-index: 1
	// 	@media screen and ( max-width: 1200px )
	// 		opacity: 1
	// 		top: 0
	&.down-scroll
		@media screen and ( min-width: 1200px )
			&::before
				top: -100%
				opacity: 0
	// .img-big
	// 	margin-top: 40px
	// 	width: 100%
	// 	height: 370px
	// 	overflow: hidden
	// 	padding-right: 90px
	// 	border-radius: 0 40px 0px 40px
	// 	@media screen and ( max-width: 1200px )
	// 		padding-right: 0
	// 	@media screen and ( max-width: 768px )
	// 		display: none
	// 	img
	// 		width: 100%
	// 		height: 100%
	// 		border-radius: 0 40px 0px 40px
	// 		overflow: hidden
	// 		object-fit: cover
	// .wrap-post-small
	// 	margin-top: -260px
	// 	@media screen and ( max-width: 768px )
	// 		margin-top: 50px
	// 	.wrap-text
	// 		@media screen and ( max-width: 1024px )
	// 			order: 2
	// 	@media only screen and (min-width : 1200px) and (height : 675px)
	// 		margin-top: -148px
	// 	@media (min-width: 1024px)
	// 		.col-lg-3
	// 			flex: 0 0 33.33%
	// 			max-width: 33.33%
	// 			&.col-md-12
	// 				flex: 0 0 100%
	// 				max-width: 100%
	// 				order: 2
	// 	@media (min-width: 1200px)
	// 		.col-lg-3
	// 			flex: 0 0 25%
	// 			max-width: 25%
	// 			&.col-md-12
	// 				flex: 0 0 25%
	// 				max-width: 25%
	// 				order: 0
	// 	.box-item
	// 		width: 100%
	// 		height: 607px
	// 		border-radius: 0 30px 0px 30px
	// 		overflow: hidden
	// 		position: relative
	// 		display: block
	// 		@media only screen and (min-width : 1200px) and (max-height : 768px)
	// 			height: 500px
	// 		@media only screen and (min-width : 1200px) and (height : 675px)
	// 			height: 390px
	// 		@media screen and ( max-width: 1200px )
	// 			height: auto
	// 		@media screen and ( max-width: 768px )
	// 			height: 300px
	// 		img
	// 			width: 100%
	// 			height: 100%
	// 			object-fit: cover
	// 		.text
	// 			color: #ffffff
	// 			font-size: 1.8rem
	// 			font-weight: 700
	// 			line-height: 1.2
	// 			position: absolute
	// 			bottom: 30px
	// 			width: 100%
	// 			text-align: center
	// 			@media screen and ( max-width: 992px )
	// 				font-size: r(30px)
	// 		&.box-img
	// 			@media screen and ( max-width: 768px )
	// 				margin-bottom: 30px
	// 		&.box-text
	// 			display: flex
	// 			flex-direction: column
	// 			justify-content: flex-end
	// 			border-radius: 0!important
	// 			@media screen and ( max-width: 1200px )
	// 				margin-top: 20px
	// 			@media screen and ( max-width: 768px )
	// 				height: auto
	// 			.mota
	// 				color: #000000
	// 				font-size: r(20px)
	// 				font-weight: 400
	// 				letter-spacing: 0.4px
	// 				line-height: 32px
	// 				text-align: left
	// 				@media screen and ( max-width: 1300px )
	// 					+rgtext(6)
	// 				@media screen and ( max-width: 1600px )
	// 					+rgtext(8)
	// 				@media only screen and (min-width : 1200px) and (max-height : 768px)
	// 					+rgtext(5)


