.home-2
	padding: 0
	background-size: 105% 105%!important
	overflow: hidden
	.Module.Module-207,
	.Module.Module-207 .ModuleContent
		width: 100%
		.container
			padding-top: r(40px)
			padding-bottom: r(40px)
			padding-left: r(110px)
			@media(max-width: 576px)
				padding-left: 15px
				padding-right: 15px
	.Module.Module-207 .ModuleContent
		display: flex
		height: 100%
		align-items: center
		@media screen and ( max-width: 1023.9px )
			display: block 
			height: auto
	.js-ml
		display: flex
		flex-direction: row
	.wrap-box,
	.Module.Module-207 .ModuleContent .container
		display: flex
		// justify-content: center
		height: 100%
		.wrap-left
			height: 100%
			display: flex
			flex-direction: column
			justify-content: center
			// @media screen and ( min-width: 1200px )
			//     max-width: 600px
			.section-title
				margin-top: r(30px)
	.wrap-right
		width: 100%
		height: 100%
		max-width: 975px
		margin-left: auto
		text-align: right
		.img
			height: 100vh 
			text-align: right
			position: relative
			img
				height: 100%
				@media screen and ( max-width: 1023.9px )
					height: auto
				
	.des
		text-align: justify
		color: $color-66
		@media only screen and (min-width : 1200px) and (height : 675px)
			p
				+rgtext(2)
		@media only screen and (min-width : 1200px) and (height : 567px)
			p
				+rgtext(2)
		@media only screen and (min-width : 1200px) and (max-height : 768px)
			margin-top: 15px
	.wrap-img
		margin-top: 25px
		display: flex
		align-items: center
		justify-content: center
		@media screen and ( max-width: 768px )
			flex-wrap: wrap
		.img-big, .img-small
			margin: 0 6.5px
			@media screen and ( max-width: 768px )
				margin: 0
			img
				width: 100%
				height: 100%
				object-fit: cover
		.img-big
			width: 518px
			height: 471px - 30px
			@media screen and ( max-width: 1024px )
				height: auto
			@media screen and ( max-width: 768px )
				margin: 10px 0px
			@media only screen and (min-width : 1200px) and (max-height : 768px)
				height: 341px
			@media only screen and (min-width : 1200px) and (height : 675px)
				height: 280px
			@media only screen and (min-width : 1200px) and (height : 567px)
				height: 280px
			@media only screen and (height : 567px)
				height: 225px
		.img-small
			width: 433px
			height: 391px - 30px
			border-radius: 0px 40px 0px 40px
			overflow: hidden
			@media screen and ( max-width: 768px )
				flex: 0 0 100%
			@media screen and ( max-width: 1024px )
				height: 250px
			@media only screen and (min-width : 1200px) and (max-height : 768px)
				height: 261px
			@media only screen and (min-width : 1200px) and (height : 675px)
				height: 220px
			@media only screen and (min-width : 1200px) and (height : 567px)
				height: 220px
			@media only screen and (height : 567px)
				height: 195px
	.text-2
		@media screen and ( min-width: 1200px )
			p
				+rgtext(3)
		@media screen and (min-width : 1200px) and ( max-height: 768px )
			p
				+rgtext(3)
				margin-top: 0
				padding-top: 12px
		@media screen and (min-width : 1200px) and ( max-height: 675px )
			p
				+rgtext(2)
				margin-top: 0
				padding-top: 12px
		@media screen and ( min-width: 1600px )
			p
				+rgtext(5)


// @media only screen and (width : 375px) and (height : 812px)
