
.lien-he
	padding-top: 75px
	padding-bottom: 95px
	@media screen and ( max-width: 768px )
		padding: 40px 0px
	>.container-16
		>.row
			>*
				padding-right: 0
				padding-left: 0
	.wrap-left
		
		.title
			font-size: 22px
			color: $body
			text-transform: uppercase
			font-weight: 600
			background: $bg-gradient2
			padding: 20px
		.wrap-text-lh
			background: $body
			padding: 20px 30px
			border-bottom: 1px solid #e1e1e1
			&:hover
				background: #f5faff
				cursor: pointer
			.title-lh 
				font-size: 16px
				font-weight: 600
				text-transform: uppercase
				color: $main
			
			ul			
				li
					display: flex
					padding: 10px 0
					font-size: 14px
					
					em
						color: $main
						margin-right: 10px
						line-height: 1.7
	.wrap-right
		width: 100%
		height: 100%
.lien-he-2
	padding-top: 95px
	padding-bottom: 100px
	
	.wrap-form-lh
		width: 80%
		
		margin: 0 auto

		.wrap-form
			input[type='text']
				height: 50px
				border-radius: 50px
				border: none
				background-color: rgba(255, 255, 255, .78)
				color: $color-99
				font-size: r(14px)
				font-weight: 500
				padding: 16px 25px
				&::placeholder
					color: #999999
					font-size: 14px
					font-weight: 300
				&:focus
					outline: none
					border: 1px solid $main
					// box-shadow: 0px 0px 15px 0px rgba(111, 120, 245, 0.2)
			textarea
				width: 100%
				height: 103px
				border-radius: 20px
				outline: none
				border: none
				background-color: rgba(255, 255, 255, .78)
				color: $color-99
				font-size: r(14px)
				font-weight: 500
				padding: 16px 25px
				&::placeholder
					color: #999999
					font-size: 14px
					font-weight: 300
				&:focus
					outline: none
					border: 1px solid $main
		.frm-btn
			display: flex
			justify-content: flex-end
			position: relative
			overflow: hidden
			input
				color: $main
				text-transform: uppercase
				border: none
				font-size: r(14px)
				font-weight: 600
				margin-top: 10px
				border-radius: 10px
				width: 197px
				height: 60px
				transition: .3s all  ease-in-out
				background-color: rgba(255, 255, 255, .78)
				
				&:hover
					background-color: $main
					color: white
				
			&::before
				content: ""
				position: absolute
				right: -30px
				bottom: 0
				background: url("../img/btn-icon.png") no-repeat 
				background-size: contain
				width: 63px
				height: 50px
			@media(max-width: 576px)
				input 
					box-shadow: 0 0 10px 1px rgba(#000, 0.1)
					font-size: r(18px)
					height: 50px
					width: 130px
			// &:hover
			// 	color: $main-2
			// 	&::before
			// 		background: url("../img/btn-icon-green.png")no-repeat
					
// .wrap-map-lh
// 	width: 100%
// 	height: 843px
// 	@media screen and ( max-width: 768px )
// 		height: 50vh
// 	iframe
// 		height: 100%
// .footer-lh
// 	background-color: $main
// 	padding-top: 27px
// 	padding-bottom: 17px
// 	.end-footer
// 		border-top: 3px solid white
// 		margin-top: 29px
// 		padding-top: 10px
// 		p
// 			color: #ffffff
// 			font-size: r(20px)
// 			font-weight: 400
