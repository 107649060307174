.thanh-vien
    padding-bottom: r(60px)
    .heading
        padding-top: 30px

            
    .wrap-content
        margin-bottom: 150px
        &:nth-child(2n)
            .row
                .col-lg-6
                    &:nth-child(2n+1)
                        order: 2
                      
                        .text-desc
                            background: $main
                            left: 100px
                            @media screen and ( max-width: 567px )
                                left: 20px
                                
                            &::after
                                color: $main-2
        @media screen and ( max-width: 768px )
            &:nth-child(2n+1)
                .row
                    .col-lg-6
                        &:nth-child(2n+1)
                            order: 2 

    .box-img
        position: relative
        height: 100%
        .img
            width: 100%
            height: 100%
            img
                border-radius: 20px
                width: 100%
                height: 100%
                object-fit: cover
        .text-desc
            background: $main-2
            padding: 20px 
            width: 50%
            position: absolute
            right: 50px
            top: 80%
            @media screen and ( max-width: 768px )
                width: 60%
            @media screen and ( max-width: 576px  )
                width: 80%
                left: 20px
                top: 60%
                &::after
                    display: none
                
                
            &::after
                position: absolute
                content: "\EC51"
                font-family: "remixicon"
                left: -80px
                top: -30px
                font-size: 70px
                color: $white

            li
                display: flex
                line-height: 1.2
                padding: 5px 0
                em
                    margin-right: 10px
                    font-size: 20px
                    color: $white
                p
                    font-size: 14px
                    color: $white 
    .box-text
        padding: 30px 20px
        height: 100%
        +dc
        .text
            font-size: 16px
            line-height: 1.8
            padding: 20px 0
            p
                padding-top: 10px
        .img
            max-width: 60%
            margin: 0 auto