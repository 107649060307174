.title-center
	text-align: center
	margin-bottom: r(30px)
	.section-title
		display: inline
.section-title
	font-size: r(36px)
	font-weight: 700
	color: $main
	line-height: r(42px)
	position: relative
	padding-left: 50px
	margin-bottom: r(30px)
	@media screen and ( max-width: 767.9px )
		font-size: 2rem
	&::before
		content: ""
		position: absolute
		left: 0
		top: 0
		bottom: 0
		width: 33px
		height: 46px
		background: url("../img/bg-title.png")no-repeat
	&.no-icon
		padding-left: 0
		&::before
			display: none
	&.white
		color: $white
		padding-left: r(50px)
		&::before
			background: url("../img/bg-title-white.png")no-repeat
	&.green
		color: $main-2
	&.main
		color: $main
.title
	color: $color-33
	font-weight: 700

.section-title2
	font-size: r(36px)
	font-weight: 700
	color: $main
	line-height: r(42px)
	position: relative
	margin-bottom: r(40px)
	display: flex
	justify-content: center
	align-items: center
	@media screen and ( max-width: 578px)
		font-size: 18px
	.img
		width: 33px
		height: 46px
		margin-right: 20px
	p
		margin-bottom: -18px
	&.white
		p
			color: $white
.title-small
	color: $color-66
	font-weight: 700
	text-transform: uppercase
	&.white
		color: $white
.title-lg
	font-size: r(36px)
	font-weight: 700
	color: $tran
	background-image: linear-gradient(to top, #17a654 0%, #134d90 100%)
	background-clip: text
