.tong-quan
	padding: 0 0 r(80px)
	background-position: top !important
	.primary-nav
		margin-top: -3px
	.title-center
		margin-top: r(30px)
	.des
		text-align: center
		padding: 0 r(200px)
		margin-bottom: r(60px)
		@media screen and ( max-width: 1280px )
			padding: 0 r(80px)
		@media screen and ( max-width: 1023.9px )
			padding: 0 r(30px)
			margin-bottom: r(30px)
	.img
		margin: 0 auto
		border-radius: 10px
		+img-ratio(550/1020)

.tong-quan-1
	background-color: #f8f8f8
	.wrap-left
		.wrap-top
			display: flex
			justify-content: center
			flex-direction: column
			margin-bottom: r(60px)
			padding-right: r(120px)
			@media screen and ( max-width: 1279.9px )
				padding-right: r(40px)
		.wrap-bottom
			.des
				table
					tr
						td
							margin-bottom: r(30px)
							&:first-child
								+dc
								width: 62px
								height: 62px
								border-radius: 10px
								background-color: $white
								@media screen and ( max-width: 480px )
									padding: 5px 10px
							&:last-child
								padding-left: r(30px)
								vertical-align: top
						&:last-child
							margin-bottom: 0
			.item
				display: flex
				margin-bottom: r(30px)
				.icon
					+dc
					width: 62px
					height: 62px
					border-radius: 10px
					background-color: $white
					@media screen and ( max-width: 480px )
						padding: 5px 10px
				.content
					padding-left: r(30px)
				&:last-child
					margin-bottom: 0
	@media(max-width: 576px)
		background-image: none !important
.tong-quan-2
	.box-timeline
		position: relative
		margin: 0 auto
		padding: r(40px) 0
		&::after
			content: ""
			position: absolute
			left: 50%
			transform: translateX(-50%)
			height: 100%
			width: 2px
			top: 0
			border-right: 2px dashed #157a72
			@media screen and ( max-width: 767.9px )
				left: 0
				transform: translateX(0)
		.item-timeline
			width: 50%
			padding: 0 r(40px)
			position: relative
			z-index: 2
			@media screen and ( max-width: 767.9px )
				width: 100%
				margin-bottom: r(20px)
			.wrap-content
				position: relative  
				min-width: 364px
				max-width: 394px
				padding-bottom: r(20px) 
				box-shadow: -8px 0 46px rgba(0, 0, 0, 0.09)
				border: 1px dashed #fff
				background: linear-gradient(#fff,#fff) padding-box, linear-gradient(to top, #17a654 0%, #134d90 100%)
				border-radius: 10px
				@media screen and ( max-width: 1023.9px )
					max-width: 100%
					min-width: auto
			&::after
				content: ""
				width: 15px
				height: 15px
				border-radius: 100%
				background-image: linear-gradient(to top, #17a654 0%, #134d90 100%)
				background-color: $white
				position: absolute
				top: 50%
				transform: translateY(-50%)
			&:nth-child(odd)
				text-align: right
				margin-right: auto
				@media screen and ( max-width: 767.9px )
					text-align: left
				&::after
					right: -8.3px
				.wrap-content
					margin-left: auto
					padding-right: r(30px)
					padding-left: r(60px)
					&::before
						content: ""
						position: absolute
						right: -5px
						top: 50%
						transform: translateY(-50%) rotate(45deg)
						width: 10px
						height: 10px
						border: 1px dashed $main-2
						border-left: 0
						border-bottom: 0
						background: $white
				@media screen and ( max-width: 767.9px )
					&::after
						left: -7px
					.wrap-content
						margin-right: auto
						padding-left: r(30px)
						padding-right: r(60px)
						&::before
							content: ""
							position: absolute
							left: -5px
							top: 50%
							transform: translateY(-50%) rotate(-45deg)
							width: 10px
							height: 10px
							border: 1px dashed $main-2
							border-right: 0
							border-bottom: 0
							background: $white
			&:nth-child(even)
				text-align: left
				margin-left: auto
				@media screen and ( max-width: 767.9px )
					margin-right: auto
				&::after
					left: -7px
				.wrap-content
					margin-right: auto
					padding-left: r(30px)
					padding-right: r(60px)
					&::before
						content: ""
						position: absolute
						left: -5px
						top: 50%
						transform: translateY(-50%) rotate(-45deg)
						width: 10px
						height: 10px
						border: 1px dashed $main-2
						border-right: 0
						border-bottom: 0
						background: $white
.tong-quan-3
	.img-tong-quan-3
		min-height: 250px
		@media(min-width: 1280px)
			min-height: 500px
		@media(min-width: 1600px)
			min-height: 800px
.tong-quan-4
	.des
		padding: 0 r(300px)
		margin-bottom: r(40px)
		@media screen and ( max-width: 1279.9px )
			padding: 0 r(150px)
		@media screen and ( max-width: 1023.9px )
			padding: 0 r(75px)
		@media screen and ( max-width: 767.9px )
			padding: 0 r(40px)
			margin-bottom: r(20px)
	.img
		text-align: center
		box-shadow: 0 0 92px rgba(0, 0, 0, 0.13)
		max-width: 631px
		height: 416px
		margin: 0 auto
		display: block
		@media screen and ( max-width: 767.9px )
			height: 350px
		img
			height: 100%
			@media screen and ( max-width: 767.9px )
				object-fit: contain
	.swiper-tong-quan
		position: relative
		.wrap-button
			.button-next
				right: 280px
				@media screen and ( max-width: 1599.9px )
					right: 180px
			.button-prev
				left: 280px
				@media screen and ( max-width: 1599.9px )
					left: 180px
