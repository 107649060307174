header
	position: fixed
	top: 0
	left: 0
	width: 100%
	z-index: 99
	&.active
		transform: translateY(-100%)
		transition: all 1s cubic-bezier(.22,.44,0,1)
.header-top
	background-image: linear-gradient(89deg, #f2f3f3 0%, #fbfcfc 100%)
	height: 35px
	display: flex
	align-items: center
	width: 100%
.header-top-primary
	display: flex
	align-items: center
	@media screen and ( max-width: 479.9px )
		justify-content: space-between
		.Module.Module-138
			display: none
	.wrap-left
		display: flex
		align-items: center
		em
			font-size: 24px
			font-weight: 500
			padding-right: 10px
		@media screen and ( max-width: 479.9px )
			display: none
		*
			+text-gradient
			font-weight: 700
	.wrap-search
		margin-left: auto
		@media screen and ( max-width: 479.9px )
			margin-left: 0
			order: 1
		.searchbox
			position: relative
			&::before
				content: ""
				position: absolute
				bottom: 0
				width: 100%
				height: 1px
				background: $bg-gradient
			input
				border: unset
				font-size: 12px
				padding: 5px
				background: $tran
			button
				+inner-transy
				right: 0
				border: unset
				background-color: $tran
				font-size: 0
				&::before
					+icon("\f0d1")
					+text-gradient 
					right: 0
					font-size: r(18px)
	.wrap-language
		position: relative
		cursor: pointer
		margin-left: r(25px)
		padding-right: 15px
		color: $color-99
		font-size: 14px
		text-transform: uppercase
		&::before
			+icon("\ea4d")
			+text-gradient
			content: 
			right: 0
		.dropdown
			position: absolute
			opacity: 0
			visibility: hidden
			transform: translateY(30%)
			transition: .3s all
			padding: r(5px)
			background-color: $main-2
			color: $white
			z-index: 3
		&:hover
			color: $main-2
			.dropdown
				opacity: 1
				visibility: visible
				transform: translateY(0)
				a
					&:hover
						color: $white
.header-bottom
	// box-shadow: 0 0 3px rgba(0, 0, 0, 0.1)
	// display: flex
	// align-items: center
	height: 65px
	background: $white
	.container
		height: 100%
	@media screen and ( max-width: 767.9px )
		height: 65px
		position: relative
	.primary-menu
		@media screen and ( max-width: 1023.9px )
			.logo
				margin: 0 auto
			ul
				display: none
		@media screen and ( max-width: 767.9px )
			.logo
				margin: auto auto auto 0
				.img
					align-items: center
				img
					width: 75px
					height: 40px
	.Module.Module-141, 
	.Module.Module-141 .ModuleContent,
	.Module.Module-142,
	.Module.Module-142 .ModuleContent
		display: flex
		width: 100%
		height: 100%
		justify-content: space-between
.primary-menu
	display: flex
	align-items: center
	justify-content: space-between
	width: 100%
	height: 100%
	.dropdown-menu
		.title
			em
				@media screen and ( min-width: 1023.9px )
					display: none
	.logo
		margin: 0 r(130px)
		padding: 10px 0
		@media screen and ( max-width: 1279.9px )
			margin: 0 r(60px)
		.img
			display: flex
			width: 100px
			img
				margin: 0 auto
	>ul
		li
			&::hover
				.title
					&::before
						width: 100%


	ul
		list-style: none
		padding: 0
		margin: 0
		width: 100%
		height: 100%
		display: flex
		justify-content: space-between
		li
			font-weight: 700
			text-transform: uppercase
			position: relative
			display: inline-flex
			align-items: center
			cursor: pointer
			&::hover
				.title
					&::before
						width: 100%
			> .title
				position: relative
			&.active
				.title
					position: relative
					&::before
						content: ""
						position: absolute
						bottom: 1px
						height: 2px
						border-radius: 1px
						left: 0
						width: 100%
						background: $bg-gradient
				a, em
					+text-gradient-menu
					text-decoration: underline		
			> .title
				&::before
					content: ""
					position: absolute
					bottom: 1px
					height: 2px
					left: 0
					border-radius: 1px
					width: 0
					background: $bg-gradient
					transition: .3s all
			&:hover
				.title
					&::before
						width: 100%
					a
						+text-gradient-menu
						transition: .3s all ease-in-out
				.dropdown
					// top: 100%
					opacity: 1
					visibility: visible
			@media screen and ( min-width: 1024px )		
				.dropdown
					position: absolute
					top: 100%
					display: flex
					flex-direction: column
					justify-content: center
					align-items: center
					opacity: 0
					visibility: hidden
					background-color: $white
					left: 50%
					transform: translateX(-50%)
					transition: .3s all
					width: max-content
					.dropdown-item
						+dc
						min-width: 190px
						width: 100%
						padding: r(20px)
						font-size: 14px
						font-weight: 700
						text-transform: uppercase
						color: $color-33
						border: 1px solid $color-eb
						&:hover
							*
								+text-gradient-menu
	

.toggle-menu
	@media screen and ( min-width: 1024px )
		display: none
	width: 30px
	padding: 0
	cursor: pointer
	position: absolute
	transition: .3s all ease-in-out
	margin-left: auto
	right: 30px
	top: 25%
	margin-top: 5px
	span
		position: absolute
		width: 100%
		display: block
		height: 3px
		background-color: $main-2
		transform: rotate(0deg)
		transition: .4s all ease-in-out
		left: 0
		@media screen and ( max-width: 767.9px )
			background-color: $main-2
		&:first-child
			top: 0
		&:nth-child(2),
		&:nth-child(3)
			top: 10px
		&:last-child
			top: 20px
	&.active
		span
			&:first-child,
			&:last-child
				left: 50%
				width: 0
			&:nth-child(2)
				transform: rotate(45deg)
			&:nth-child(3)
				transform: rotate(-45deg)
				

