.wrap-form
    .form-row
        display: flex
        margin: 0 -15px
        @media screen and ( max-width: 768px )
            flex-wrap: wrap
        .form-group
            flex: 0 0 50%
            padding: 0 15px
            margin-bottom: 25px
            @media screen and ( max-width: 768px )
                flex: 0 0 100%
            input[type="text"]
                width: 100%
                height: 86px
                border-radius: 5px
                border: 1px solid #707070
                background-color: #ffffff
                padding: 20px
                color: #000000
                font-size: r(20px)
                font-weight: 400
                &::placeholder
                    color: #000000
