.home-5
	justify-content: center
	align-items: center
	.mx-auto
		margin-left: auto
		margin-right: auto
	.Module.Module-210
		max-width: 100%
	.swiper-news
		position: relative
		.wrap-button
			@media screen and ( max-width: 1519.9px )
				display: none
		.item
			display: flex
			flex-direction: column
			border-radius: 20px
			background-color: $white
			cursor: pointer
			overflow: hidden
			.img
				display: flex
				max-width: 100%
				// height: 390px
				+img-ratio(255/390)
				// img
					// width: 100%
					// height: 390px
			.box-content
				position: relative
				padding: r(15px) r(25px) r(25px) r(50px)
				.title
					+line(3)
					font-size: r(24px)
					line-height: 1.2
					margin-bottom: r(20px)
					transition: .3s all
					height: 84px
					@media screen and ( max-width: 767.9px )
						height: 40px
						font-size: 16px
				.desc
					+line(1)
					font-size: r(14px)
					line-height: r(18px)
				.inner-date
					top: 95px
					bottom: 0
					left: 0
			&:hover
				.title
					color: $main-2
				.inner-date
					background-color: $main-2
