.home-page-banner
	padding: 0
	background-color: #fff
	background-image: none 
	.banner-wrapper
		width: 100%
		height: 100%
		.swiper-container
			@media screen and ( max-width: 768px )
				height: 52vh
	.img
		width: 100%
		height: 100vh
		position: relative
		&::before
			content: ""
			position: absolute
			width: 100%
			height: 100%
			// background-image: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.1) 57%, rgba(0, 0, 0, 0) 100%)
			background-image: linear-gradient(180deg, transparent,rgba(0, 0, 0, 0.1),rgba(0, 0, 0, .1),rgba(0, 0, 0, .2),rgba(0, 0, 0, .2),rgba(0, 0, 0, .2), rgba(0, 0, 0, .3),rgba(0, 0, 0, .3), rgba(0, 0, 0, .4), rgba(0, 0, 0, .5), rgba(0, 0, 0, .6), rgba(0, 0, 0, .7), rgba(0, 0, 0, .8), rgba(0, 0, 0, .9))
			top: 0
			left: 0
			z-index: 2
			pointer-events: none 
		@media screen and ( max-width: 1024px )
			height: auto
		@media screen and ( max-width: 992px )
			height: 100%
		img
			transition: all 5s cubic-bezier(.22, .44, 0, 1)
			transform: scale(1)
			height: 100%
			width: 100%
			overflow: hidden
			object-fit: cover
	.swiper-slide
		// max-width: 1920px!important
		width: 100% !important
	.box-text
		position: absolute
		font-size: r(40px)
		color: $white
		text-align: center
		transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s
		left: 50%
		transform: translate3d(-50%, -40% , 0)
		bottom: 15%
		font-weight: 400
		text-transform: uppercase
		@media screen and ( max-width: 1023.9px)
			font-size: r(28px)
		@media screen and ( max-width: 767.9px )
			transform: translate3d(-50%, -20% , 0)
			bottom: 15%
			width: 100%
		&::before
			content: ""
			position: absolute
			top: -30px
			width: 75px
			height: 2px
			left: 50%
			transform: translateX(-50%)
			background-color: $white
	.swiper-slide-active
		.box-text
			z-index: 3
			font-weight: 400!important
			transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.5s
			bottom: 15%
			transform: translateY(-25%) translateX(-50%)!important
			opacity: 1
			@media screen and ( max-width: 1199.9px )
				bottom: 14%
			@media screen and ( max-width: 767.9px )
				bottom: 15%
	.wrap-scroll
		position: absolute
		left: 50%
		bottom: 9.5%
		transform: translateX(-50%)
		width: 24px
		height: 40px
		border-radius: 12px
		border: 1px solid #ffffff
		display: flex
		flex-direction: column
		align-items: center
		justify-content: center
		z-index: 3
		@media screen and ( max-width: 1023.9px )
			display: none
		&:hover
			cursor: pointer
		span
			z-index: 3
			position: absolute
			animation: Toptodown 2s infinite
			&:nth-child(1)
				top: 0
			&:nth-child(2)
				animation-delay: .2s
				top: 8px
			&:nth-child(3)
				animation-delay: .4s
				top: 16px

