.main-san-pham
	.sp-banner
		margin-top: 82px
.sp-banner
	min-height: 1080px
	position: relative
	display: flex
	align-items: center
	@media screen and ( max-width: 992px )
		height: 80vh
	@media screen and ( max-width: 575px )
		min-height: auto
		height: auto
		padding-top: 50px
		padding-bottom: 120px
	.line
		height: 152px
		width: 5px
		background-color: $main
		position: absolute
		bottom: -76px
		left: 50%
		transform: translateX(-50%)
	.wrap-text
		@media screen and ( max-width: 1280px )
			margin-bottom: 30px
		.title
			color: #1a1a1a
			font-size: r(35px)
			font-weight: 700
		.title-sp
			color: $main
			font-size: r(88px)
			font-weight: 700
			font-style: normal
			letter-spacing: 1.76px
			text-transform: uppercase
			span
				font-size: 51px
	.wrap-product-main
		padding-top: 300px
		@media screen and ( max-width: 1280px )
			padding-top: 0px
	.wrap-product
		margin-top: -100px
		@media screen and ( max-width: 1280px )
			margin-top: 0
		.img
			display: flex
			justify-content: center
			padding: 0 20px
		.box-ke
			max-width: 906px
			width: 100%
			height: 132px
			background-color: $main
			margin-top: -67px
			@media screen and ( max-width: 1280px )
				max-width: 100%
			@media screen and ( max-width: 765px )
				height: 100px

