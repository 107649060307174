.wrap-post-list
	background-image: url(../img/tin-tuc/bntt.png)
	.section-title2
		padding-top: r(30px)
	.box-item
		.wrap-text
			.title
				height: 72px
			.desc
				padding: 0
				+line(1)
			.view-detail
				display: inline-block
				margin-top: 30px
	.post-wrap
		
		>.row
			margin-bottom: -30px
			>*
				margin-bottom: 30px

.dao-tao-pages
	.heading
		.title-center
			display: none
	.wrap-text
		.title
			height: 76px
		.view-detail
			margin-top: r(30px)
			display: inline-block
	[class*='col']
		margin-bottom: r(30px)
.wrap-post-list
	.section-title
		padding-top: r(30px)
.news-detail-page
	.thu-vien-anh
		>.container
			max-width: 100%
			padding-right: 0
			padding-left: 0
	.wrap-post-related
		.wrap-text
			.title
				height: 73px
			.view-detail
				margin-top: r(30px)
				display: inline-block
		// .box-item
		// 	border-radius: 20px
		// 	background: $white
		// 	overflow: hidden
		// 	transition: .2s ease-in-out all
		// 	&:hover
		// 		.wrap-text
		// 			.title
		// 				color: $main-2
		// 			time
		// 				background: $main-2	
		// 	.img
		// 		width: 100%
		// 		height: 260px
		// 		overflow: hidden
		// 		img
		// 			width: 100%
		// 			height: 100%
		// 			object-fit: cover
		// 	.wrap-text
		// 		padding: 30px 20px 30px 40px
		// 		position: relative
		// 		.title
		// 			font-size: 22px
		// 			font-weight: 600
		// 			color: $color-33
		// 			padding-bottom: 10px
		// 			+line(2)
		// 			&:hover
		// 				color: $main-2
						
		// 		.desc
		// 			font-size: 14px
		// 			color: $color-33
		// 			+line(2)
		// 			padding-top: 10px
		// 			padding-bottom: 20px
					
		// 		.view-detail
		// 			font-size: 13px
		// 			color: $main
		// 			text-transform: uppercase
		// 			font-weight: 600
		// 			position: relative
		// 			margin-top: auto
		// 			margin-top: 30px
		// 			&::before
		// 				position: absolute
		// 				width: 25px
		// 				content: ""
		// 				height: 2px
		// 				background: $main
		// 				right: -35px
		// 				top: 50%
		// 				transform: translateY(-50%)
		// 				transition: .2s ease-in-out all
		// 			&:hover
		// 				color: $main-2
		// 				&::before
		// 					background: $main-2
		// 					transform: translateX(10px)
		// 		time
		// 			font-size: 13px
		// 			padding: 2px 10px
		// 			color: $white
		// 			font-weight: 500
		// 			background: $main
		// 			position: absolute
		// 			top: 32px
		// 			left: -33px
		// 			transform: rotate(270deg)


	// .post-list
// 	margin-top: -46px
// 	@media screen and ( max-width: 1200px )
// 		margin-top: -55px
// 	.container
// 		padding-top: 92px
// 		@media screen and ( max-width: 992px )
// 			padding-top: 70px
// .post-list
// 	.wrap-post
// 		padding-bottom: 85px
// 		margin-top: -45px
// 	.home-5
// 		.view-detail
// 			min-width: 141px
// 			height: 37px
// 			border-radius: 30px
// 			border: 1px solid $main
// 			color: #000000
// 			padding: 0 18px
// 			font-size: r(18px)
// 			font-weight: 400
// 			margin-top: 0
// 			background-color: transparent
// 			@media screen and ( max-width: 768px )
// 				height: 35px
// 		.wrap-big
// 			margin-top: 0
// 			margin-bottom: 55px
// 			.img
// 				+img-ratio(413/1050)
// 			.wrap-text
// 				.date
// 					font-size: r(48px)
// 					font-weight: 300
// 					width: 92px
// 					height: 90px
// 					background-color: $main
// 					flex: 0 0 92px
// 					span
// 						color: #ffffff
// 						font-size: r(12px)!important
// 						font-weight: 400
// 				.wrap-left
// 					padding-left: 30px
// 					@media screen and ( max-width: 992px )
// 						padding-left: 15px
// 					.title-link
// 						font-weight: 700
// 						line-height: 1.2
// 						@media screen and ( max-width: 992px )
// 							font-size: r(20px)
// 		.wrap-small
// 			display: flex
// 			margin: 20px 0px 0
// 			@media screen and ( max-width: 765px )
// 				flex-wrap: wrap
// 			.box-post-small
// 				flex: 0 0 48%
// 				padding: 0 0px
// 				@media screen and ( max-width: 765px )
// 					flex: 0 0 100%
// 					margin-bottom: 20px
// 				.img
// 					height: 258px
// 					margin-bottom: 10px
// 					@media screen and ( max-width: 575px )
// 						height: auto
// 					.date
// 						color: black
// 						font-size: r(48px)
// 						font-weight: 300
// 						width: 92px
// 						flex: 0 0 92px
// 						height: 90px
// 						opacity: 1
// 						background-color: #ffffff
// 						@media screen and ( max-width: 1024px )
// 							height: auto
// 						span
// 							color: black
// 							font-size: r(12px)!important
// 							font-weight: 400
// 				.wrap-title
// 					max-width: 100%
// 					margin-bottom: 30px
// 					+rgtext(3)
// 					padding: 0
// 					height: 90px
// 					@media screen and ( max-width: 1024px )
// 						height: auto
// 					@media screen and ( max-width: 768px )
// 						margin-bottom: 15px
// .post-list-2
// 	background-color: #957e6e
// 	padding-bottom: 53px
// 	padding-top: 110px
// 	@media screen and ( max-width: 992px )
// 		padding-top: 60px
// 		padding-bottom: 60px
// .wrap-post-list-sm
// 	display: flex
// 	align-items: center
// 	position: relative
// 	@media screen and ( max-width: 575px )
// 		flex-wrap: wrap
// 	// margin-bottom: 20px
// 	&:hover
// 		.wrap-text
// 			background-color: $main
// 			color: white
// 			&::before
// 				background-color: $main
// 	.img
// 		flex: 0 0 269px
// 		position: relative
// 		height: 188px
// 		@media screen and ( max-width: 1600px )
// 			flex: 1
// 		@media screen and ( max-width: 575px )
// 			flex: 0 0 100%
// 			height: auto
// 		img
// 			width: 100%
// 			height: 100%
// 			object-fit: cover
// 		.date
// 			position: absolute
// 			top: 0
// 			left: 0
// 			color: #4a4a4a
// 			font-weight: 300
// 			text-align: center
// 			font-size: 35px
// 			width: 65px
// 			height: 62px
// 			display: flex
// 			flex-direction: column
// 			line-height: 1.2
// 			justify-content: center
// 			align-items: center
// 			background-color: #ffffff
// 			span
// 				font-size: 14px
// 	.wrap-text
// 		flex: 1
// 		height: 188px
// 		background-color: white
// 		padding: 0 12px
// 		padding-right: 31px
// 		display: flex
// 		align-items: center
// 		z-index: 3
// 		position: relative
// 		transition: .3s all  ease-in-out
// 		@media screen and ( max-width: 575px )
// 			flex: 0 0 100%
// 			height: auto
// 			padding-top: 15px
// 			padding-bottom: 15px
// 		&::before
// 			content: ''
// 			width: 30px
// 			height: 30px
// 			background-color: white
// 			position: absolute
// 			top: 50%
// 			left: -25px
// 			z-index: -1
// 			transform: rotate(45deg) translateY(-50%)
// 			transition: .3s all  ease-in-out
// 			@media screen and ( max-width: 575px )
// 				top: -15px
// 				left: 50%
// 				transform: translateX(-50%) rotate(45deg)
// 		*
// 			font-size: r(20px)
// 			font-weight: 700
// 			line-height: 32px
// 			+rgtext(3)
// 			text-align: left
// 			text-transform: uppercase
// 			@media screen and ( max-width: 1600px )
// 				font-size: r(22px)
// 				line-height: 1.5
// 			@media screen and ( max-width: 575px )
// 				font-size: r(24px)

// .post-list-3
// 	background-color: #fff
// 	padding-top: r(90px)
// 	padding-bottom: r(154px)
// 	@media screen and ( max-width: 992px )
// 		padding-top: 60px
// 		padding-bottom: 60px
// 	.wrap-post-list-sm
// 		&:hover
// 			.wrap-text
// 				background-color: $main
// 				&::before
// 					background-color: $main
// 		.wrap-text
// 			background-color: #957e6e
// 			*
// 				color: white
// 			&::before
// 				background-color: #957e6e
// 	.hidden-image
// 		display: none !important
// .wrap-post-list-big
// 	display: flex
// 	align-items: center
// 	margin-bottom: 30px
// 	@media screen and ( max-width: 768px )
// 		flex-wrap: wrap
// 	&:hover
// 		.wrap-text
// 			background-color: $main
// 			color: white
// 			&::before
// 				background-color: $main
// 	.img
// 		flex: 0 0 518px
// 		height: 413px
// 		position: relative
// 		@media screen and ( max-width: 992px )
// 			flex: 1
// 		@media screen and ( max-width: 768px )
// 			flex: 0 0 100%
// 			height: auto
// 		img
// 			width: 100%
// 			height: 100%
// 			object-fit: cover
// 		.date
// 			width: 92px
// 			height: 90px
// 			background-color: $main
// 			position: absolute
// 			top: 0
// 			left: 0
// 			display: flex
// 			flex-direction: column
// 			color: #ffffff
// 			font-size: r(48px)
// 			font-weight: 300
// 			line-height: 1.2
// 			text-align: center
// 			align-items: center
// 			justify-content: center
// 			span
// 				color: #ffffff
// 				font-size: r(12px)
// 				font-weight: 400
// 	.wrap-text
// 		flex: 1
// 		height: 413px
// 		background-color: #957e6e
// 		padding: 0 31px
// 		display: flex
// 		align-items: center
// 		justify-content: center
// 		position: relative
// 		z-index: 2
// 		transition: .3s all  ease-in-out
// 		@media screen and ( max-width: 768px )
// 			flex: 0 0 100%
// 			height: auto
// 			padding: 31px
// 		&::before
// 			content: ''
// 			width: 64px
// 			height: 64px
// 			background-color: #957e6e
// 			position: absolute
// 			top: 50%
// 			left: -53px
// 			z-index: -1
// 			transform: rotate(45deg) translateY(-50%)
// 			transition: .3s all  ease-in-out
// 			@media screen and ( max-width: 768px )
// 				transform: rotate(45deg) translateX(-50%)
// 				top: 0
// 				left: 47%
// 		*
// 			color: white
// 			font-size: r(35px)
// 			font-weight: 700
// 			text-transform: uppercase
// 			transition: .3s all  ease-in-out
// 			@media screen and ( max-width: 992px )
// 				font-size: r(24px)

// .post-list-4
// 	padding-top: 58px
// 	padding-bottom: 50px
// 	.wrap-row
// 		display: flex
// 		margin: 0 -26.5px
// 		margin-top: 47px
// 		flex-wrap: wrap
// 		@media screen and ( max-width: 1300px )
// 			margin: 0 -13px
// 		.box-item
// 			flex: 0 0 20%
// 			max-width: 20%
// 			padding: 0 26.5px
// 			margin-bottom: 56px
// 			@media screen and ( max-width: 1300px )
// 				flex: 0 0 25%
// 				max-width: 25%
// 				padding: 0 13px
// 			@media screen and ( max-width: 992px )
// 				flex: 0 0 33.33%
// 				max-width: 33%
// 			@media screen and ( max-width: 575px )
// 				flex: 0 0 50%
// 				max-width: 50%
// 			@media screen and ( max-width: 375px )
// 				flex: 0 0 100%
// 				max-width: 100%
// 			.img
// 				a
// 					height: 326px
// 					display: block
// 					img
// 						width: 100%
// 						height: 100%
// 						object-fit: cover
// 			.wrap-text
// 				.title
// 					color: #000000
// 					font-size: r(20px)
// 					padding-top: 12px
// 					font-weight: 700
// 					line-height: 1.3
// 				.btn-download
// 					color: #000000
// 					font-size: r(20px)
// 					font-weight: 400
// 					display: flex
// 					align-items: center
// 					justify-content: center
// 					width: 157px
// 					height: 39px
// 					border-radius: 30px
// 					border: 1px solid #707070
// 					background-color: #ffffff
// 					margin-top: 20px
// 					transition: .3s all  ease-in-out
// 					&:hover
// 						transform: translateY(-3px)
// 						background-color: $main
// 						color: white
// 						border: 1px solid $main
// 					em
// 						font-size: 26px
// 						margin-left: 5px
.news-detail-page
	.wrap-post-list
		> .container 
			width: 100%
			max-width: 100% 
			padding: 0
