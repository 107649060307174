.thu-vien-anh
	position: relative
	background-image: url(../img/home-2/bg.png)
	&.section
		padding-top: 0
	.heading
		padding-top: r(55px)
		.sub-tab
			margin-bottom: r(30px)
			ul
				display: flex
				justify-content: center
				align-items: center
				li
					padding: 0 40px
					position: relative
					font-size: 16px
					font-weight: 600
					color: $color-99
					@media(max-width: 576px)
						font-size: 14px
						padding: 0 20px
					&:first-child
						&::before
							display: none
			
					&::before
						width: 2px
						height: 14px
						position: absolute
						content: ""
						top: 50%
						left: 0
						transform: translateY(-50%)
						background-color: $color-99
	
					a
						&:hover
							color: $main
							+hover-underline(95%, $main)
					&.active
						a
							text-decoration: underline
							color: $main
			@media(max-width: 576px)
				display: flex
				justify-content: center
				align-items: center
				ul 
					overflow-x: auto 
					justify-content: flex-start
					li 
						width: max-content
						white-space: nowrap
						&:first-child
							padding-left: 0
	.thuvien-list
		.row
			&:first-child
				.img
					+img-ratio(220/390)
			&:last-child
				.img
					+img-ratio(340/600)
		.box-wrap
			position: relative
			overflow: hidden
			border-radius: 3px
			margin-bottom: 30px
			transition: .2s ease-in-out all
			&::after
				content: ""
				position: absolute
				height: 100%
				width: 100%
				top: 100%
				left: 0
				background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 52%, rgba(0, 0, 0, 0.5) 100%)
				z-index: 21
				pointer-events: none 
				transition: .3s ease-in-out all
			&::before
				content: ""
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, .1) 100%)
				pointer-events: none
				z-index: 21
				transition: .3s ease-in-out all
			&:hover
				&::after
					top: 0%
				&::before
					top: -100%
			.info
				position: absolute
				left: 50px
				bottom: 10%
				z-index: 22
				a
					font-size: 14px
					color: $white
					transition: .2s ease-in-out all
					position: relative
					
					&:hover
						cursor: pointer
						
						border-bottom: 1px solid $white
					&::before
						content: "\EB2E"
						position: absolute
						font-family: "remixicon"
						bottom: 0
						left: -25px
						font-size: 18px
						line-height: 1
						z-index: 2
		.modal-img
			display: none
			.img
				width: 100%
				height: 100%
